import React, { useEffect, useState } from "react";
import contact_bg from "../img/background_img/Contact-Us.webp";
import { NavLink } from "react-router-dom";
import Aservices from "./Aservices";
import res from "../img/bn3256.png";
import help from "../img/Need-help.webp";
import Helmet from "react-helmet";
import logo from "../img/logo192.png";
import Ecommerce_image_ecc from "../../src/aservecse_img/About_Ajiva_Eccomerce.png";
import Ecommerce_image from "../../src/aservecse_img/ecom_pg_lfimg_1.png";
import Ecommerce_image_2 from "../../src/aservecse_img/ecom_pg_lfimg_2.png";
import Ecommerce_image_3 from "../../src/aservecse_img/Eccomerce_3.png";
import Ecommerce_image_4 from "../../src/aservecse_img/Eccomerce_4.png";
import Eccomerce_SCO from "../../src/aservecse_img/Eccomerce_SCO.png";
import new_design from "../../src/aservecse_img/New_design.png";
import Web_Development_21 from "../../src/img/WebDevelopment/web-development.png";
import Our_Approach from "../../src/img/WebDevelopment/Our-Approach.png";
import Custom_Web_Development from "../../src/img/WebDevelopment/Custom-Web-Development.png";
import responcive_website from "../../src/img/WebDevelopment/Responsive-Design.png";
import E_commerce_solution from "../../src/img/WebDevelopment/E-Commerce-Solutions.png";
import cms_image_14 from "../../src/img/WebDevelopment/Content-Management-Systems-(CMS).png";
import Customer_experience from "../../src/img/WebDevelopment/Website-Maintenance.png";
import Custome_software_development from "../../src/img/WebDevelopment/experience.png";
import CustomerSatisfaction from "../../src/img/WebDevelopment/Customer-Satisfaction.png";
// import Get_Started_Today from "../../src/img/WebDevelopment/";

var Web = Web_Development();

function Web_Development() {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="title"
          content="Custom Web Development Company | Professional & Affordable Web Development Services"
        />
        <title>
        Custom Web Development Company | Professional & Affordable Web Development Services
        </title>
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" custom web development company, professional web development services, affordable web development company, top web development companies, web design and development company, web development solutions company

"
        />
        <meta
          name="description"
          content="AJIVA INFOTECH offers professional and affordable web development services for your unique needs. As a leading custom web development company, we provide top-notch web design and development solutions. Contact us today to enhance your online presence with one of the top web development companies in the industry.
"
        />
        <link rel="apple-touch-icon" href={logo} />
      </Helmet>
      <div className="cocontainer m-auton">
        <section>
          <div className="flex justify-center">
            <img src={contact_bg} alt="contact_bg" />
          </div>
          <div className="container m-auto ">
            <div className="">
              <div className="opp">
                <div className="bang">
                  <h1 className="text-red-700	 ">Web Development</h1>
                </div>
                <div className="Api_int">
                  <NavLink to="/" className="text-base text-red-600	pr-2">
                    Home
                  </NavLink>
                  <span className="text-sm">/</span>
                  <h1 className="pl-2  lg:text-2xl font-bold text-[#333333]">
                    Web Development{" "}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="ecomerece_border_frist_main">
          <div className="container m-auto">
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image ">
                  <img src={Web_Development_21} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Custom Web Development Company
                </h1>
                <p className="">
                  At <span className="font-bold">AJIVA INFOTECH </span> we pride
                  ourselves on being a top-tier{" "}
                  <span className="font-bold">
                    custom web development company
                  </span>
                  . Our mission is to transform your vision into reality with
                  bespoke web solutions tailored to your unique needs. We
                  specialize in creating websites that are not only visually
                  stunning but also highly functional, ensuring a seamless user
                  experience that drives engagement and conversions.
                </p>
                {/* <div>
                  <ul className="web_des_ser_offer">
                    <li>Optimize customer experience</li>
                    <li>Increase operational efficiency</li>
                    <li>Gain a competitive advantage</li>
                    <li>Unlock new revenue opportunities</li>
                    <li>Adapt to changing market demands</li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Professional Web Development Services
                </h1>
                <p className="">
                  We offer a comprehensive range of professional web development
                  services designed to meet the diverse needs of businesses
                  across various industries. Whether you need a simple website
                  or a complex web application, our team of experienced
                  developers uses the latest technologies and best practices to
                  deliver top-notch results. Our services include:
                </p>
                <ul class="list-none p-0">
                  <li class="relative pl-6 before:absolute before:left-0 before:top-1/2 before:w-1.5 before:h-1.5 before:bg-black before:rounded-full before:content-[''] before:-translate-y-1/2">
                    Custom Website Development
                  </li>
                  <li class="relative pl-6 before:absolute before:left-0 before:top-1/2 before:w-1.5 before:h-1.5 before:bg-black before:rounded-full before:content-[''] before:-translate-y-1/2">
                    E-commerce Solutions
                  </li>
                  <li class="relative pl-6 before:absolute before:left-0 before:top-1/2 before:w-1.5 before:h-1.5 before:bg-black before:rounded-full before:content-[''] before:-translate-y-1/2">
                    CMS Development
                  </li>
                  <li class="relative pl-6 before:absolute before:left-0 before:top-1/2 before:w-1.5 before:h-1.5 before:bg-black before:rounded-full before:content-[''] before:-translate-y-1/2">
                    Web Application Development
                  </li>
                  <li class="relative pl-6 before:absolute before:left-0 before:top-1/2 before:w-1.5 before:h-1.5 before:bg-black before:rounded-full before:content-[''] before:-translate-y-1/2">
                    API Integration
                  </li>
                </ul>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Our_Approach} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="Our_Services">
              <h1>Our Services</h1>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Custom_Web_Development} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Affordable Web Development Company
                </h1>
                <p className="">
                  As an affordable web development company, we believe that
                  quality web solutions should be accessible to businesses of
                  all sizes. We offer competitive pricing without compromising
                  on quality, ensuring you get the best value for your
                  investment. Our transparent pricing model means no hidden
                  fees, and our flexible packages are designed to fit your
                  budget.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]  sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Top Web Development Companies
                </h1>
                <p className="">
                  When you partner with AJIVA INFOTECH, you are working with one
                  of the top web development companies in the industry. Our
                  proven track record of successful projects and satisfied
                  clients speaks volumes about our commitment to excellence. We
                  stay ahead of the curve by continuously adopting the latest
                  trends and technologies in web development, ensuring your
                  website remains cutting-edge and competitive.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={responcive_website} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={E_commerce_solution} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Web Design and Development Company
                </h1>
                <p className="">
                  As a full-service web design and development company, we
                  understand that the aesthetics of your website are just as
                  important as its functionality. Our talented designers work
                  closely with our developers to create websites that are
                  visually appealing, user-friendly, and optimized for
                  performance. From the initial design concept to the final
                  deployment, we ensure every aspect of your website reflects
                  your brand identity and engages your audience.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Web Development Solutions Company
                </h1>
                <p className="">
                  AJIVA INFOTECH is a leading web development solutions company
                  dedicated to solving your web-related challenges with
                  innovative and efficient solutions. Our holistic approach
                  means we take the time to understand your business goals and
                  objectives, enabling us to deliver web solutions that drive
                  results. Whether you need to improve your online presence,
                  streamline operations, or enhance customer engagement, we have
                  the expertise to help you succeed.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={cms_image_14} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="Our_Services">
              <h1>Why Choose Us?</h1>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2   ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Eccomerce_SCO} />
                </div>
              </div>

              <div className="lg:w-1/2   Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Expert Team
                </h1>
                <p className="">
                  Our skilled developers and designers are experts in their
                  fields, bringing years of experience and creativity to every
                  project.
                </p>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer sectio-positon-change  bg-[]">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Customer-Centric Approach
                </h1>
                <p className="">
                  We prioritize our clients’ needs, ensuring that every solution
                  is customized to meet your specific requirements.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Customer_experience} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Custome_software_development} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Quality Assurance
                </h1>
                <p className="">
                  We adhere to stringent quality standards to ensure your
                  website is robust, secure, and scalable.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Timely Delivery
                </h1>
                <p className="">
                  We understand the importance of deadlines and strive to
                  deliver your project on time, every time.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={CustomerSatisfaction} />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Ongoing Support
                </h1>
                <p className="">
                  Our commitment to your success doesn’t end with the launch. We
                  offer ongoing support and maintenance to ensure your website
                  continues to perform optimally.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                Get Started with AJIVA INFOTECH Today
                </h1>
                <p className="">
                Ready to elevate your online presence? Contact us today to discuss your web development needs and discover how our professional web development services can benefit your business. Let AJIVA INFOTECH be your trusted partner in creating a powerful and impactful online presence.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={CustomerSatisfaction} />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Ongoing Support
                </h1>
                <p className="">
                  Our commitment to your success doesn’t end with the launch. We
                  offer ongoing support and maintenance to ensure your website
                  continues to perform optimally.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Aservices />
      </div>
    </>
  );
}
export default Web_Development;
