import React, { useEffect, useState } from "react";
import contact_bg from "../img/background_img/Contact-Us.webp";
import { NavLink } from "react-router-dom";
import res from "../img/bn3256.png";
import help from "../img/Need-help.webp";
import Aservices from "./Aservices";
import Helmet from "react-helmet";
import logo from "../img/logo192.png";
import Ecommerce_image_ecc from "../../src/aservecse_img/About_Ajiva_Eccomerce.png";
import Ecommerce_image from "../../src/aservecse_img/ecom_pg_lfimg_1.png";
import Ecommerce_image_2 from "../../src/aservecse_img/ecom_pg_lfimg_2.png";
import Ecommerce_image_3 from "../../src/aservecse_img/Eccomerce_3.png";
import Ecommerce_image_4 from "../../src/aservecse_img/Eccomerce_4.png";
import Eccomerce_SCO from "../../src/aservecse_img/Eccomerce_SCO.png";
import new_design from "../../src/aservecse_img/New_design.png";
function Graphi_Design() {
  // const [Graphi_Design, setGraphi_Design] = useState(0);

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        {/* <link rel="icon" href={logo} /> */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="title"
          content="Professional Website & Graphic Design Services | Custom, Affordable, and Responsive Designs"
        />
        <title>
        Professional Website & Graphic Design Services | Custom, Affordable, and Responsive Designs
        </title>
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content="website design services, graphic design services, custom website design, affordable website design, responsive website design, small business website design, eCommerce website design, WordPress website design, mobile-friendly website design, logo design, brochure design, flyer design, business card design, banner design, social media graphic design, integrated design services, full-service design agency, UI/UX design, branding and identity design, illustration design, packaging design, motion graphics design, infographic design"
        />
        <meta
          name="description"
          content="Discover top-notch website and graphic design services suits to your business needs. Offering custom, affordable, and responsive designs for websites, logos, brochures, and more. Perfect for small businesses, eCommerce, and WordPress sites. Contact us today!
"
        />
        {/* <link rel="apple-touch-icon" href={logo} /> */}
      </Helmet>
      <div className="cocontainer m-auton">
        <section>
          <div className="flex justify-center">
            <img src={contact_bg} alt="contact_bg" />
          </div>
          <div className="container m-auto ">
            <div className="">
              <div className="opp">
                <div className="bang">
                  <h1 className="text-red-700	 ">Web & Graphic Design</h1>
                </div>
                <div className="Api_int">
                  <NavLink to="/" className="text-base text-red-600	pr-2">
                    Home
                  </NavLink>
                  <span className="text-sm">/</span>
                  <h1 className="pl-2  lg:text-2xl font-bold text-[#333333]">
                    Graphic Design Services
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="ecomerece_border_frist_main">
          <div className="container m-auto">
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Creative Graphic Design Services
                </h1>
                <p className="">
                  Unleash your brand’s potential with our creative graphic
                  design services. We bring fresh, innovative ideas to the
                  table, ensuring your visuals captivate and communicate
                  effectively.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]   ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Logo Design Services
                </h1>
                <p className="">
                  Make a lasting impression with a unique logo that represents
                  your brand. Our logo design services deliver distinctive and
                  memorable logos that reflect your business’s essence.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Brochure Design Services
                </h1>
                <p className="">
                  Inform and engage your audience with our brochure design
                  services. We create professional, eye-catching brochures that
                  effectively convey your message and showcase your products or
                  services.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Flyer Design Services
                </h1>
                <p className="">
                  Promote your events, sales, or announcements with our flyer
                  design services. We design attractive, informative flyers that
                  capture attention and drive action.
                </p>
              </div>
            </div>

            {/* ======================================================================================= */}
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Business Card Design Services
                </h1>
                <p className="">
                  Leave a lasting impression with our business card design
                  services. We design professional, stylish business cards that
                  make networking more effective and memorable{" "}
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Banner Design Services
                </h1>
                <p className="">
                  Enhance your marketing efforts with our banner design
                  services. We create striking banners for websites, events, and
                  advertising that draw attention and communicate your message
                  clearly.{" "}
                </p>
              </div>
            </div>
            {/* ======================================================================================= */}
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Social Media Graphic Design Services
                </h1>
                <p className="">
                  Boost your social media presence with our graphic design
                  services. We create engaging, visually appealing graphics for
                  all your social media platforms, helping you stand out and
                  connect with your audience.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
            </div>
            <div className="Our-Services-Graphic-Design">
              <h1>Combined Services</h1>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Integrated Website and Graphic Design Services
                </h1>
                <p className="">
                  Achieve a cohesive brand identity with our integrated website
                  and graphic design services. We ensure that your website and
                  graphics work together seamlessly to create a unified look and
                  feel.
                </p>
              </div>
            </div>
            {/* ================================================================================================ */}
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Full-Service Website and Graphic Design Agency
                </h1>
                <p className="">
                  As a full-service website and graphic design agency, we offer
                  end-to-end solutions for all your design needs. From concept
                  to completion, we manage every aspect of your project with
                  expertise and creativity.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Website and Graphic Design Packages
                </h1>
                <p className="">
                  Our website and graphic design packages provide a
                  comprehensive solution at a competitive price. These packages
                  are designed to offer everything you need to establish and
                  grow your online presence and brand identity.
                </p>
              </div>
            </div>
            {/* =============================================================================== */}
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Best Website and Graphic Design Services
                </h1>
                <p className="">
                  We pride ourselves on offering the best website and graphic
                  design services. Our commitment to quality, creativity, and
                  customer satisfaction sets us apart in the industry.{" "}
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Professional Website and Graphic Design Company
                </h1>
                <p className="">
                  As a professional website and graphic design company, we
                  deliver top-notch services that meet the highest standards of
                  excellence. Our team is dedicated to helping you achieve your
                  business goals through effective design.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Top Website and Graphic Design Services
                </h1>
                <p className="">
                  Our top website and graphic design services are recognized for
                  their excellence and impact. We combine creativity,
                  technology, and strategy to deliver results that exceed
                  expectations.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
            </div>
            <div className="Our-Services-Graphic-Design">
              <h1>Specialized Services</h1>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  UI/UX Design Services
                </h1>
                <p className="">
                  Enhance user satisfaction with our UI/UX design services. We
                  focus on creating intuitive, user-friendly interfaces that
                  provide an exceptional user experience.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Branding and Identity Design Services
                </h1>
                <p className="">
                  Build a strong, cohesive brand with our branding and identity
                  design services. We develop logos, color schemes, typography,
                  and more to create a consistent and memorable brand identity.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Illustration Design Services
                </h1>
                <p className="">
                  Add a unique touch to your visuals with our illustration
                  design services. We create custom illustrations that enhance
                  your content and set your brand apart.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Packaging Design Services
                </h1>
                <p className="">
                  Make your products stand out with our packaging design
                  services. We design attractive, functional packaging that
                  appeals to your target market and reinforces your brand.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Motion Graphics Design Services
                </h1>
                <p className="">
                  Bring your stories to life with our motion graphics design
                  services. We create dynamic animations and videos that engage
                  and captivate your audience.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Infographic Design Services
                </h1>
                <p className="">
                  Simplify complex information with our infographic design
                  services. We design informative, visually appealing
                  infographics that make data easy to understand and share
                  <br></br>
                  Contact us today to learn how our website and graphic design
                  services can help your business thrive. Let’s create something
                  amazing together!
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Aservices />
      </div>
    </>
  );
}
export default Graphi_Design;
