import React, { useState } from "react";
import bcrypt from "bcryptjs";

const CreateBcryptPassword = () => {
  const [password, setPassword] = useState("");
  const [hashedPassword, setHashedPassword] = useState("");
  const [copied, setCopied] = useState(false); // State to manage copied status

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleHashPassword = () => {
    const saltRounds = 10; // Number of salt rounds to use
    bcrypt.hash(password, saltRounds, (err, hash) => {
      if (err) {
        console.error("Error hashing password:", err);
      } else {
        setHashedPassword(hash);
      }
    });
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(hashedPassword); // Copy the hashed password to clipboard
    setCopied(true); // Update copied status
  };

  return (
    <div className="max-w-md mx-auto my-8 p-6 bg-gray-100 rounded-lg shadow-lg">
      <h2 className="text-xl font-semibold mb-4">Create Bcrypt Password</h2>
      <div className="mb-4">
        <label className="block mb-2">Password:</label>
        <input
          className="w-full px-3 py-2 border rounded-md"
          type="text"
          value={password}
          onChange={handlePasswordChange}
        />
      </div>
      <div className="mb-4">
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
          onClick={handleHashPassword}
        >
          Hash Password
        </button>
      </div>
      {hashedPassword && (
        <div>
          <label className="block mb-2">Hashed Password:</label>
          <input
            className="w-full px-3 py-2 border rounded-md"
            type="text"
            value={hashedPassword}
            readOnly
          />
          <button
            className="mt-2 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-300"
            onClick={handleCopyToClipboard}
          >
            {copied ? "Copied!" : "Copy to Clipboard"}
          </button>
        </div>
      )}
    </div>
  );
};

export default CreateBcryptPassword;
