import React, { useState } from "react";
import Helmet from "react-helmet";
import logo from "../../img/logo192.png";
const CodeEditor = () => {
  const [htmlCode, setHtmlCode] = useState("");
  const [cssCode, setCssCode] = useState("");
  const [jsCode, setJsCode] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [previewCode, setPreviewCode] = useState("");

  const updatePreview = () => {
    const fullHtml = `<html>
      <head>
        <style>${cssCode}</style>
      </head>
      <body>
        ${htmlCode}
        <script>${jsCode}</script>
      </body>
    </html>`;
    setPreviewCode(fullHtml);
  };

  const handleSave = () => {
    console.log("Saving code...");
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="" />
        <meta
          name="title"
          content="Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" coding validation tools, programming tools, code checker, code error finding tool, code beautify tool, code scanning tool"
        />
        <meta
          name="description"
          content=" Discover top coding validation tools to ensure error-free, high-quality code. Learn how tools like HTML Preview, Minyfiy to UnMinyfiy, Json Validator, Validate CSS, String Replace, Webhook Test can streamline your development workflow and enforce coding standards. "
        />
        {/* <link rel="apple-touch-icon" href={logo} /> */}
        <title>
          Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech
        </title>
      </Helmet>
      <div className=" bg-gray-100 m-auto">
        <div className="code-editor p-4 w-[80%] m-auto ">
          <div className="text-lg font-bold mb-4 text-center">
            Code Debugger
          </div>

          {/* First Row: Code Inputs */}
          <div className="grid grid-cols-3 ">
            {/* HTML Textarea */}
            <div>
              <textarea
                className="w-full h-60 border rounded-md p-2 bg-[#000] text-[#fff]"
                value={htmlCode}
                onChange={(e) => setHtmlCode(e.target.value)}
                placeholder="Enter HTML code here..."
              />
            </div>
            {/* CSS Textarea */}
            <div>
              <textarea
                className="w-full h-60 border rounded-md p-2 bg-[#000] text-[#fff]"
                value={cssCode}
                onChange={(e) => setCssCode(e.target.value)}
                placeholder="Enter CSS code here..."
              />
            </div>
            {/* JavaScript Textarea */}
            <div>
              <textarea
                className="w-full h-60 border rounded-md p-2 bg-[#000] text-[#fff]"
                value={jsCode}
                onChange={(e) => setJsCode(e.target.value)}
                placeholder="Enter JavaScript code here..."
              />
            </div>
          </div>

          {/* Second Row: Preview and Save Button */}
          <div className="mt-4">
            <div className="flex justify-between items-center">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => {
                  updatePreview();
                  setShowPreview(true);
                }}
              >
                Update Preview
              </button>
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                onClick={handleSave}
              >
                Save
              </button>
            </div>

            {/* Preview */}
            {showPreview && (
              <iframe
                title="code-preview"
                srcDoc={previewCode}
                className="mt-4 w-full h-80 border"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CodeEditor;
