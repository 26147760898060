import React from "react";
import logo from "../img/company_logo/download.png";
const Terms_Conditions = () => {
  return (
    <>
      <div className="py-10">
        <div className="container m-auto">
          <div className="w-[75%] m-auto">
            {/* <div className="flex p-10">
              <div>
                <img className="logo_term_conditions" src={logo}></img>
              </div>
              <div className="flex">
                <h1 className="text-[#32325e] font-bold items-center	text-[20px] flex">
                  AJIVAINFOTECH PRIVATE LIMITED
                </h1>
              </div>
            </div> */}

            {/* <div className="flex w-1/3 justify-center p-5">
                <div>
                  <h1 className="p-4 text-[#757a91] mt-5 cursor-pointer ">
                    <a href="/terms_conditions ">Terms and Conditions</a>
                  </h1>
                  <h1 className="p-4 text-[#757a91] cursor-pointer ">
                    <a href="/refund_policy"> Cancellation and Refund Policy</a>
                  </h1>

                  <h1 className="p-4 text-[#757a91] cursor-pointer ">
                    {" "}
                    <a href="/privacy_policy">Privacy Policy</a>
                  </h1>
                  <h1 className="font-semibold	text-[16px] cursor-pointer  p-5">
                    <a href="/shipping_policy">
                      Shipping and Delivery Policy
                      <i class="fa-solid fa-chevron-up rotate-90"></i>
                    </a>
                  </h1>
                </div>
              </div> */}

            <div className=" w-full text-[#515978] committed_to_ensuring shadow-lg ">
              <h1 className="font-bold text-[25px] p-3 ">
                Shipping & Delivery Policy
              </h1>
              <h1 className="border-2	w-[30px]  border-[#213554] ml-3"></h1>
              <p className="font-bold p-3">Last updated on Oct 20th 2023</p>
              <div className="w-3/4">
                <div>
                  <p className="p-3 text-[#515978] text-[14px]">
                    No cancellations & Refunds are entertained
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Terms_Conditions;
