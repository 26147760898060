import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
// import slide_01 from "../img/banner/001.webp";
import { Carousel, initTWE } from "tw-elements";
import { headers } from "../helpers/variables";
import lets from "../img/Lats-talk.webp";
import help from "../img/Need-help.webp";
import pdf from "../img/Pdf.webp";
import slide_02 from "../img/about-section logos/Groupbanner.png";
import round from "../img/round_img.png";
import man from "../img/gfa_logo.png";
import slide_03 from "../img/banner/003.webp";
import slide_01_mobile from "../img/background_img/mob_view_001.jpg";
import slide_02_mobile from "../img/background_img/mob_view_002.jpg";
import slide_03_mobile from "../img/background_img/mob_view_003.jpg";
import slide_04 from "../img/banner/004.webp";
import slide_01 from "../img/banner/002.jpg";
import customers_img from "../img/Mobile.webp";
import galina from "../img/galina.png";
import dub_img from "../img/A_Img.webp";
import s from "../img/s.png";
import jesica from "../img/jasica.png";
import jcclient from "../img/jc.png";
import Jim from "../img/j.png";
import dub_img_icon1 from "../img/prog_icon_1.webp";
import dub_img_icon2 from "../img/prog_icon_2.webp";
import dub_img_icon3 from "../img/prog_icon_3.webp";
import find_img from "../img/WebDevelopment/new_2home.png";
import Prinka_thakur from "../img/Prinka_thakur.webp";
import abhishak from "../img/abhishak.webp";
import ajay from "../img/ajay.webp";
import arti from "../img/arti.webp";
import Kamaljeet from "../img/Kamaljeet.webp";
import diksha from "../img/diksha.webp";
import dinesh from "../img/dinesh.webp";
import Gautam from "../img/gautham.webp";
import sony from "../img/Sony.webp";
import neelam from "../img/neelam.webp";
import neeraj from "../img/neeraj.webp";
import sumit from "../img/sumit.webp";
import sunil from "../img/sunil.webp";
import one from "../img/one.webp";
import twillo from "../img/027.png";
import pard from "../img/026.png";
import ontrapate from "../img/025.png";
import big from "../img/024.png";
import bit from "../img/023.png";
import Agille from "../img/022.png";
import Quick from "../img/021.png";
import Aircell from "../img/020.png";
import two from "../img/two.webp";
import three from "../img/three.webp";
import four from "../img/four.webp";
import five from "../img/five.webp";
import six from "../img/six.webp";
// import sunjay_kumar from "../img/sunjay_kumar.webp";
import surinder from "../img/surinder.webp";
import data from "../img/data_mangemant.webp";

import logo from "../img/logo192.png";
import EcommerceDevelopmentnew from "../../src/img/new/ecommerce.png";
import Frontend_Development_hover from "../../src/img/new/frontend.png";
import WebDevelopmentnew from "../../src/img/new/webdevelopment.png";
import Web_Graphic_Designnew from "../../src/img/new/Web_Graphic_Designnew.png";
import API_Integration_new from "../../src/img/new/apiintgration.png";
import Mobile_App_Developmentnew from "../../src/img/new/mobileAppdevelopment.png";
import CRMDevelopmentnew from "../../src/img/new/crmdevlopment.png";

// import Web_Graphic_Designnew from "../src/img/new/Web_Graphic_Designnew.png";
import EcommerceDevelopment from "../../src/Newicon_img/done/Ecommerce_Development.png";
import WebDevelopment from "../../src/Newicon_img/done/Website_Development.png";
import Web_Graphic_Design from "../../src/Newicon_img/done/Web_Graphic_Design.png";
import API_Integration from "../../src/Newicon_img/done/AP_-Integration.png";
import BackendDevelopment from "../../src/Newicon_img/done/Backend-Developmen.png";
import CRMDevelopment from "../../src/Newicon_img/done/CRM_Development.png";
import Mobile_App_Development from "../../src/Newicon_img/done/Mobile_App_Development.png";
import Frontend_Development from "../../src/Newicon_img/done/Front-end-Development.png";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  get_api_request,
  post_api_request,
  put_api_request,
  delete_api_request,
  api_url,
} from "../helpers/Api.js";

// import { Carousel } from "react-responsive-carousel";
import { CircularProgressbar } from "react-circular-progressbar";
import "animate.css";
import "react-circular-progressbar/dist/styles.css";
import { NavLink, useParams, BrowserRouter } from "react-router-dom";
const Home = () => {
  const [percentage, setPercentage] = useState(0);
  const [percentage1, setPercentage1] = useState(0);
  const [percentage2, setPercentage2] = useState(0);
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [slide_02, slide_01, slide_04, slide_03];

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  useEffect(() => {
    setTimeout(() => {
      if (percentage < 92) {
        setPercentage(percentage + 1);
      }
      if (percentage1 < 86) {
        setPercentage1(percentage1 + 1);
      }
      if (percentage2 < 72) {
        setPercentage2(percentage2 + 1);
      }
    }, 50);
    const interval = setInterval(() => {
      goToNext();
    }, 3000); // Change image every 3 seconds

    return () => {
      clearInterval(interval);
    };
  }, [percentage, percentage1, percentage2, currentIndex]);

  function submit_form(form_id) {
    let e = document.getElementById(form_id),
      l = new FormData(e),
      t = Array.from(l.entries());
    var n = {};
    for (let o = 0; o < t.length; o++) {
      var s = t[o],
        i = s[0],
        y = s[1];
      y.length > 0 && (void 0 != n[i] ? (n[i] = n[i] + "," + y) : (n[i] = y));
    }
    return n;
  }
  const handleformsubmit = () => {
    var feildsvalue = submit_form("contactid");
    console.log(feildsvalue);
    var payload = {};
    payload["fullname"] = feildsvalue?.fullname;
    payload["email"] = feildsvalue?.email;
    payload["phone"] = feildsvalue?.phone;
    payload["city"] = feildsvalue?.address;
    payload["message"] = feildsvalue?.message;
    async function Createcontact(payload) {
      const url = "/contactus/";
      const response = await post_api_request(url, payload, headers);
      if (response.status == 201) {
        // notification.success({
        //   message: response?.data?.message,
        // });
        document.getElementById("contactid").reset();
      }
    }
    Createcontact(payload);
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="msvalidate.01" content="783DE589CC4C7D888980F6F0E0C4A0BA" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="" />
        <meta
          name="title"
          content="Top Software Development Company | Web Designing & Mobile App 
Development Services - Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" Web Solutions Agency,  Web & Graphic Design, Software & 
Web Development, Mobile App Development, PHP Developer, E-
COMMERCE solutions, API Integration Services, Nord.js, React, zapier, Full 
Stack Developers, Wordpress AMC services, CRM development, App 
development, Software solutions"
        />
        <meta
          name="description"
          content=" Ajiva Infotech is a top software development company or Agency 
with skilled professional web developers, offering cutting-edge services in 
web design, software development, mobile apps, e-commerce solutions, 
and API integration. "
        />
        <link rel="apple-touch-icon" href={logo} />
        <title>
          Top Software Development Company | Web Designing & Mobile App
          Development Services - Ajiva Infotech
        </title>
      </Helmet>
      {/* <div className="home_slider">
        <div
          id="carouselExampleCaptions"
          className="relative"
          data-te-carousel-init
          data-te-ride="carousel"
        >
          <div
            className="absolute bottom-0 left-0 right-0 z-[2] mx-[15%] mb-4 flex list-none justify-center p-0"
            data-te-carousel-indicators
          >
            <button
              type="button"
              data-te-target="#carouselExampleCaptions"
              data-te-slide-to="0"
              data-te-carousel-active
              className="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-red-600 bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-te-target="#carouselExampleCaptions"
              data-te-slide-to="1"
              className="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-red-600 bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-te-target="#carouselExampleCaptions"
              data-te-slide-to="2"
              className="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-red-600 bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
            <div
              className="relative float-left -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
              data-te-carousel-active
              data-te-carousel-item
              style={{ backfaceVisibility: "hidden" }}
            >
              <img
                src={slide_02}
                width="1349"
                height="422"
                alt="ajivainfotech slider image"
                className="lg:block w-full hidden"
              />
              <img
                src={slide_01_mobile}
                width="1349"
                height="422"
                alt="ajivainfotech slider image"
                className="block w-full lg:hidden block"
              />
            </div>
            <div
              className="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
              data-te-carousel-item
              style={{ backfaceVisibility: "hidden" }}
            >
              <img
                src={slide_03}
                width="1349"
                height="422"
                alt="ajivainfotech slider image two"
                className="lg:block w-full hidden"
              />
              <img
                src={slide_02_mobile}
                width="1349"
                height="422"
                alt="ajivainfotech slider image"
                className="block w-full lg:hidden block"
              />
            </div>
            <div
              className="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
              data-te-carousel-item
              style={{ backfaceVisibility: "hidden" }}
            >
              <img
                src={slide_04}
                width="1349"
                height="422"
                alt="ajivainfotech slider image three"
                className="lg:block w-full hidden"
              />
              <img
                src={slide_03_mobile}
                width="1349"
                height="422"
                alt="ajivainfotech slider image"
                className="block w-full lg:hidden block"
              />
            </div>
          </div>
          <button
            className="absolute bottom-0 left-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
            type="button"
            data-te-target="#carouselExampleCaptions"
            data-te-slide="prev"
          >
            <span className="inline-block h-8 w-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </span>
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Previous
            </span>
          </button>
          <button
            className="absolute bottom-0 right-0 top-0 z-[1] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
            type="button"
            data-te-target="#carouselExampleCaptions"
            data-te-slide="next"
          >
            <span className="inline-block h-8 w-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </span>
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Next
            </span>
          </button>
        </div>
      </div> */}
      <div id="unique-slider" className="unique-slider-container">
        <button onClick={goToPrevious} className="unique-slider-left-arrow">
          &#10094;
        </button>
        <div className="unique-slider-content">
          <img
            src={images[currentIndex]}
            alt="slider"
            className="unique-slider-image"
          />
        </div>
        <button onClick={goToNext} className="unique-slider-right-arrow">
          &#10095;
        </button>
        <div className="unique-slider-dots">
          {images.map((_, index) => (
            <span
              key={index}
              className={`unique-slider-dot ${index === currentIndex ? "active" : ""}`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div>
      <div></div>
      <section className="our_services lg:py-10 fade-bottom reveal my-6">
        <div className=" container m-auto">
          <section>
            <div className="our_services_card  lg:pb-12 lg:px-6">
              <div className="lg:flex justify-center	">
                <p className="h-1 my-4 mx-2 bar"></p>
                <h2 className="text-red-600 lg:text-2xl font-bold flex text-center	">
                  WHAT WE DO
                </h2>
              </div>
              <h1 className="font-bold text-3xl mt-5 text-[#333333] text-center	">
                Our Services
                {/* Comprehensive */}
              </h1>
              <p className="text-gray-600 font-medium text-[14px] lg:mr-10 my-5  commercsalution text-center">
                Ajiva Infotech, A Top Web Development Company or Agency, excels
                in Web & Graphic Design Services, Web Development, Mobile App
                Development, and E-COMMERCE. Our CMS development, Front-end, and
                Backend Development services utilize technologies like PHP,
                Node.js, and React. We offer Full Stack Developers, CRM and app
                development, software solutions, Zapier integrations, and
                WordPress AMC services to meet diverse client needs. Our expert
                team ensures innovative, customized solutions for digital
                transformation.
              </p>
            </div>
          </section>
          <div className="w-full grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 mx-4 my-2">
            <div className="mx-4 my-2">
              <div className="card_hover">
                <div className="card4 border-1 border-red-600">
                  <h2 className="text-xl font-bold py-3 text-[#333333] px-2">
                    Ecommerce Development
                  </h2>
                  <p className="text-base text-[#515151] cardcommercsalution mb-3 font-medium px-2">
                    Welcome to Ajiva Infotech, where we specialize in empowering
                    businesses with tailored e-commerce development solutions.
                    From customizing your online store to optimizing it for
                    search engines, we're here to elevate your digital presence
                    and drive success in the competitive world of e-commerce.
                  </p>

                  <div className="flex justify-between hover-bacground-property">
                    <div className="text-red-600 font-semibold py-2 m-auto ml-0">
                      <div className="flex items-center gap-5">
                        <div className="icone-background">
                          <i className="fa-solid fa-arrow-right text-red-600"></i>
                        </div>
                        <NavLink to="/services/ecommerce-development">
                          View More
                        </NavLink>
                      </div>
                    </div>
                    <i className="fa-solid fa-arrow-right-long mx-2 text-2xl mt-1 hidden"></i>
                    <button className="btn-hover-background">
                      <div className="btn__text-static">
                        <img
                          id="image-1"
                          className="image-background-setting"
                          width="54"
                          height="60"
                          decoding="async"
                          loading="lazy"
                          src={EcommerceDevelopmentnew}
                          alt="ajivainfotech ecommerce images"
                        />
                      </div>
                      <div className="btn__text-dynamic">
                        <div className="">
                          <img
                            id="image-2"
                            width="54"
                            height="60"
                            decoding="async"
                            loading="lazy"
                            src={EcommerceDevelopment}
                            alt="ajivainfotech ecommerce images"
                          />
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mx-4 my-2">
              <div className="card_hover">
                <div className="card4 border-1 border-red-600">
                  <h2 className="text-xl font-bold py-3 text-[#333333] px-2">
                    Front-end Development
                  </h2>
                  <p className="text-base text-[#515151] cardcommercsalution mb-3 font-medium px-2 ">
                    Welcome to Ajiva Infotech, where we specialize in delivering
                    cutting-edge front-end development solutions tailored to
                    meet your unique needs. Our team of skilled developers is
                    proficient in a wide array of technologies and frameworks to
                    ensure that your website or application stands out in
                    today's competitive digital landscape.
                  </p>

                  <div className="flex justify-between hover-bacground-property">
                    <div className="text-red-600 font-semibold py-2 m-auto ml-0">
                      <div className="flex items-center gap-5">
                        <div className="icone-background">
                          <i className="fa-solid fa-arrow-right text-red-600"></i>
                        </div>
                        <NavLink to="/services/frontend-development">
                          View More
                        </NavLink>
                      </div>
                    </div>
                    <i className="fa-solid fa-arrow-right-long mx-2 text-2xl mt-1 hidden"></i>
                    <button className="btn-hover-background">
                      <div className="btn__text-static">
                        <img
                          id="image-1"
                          className="image-background-setting"
                          width="54"
                          height="60"
                          decoding="async"
                          loading="lazy"
                          src={Frontend_Development_hover}
                          alt="ajivainfotech ecommerce images"
                        />
                      </div>
                      <div className="btn__text-dynamic">
                        <div className="">
                          <img
                            id="image-2"
                            width="54"
                            height="60"
                            decoding="async"
                            loading="lazy"
                            src={Frontend_Development}
                            alt="ajivainfotech ecommerce images"
                          />
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mx-4 my-2">
              <div className="card_hover">
                <div className="card4 border-1 border-red-600">
                  <h2 className="text-xl font-bold py-3 text-[#333333] px-2">
                    Mobile App Development
                  </h2>
                  <p className="text-base text-[#515151] cardcommercsalution mb-3 font-medium px-2">
                    We are passionate about delivering effective design
                    solutions that are intuitive and responsive and also
                    correspond with your business objectives. Since design is
                    integral to user experience, we invest largely in
                    researching just what will work for your brand depending on
                    the industry and your target audience. Our commitment
                    towards customer satisfaction reflects in our client
                    portfolio that includes several top B2B and B2C companies
                    from across the globe.
                  </p>

                  <div className="flex justify-between hover-bacground-property">
                    <div className="text-red-600 font-semibold py-2 m-auto ml-0">
                      <div className="flex items-center gap-5">
                        <div className="icone-background">
                          <i className="fa-solid fa-arrow-right text-red-600"></i>
                        </div>
                        <NavLink to="/services/mobile-app-development">
                          View More
                        </NavLink>
                      </div>
                    </div>
                    <i className="fa-solid fa-arrow-right-long mx-2 text-2xl mt-1 hidden"></i>
                    <button className="btn-hover-background">
                      <div className="btn__text-static">
                        <img
                          id="image-1"
                          className="image-background-setting"
                          width="54"
                          height="60"
                          decoding="async"
                          loading="lazy"
                          src={Mobile_App_Developmentnew}
                          alt="ajivainfotech ecommerce images"
                        />
                      </div>
                      <div className="btn__text-dynamic">
                        <div className="">
                          <img
                            id="image-2"
                            width="54"
                            height="60"
                            decoding="async"
                            loading="lazy"
                            src={Mobile_App_Development}
                            alt="ajivainfotech ecommerce images"
                          />
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mx-4 my-2">
              <div className="card_hover">
                <div className="card4 border-1 border-red-600">
                  <h2 className="text-xl font-bold py-3 text-[#333333] px-2">
                    Web Development
                  </h2>
                  <p className="text-base text-[#515151] cardcommercsalution mb-3 font-medium px-2">
                    Welcome to Ajiva Infotech, your trusted partner for expert
                    web development services. As a leading web solutions agency,
                    we specialize in creating custom, high-quality websites
                    tailored to meet the unique needs of businesses like yours.
                  </p>

                  <div className="flex justify-between hover-bacground-property">
                    <div className="text-red-600 font-semibold py-2 m-auto ml-0">
                      <div className="flex items-center gap-5">
                        <div className="icone-background">
                          <i className="fa-solid fa-arrow-right text-red-600"></i>
                        </div>
                        <NavLink to="/services/web-development">
                          View More
                        </NavLink>
                      </div>
                    </div>
                    <i className="fa-solid fa-arrow-right-long mx-2 text-2xl mt-1 hidden"></i>
                    <button className="btn-hover-background">
                      <div className="btn__text-static">
                        <img
                          id="image-1"
                          className="image-background-setting"
                          width="54"
                          height="60"
                          decoding="async"
                          loading="lazy"
                          src={WebDevelopmentnew}
                          alt="ajivainfotech ecommerce images"
                        />
                      </div>
                      <div className="btn__text-dynamic">
                        <div className="">
                          <img
                            id="image-2"
                            width="54"
                            height="60"
                            decoding="async"
                            loading="lazy"
                            src={WebDevelopment}
                            alt="ajivainfotech ecommerce images"
                          />
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mx-4 my-2">
              <div className="card_hover">
                <div className="card4 border-1 border-red-600">
                  <h2 className="text-xl font-bold py-3 text-[#333333] px-2">
                    Graphic Design
                  </h2>
                  <p className="text-base text-[#515151] cardcommercsalution mb-3 font-medium px-2">
                    Web & Graphic design in an impressive way is not just a
                    profession but a passion for our Team. We believe in the
                    idea that a good look of any website is the first impression
                    on visitors. Good design and graphics of any website is the
                    starting point of any website. We have a special team for
                    Website Design that has been involved in designing
                    professional websites of all categories. Our websites and
                    search engines are optimized and user-friendly.
                  </p>

                  <div className="flex justify-between hover-bacground-property">
                    <div className="text-red-600 font-semibold py-2 m-auto ml-0">
                      <div className="flex items-center gap-5">
                        <div className="icone-background">
                          <i className="fa-solid fa-arrow-right text-red-600"></i>
                        </div>
                        <NavLink to="/services/graphic-design">
                          View More
                        </NavLink>
                      </div>
                    </div>
                    <i className="fa-solid fa-arrow-right-long mx-2 text-2xl mt-1 hidden"></i>
                    <button className="btn-hover-background">
                      <div className="btn__text-static">
                        <img
                          id="image-1"
                          className="image-background-setting"
                          width="54"
                          height="60"
                          decoding="async"
                          loading="lazy"
                          src={Web_Graphic_Designnew}
                          alt="ajivainfotech ecommerce images"
                        />
                      </div>
                      <div className="btn__text-dynamic">
                        <div className="">
                          <img
                            id="image-2"
                            width="54"
                            height="60"
                            decoding="async"
                            loading="lazy"
                            src={Web_Graphic_Design}
                            alt="ajivainfotech ecommerce images"
                          />
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mx-4 my-2">
              <div className="card_hover">
                <div className="card4 border-1 border-red-600">
                  <h2 className="text-xl font-bold py-3 text-[#333333] px-2">
                    API Integration
                  </h2>
                  <p className="text-base text-[#515151] cardcommercsalution mb-3 font-medium px-2">
                    Welcome to Our API Integration Services At Ajiva Infotech,
                    we specialize in providing seamless API integration
                    solutions to elevate your business's performance and
                    efficiency. Whether you're looking to integrate third-party
                    APIs, streamline communication between systems, or enhance
                    your digital infrastructure, our team of API integration
                    specialists is here to help.
                  </p>

                  <div className="flex justify-between hover-bacground-property">
                    <div className="text-red-600 font-semibold py-2 m-auto ml-0">
                      <div className="flex items-center gap-5">
                        <div className="icone-background">
                          <i className="fa-solid fa-arrow-right text-red-600"></i>
                        </div>
                        <NavLink to="/services/api-integration">
                          View More
                        </NavLink>
                      </div>
                    </div>
                    <i className="fa-solid fa-arrow-right-long mx-2 text-2xl mt-1 hidden"></i>
                    <button className="btn-hover-background">
                      <div className="btn__text-static">
                        <img
                          id="image-1"
                          className="image-background-setting"
                          width="54"
                          height="60"
                          decoding="async"
                          loading="lazy"
                          src={API_Integration_new}
                          alt="ajivainfotech ecommerce images"
                        />
                      </div>
                      <div className="btn__text-dynamic">
                        <div className="">
                          <img
                            id="image-2"
                            width="54"
                            height="60"
                            decoding="async"
                            loading="lazy"
                            src={API_Integration}
                            alt="ajivainfotech ecommerce images"
                          />
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mx-4 my-2">
              <div className="card_hover">
                <div className="card4 border-1 border-red-600">
                  <h2 className="text-xl font-bold py-3 text-[#333333] px-2">
                    Backend Development
                  </h2>
                  <p className="text-base text-[#515151] cardcommercsalution mb-3 font-medium px-2">
                    Unlock the potential of modern web development with PHP and
                    Laravel. PHP, the cornerstone of web scripting, combined
                    with the power of Laravel, a leading PHP framework, forms
                    the perfect duo for crafting dynamic, efficient, and secure
                    web solutions.
                  </p>

                  <div className="flex justify-between hover-bacground-property">
                    <div className="text-red-600 font-semibold py-2 m-auto ml-0">
                      <div className="flex items-center gap-5">
                        <div className="icone-background">
                          <i className="fa-solid fa-arrow-right text-red-600"></i>
                        </div>
                        <NavLink to="#">View More</NavLink>
                      </div>
                    </div>
                    <i className="fa-solid fa-arrow-right-long mx-2 text-2xl mt-1 hidden"></i>
                    <button className="btn-hover-background">
                      <div className="btn__text-static">
                        <img
                          id="image-1"
                          className="image-background-setting"
                          width="54"
                          height="60"
                          decoding="async"
                          loading="lazy"
                          src={API_Integration_new}
                          alt="ajivainfotech ecommerce images"
                        />
                      </div>
                      <div className="btn__text-dynamic">
                        <div className="">
                          <img
                            id="image-2"
                            width="54"
                            height="60"
                            decoding="async"
                            loading="lazy"
                            src={BackendDevelopment}
                            alt="ajivainfotech ecommerce images"
                          />
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mx-4 my-2">
              <div className="card_hover">
                <div className="card4 border-1 border-red-600">
                  <h2 className="text-xl font-bold py-3 text-[#333333] px-2">
                    CRM Development
                  </h2>
                  <p className="text-base text-[#515151] cardcommercsalution mb-3 font-medium px-2">
                    We excel in crafting bespoke CRM solutions tailored to suit
                    your business needs. A well-designed CRM system can
                    revolutionize the way you manage relationships with your
                    customers, streamline processes, and drive growth.
                  </p>

                  <div className="flex justify-between hover-bacground-property">
                    <div className="text-red-600 font-semibold py-2 m-auto ml-0">
                      <div className="flex items-center gap-5">
                        <div className="icone-background">
                          <i className="fa-solid fa-arrow-right text-red-600"></i>
                        </div>
                        <NavLink to="/services/crm-development">
                          View More
                        </NavLink>
                      </div>
                    </div>
                    <i className="fa-solid fa-arrow-right-long mx-2 text-2xl mt-1 hidden"></i>
                    <button className="btn-hover-background">
                      <div className="btn__text-static">
                        <img
                          id="image-1"
                          className="image-background-setting"
                          width="54"
                          height="60"
                          decoding="async"
                          loading="lazy"
                          src={CRMDevelopmentnew}
                          alt="ajivainfotech ecommerce images"
                        />
                      </div>
                      <div className="btn__text-dynamic">
                        <div className="">
                          <img
                            id="image-2"
                            width="54"
                            height="60"
                            decoding="async"
                            loading="lazy"
                            src={CRMDevelopment}
                            alt="ajivainfotech ecommerce images"
                          />
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-4 my-2">
              <div className="card_hover">
                <div className="card4 border-1 border-red-600">
                  <h2 className="text-xl font-bold py-3 text-[#333333] px-2">
                    Website Design
                  </h2>
                  <p className="text-base text-[#515151] cardcommercsalution mb-3 font-medium px-2">
                    We believe that a high-quality website shouldn’t break the
                    bank. Our affordable website design services offer
                    exceptional value without compromising on quality, making it
                    accessible for businesses of all sizes.
                  </p>

                  <div className="flex justify-between hover-bacground-property">
                    <div className="text-red-600 font-semibold py-2 m-auto ml-0">
                      <div className="flex items-center gap-5">
                        <div className="icone-background">
                          <i className="fa-solid fa-arrow-right text-red-600"></i>
                        </div>
                        <NavLink to="/services/website-design">
                          View More
                        </NavLink>
                      </div>
                    </div>
                    <i className="fa-solid fa-arrow-right-long mx-2 text-2xl mt-1 hidden"></i>
                    <button className="btn-hover-background">
                      <div className="btn__text-static">
                        <img
                          id="image-1"
                          className="image-background-setting"
                          width="54"
                          height="60"
                          decoding="async"
                          loading="lazy"
                          src={CRMDevelopmentnew}
                          alt="ajivainfotech ecommerce images"
                        />
                      </div>
                      <div className="btn__text-dynamic">
                        <div className="">
                          <img
                            id="image-2"
                            width="54"
                            height="60"
                            decoding="async"
                            loading="lazy"
                            src={CRMDevelopment}
                            alt="ajivainfotech ecommerce images"
                          />
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mx-4 my-2">
              <div className="card_hover">
                <div className="card4 border-1 border-red-600">
                  <h2 className="text-xl font-bold py-3 text-[#333333] px-2">
                    Software-Development
                  </h2>
                  <p className="text-base text-[#515151] cardcommercsalution mb-3 font-medium px-2">
                    We believe that a high-quality website shouldn’t break the
                    bank. Our affordable website design services offer
                    exceptional value without compromising on quality, making it
                    accessible for businesses of all sizes.
                  </p>

                  <div className="flex justify-between hover-bacground-property">
                    <div className="text-red-600 font-semibold py-2 m-auto ml-0">
                      <div className="flex items-center gap-5">
                        <div className="icone-background">
                          <i className="fa-solid fa-arrow-right text-red-600"></i>
                        </div>
                        <NavLink to="/services/software-development">
                          View More
                        </NavLink>
                      </div>
                    </div>
                    <i className="fa-solid fa-arrow-right-long mx-2 text-2xl mt-1 hidden"></i>
                    <button className="btn-hover-background">
                      <div className="btn__text-static">
                        <img
                          id="image-1"
                          className="image-background-setting"
                          width="54"
                          height="60"
                          decoding="async"
                          loading="lazy"
                          src={CRMDevelopmentnew}
                          alt="ajivainfotech ecommerce images"
                        />
                      </div>
                      <div className="btn__text-dynamic">
                        <div className="">
                          <img
                            id="image-2"
                            width="54"
                            height="60"
                            decoding="async"
                            loading="lazy"
                            src={CRMDevelopment}
                            alt="ajivainfotech ecommerce images"
                          />
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" ensure_customers relative reveal fade-left">
        <div className="aj_ensure_customers_bg relative w-full">
          <div className="aj_ensure_customers_content_info">
            <div className="container m-auto lg:py-10">
              <div className="aj_ensure_customers_content absolute lg:ml-0 top-0 lg:mr-10 mx-4">
                <div className="container m-auto">
                  <div className="w-full lg:flex ">
                    <div className="lg:w-1/2 lg:flex m-auto ">
                      <div className="lg:p-10 lg:pl-0 p-5 ">
                        <div className="flex">
                          <p className="h-1 my-3 mx-2 bar"></p>
                          <h2 className="text-xl font-bold text-white lg:flex  Find_More_Business ">
                            Find More Business
                          </h2>
                        </div>
                        <p className="lg:text-3xl  Ensure_Customers_Find font-bold uppercase  text-white pt-4">
                          Ensure Customers Find You Find More Business
                        </p>
                        <p className="lg:mr-10 lg:text-[13px] text-justify  lg:font-500 text-sm font-semibold business_owners_need	 text-gray-300 pb-12 pt-7">
                          Unlock your business potential with Ajiva Infotech's
                          app design and development services. We create
                          innovative, user-friendly mobile apps that enhance
                          your online presence. Our expert App developers ensure
                          your app stands out in a competitive market, driving
                          customer engagement and business growth. Partner with
                          us to transform your ideas into successful mobile
                          solutions. Get found online with a custom mobile app
                          that meets your unique needs
                        </p>
                        <NavLink
                          className=" Start_Getting_Found group relative inline-block overflow-hidden  bg-white lg:px-8 lg:py-3 px-4 py-4 "
                          to="/"
                        >
                          <span className="absolute inset-y-0 left-0 w-[0px] bg-red-600 transition-all group-hover:w-full group-active:bg-red-600"></span>

                          <span
                            className="relative text-lg font-semibold text-gray-700 transition-colors group-hover:text-white uppercase"
                            onClick={() => setOpen(true)}
                          >
                            Start Getting Found
                          </span>
                        </NavLink>
                      </div>
                    </div>
                    <div className="lg:w-1/2 aj_clip_img">
                      <div className="aj_ensure_customers_img">
                        <img
                          decoding="async"
                          loading="lazy"
                          src={customers_img}
                          width="360"
                          height="533"
                          alt="ajivainfotech customers images"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="lg:py-10 lg:my-8 relative  overflow-hidden">
        <div className="container m-auto relative">
          <div className="w-full lg:flex relative z-10">
            <div className="lg:w-1/2 lg:flex lg:items-center">
              <img
                decoding="async"
                loading="lazy"
                src={find_img}
                width="512"
                height="424"
                alt="ajivainfotech website find images"
                className="lg:mx-4 lg:p-0 p-5 lg:w-4/5	z-10 relative"
              />
            </div>
            <div className="lg:w-1/2 z-10 flex items-center	">
              <div className="our-services_content p-4">
                <div className="lg:flex">
                  <p className="h-1 my-4 mx-2  bar"></p>
                  <h2 className="text-red-600 lg:text-2xl font-bold uppercase flex">
                    Find websites built
                  </h2>
                </div>
                <p className="font-extrabold	 text-2xl lg:text-4xl text-[#333333] my-2 uppercase">
                  Websites Built For Success
                </p>
                <p className="font-bold	 lg:text-3xl text-[#333333] text-2xl mt-2 lg:mb-10 uppercase">
                  Who Are You, What Do You Do
                </p>
                <p className="text-[#333333] Ensure-Customers	  lg:mr-10 my-5">
                  At Ajiva Infotech, we build websites designed for success. Our
                  comprehensive web design services create visually appealing
                  and functional sites.
                </p>
                <p className="text-[#333333]   Ensure-Customers  lg:mr-10 mt-5 lg:mb-8">
                  We combine top-tier graphic design with expert website
                  development to ensure your site stands out. Whether you need a
                  dynamic e-commerce platform or a sleek corporate site. Trust
                  our team to deliver a website that not only looks great but
                  also performs exceptionally, helping your business thrive
                  online.
                </p>

                {/* <a
                  className="w-full lg:w-auto my-2 lg:my-0  inline-block rounded border border-gray-600  px-10 py-3 text-base font-medium text-[#333333]  hover:bg-red-600 hover:text-white hover:border-red-600 focus:outline-none focus:ring active:text-indigo-500 mr-1 uppercase"
                  href="/download"
                >
                  Landing Page
                </a>

                <a
                  className="w-full lg:w-auto my-2 lg:my-0  inline-block rounded border border-gray-600 px-10 py-3 text-base font-medium text-[#333333] hover:bg-red-600 hover:border-red-600  hover:text-white focus:outline-none focus:ring active:bg-red-500 mx-1 uppercase"
                  href="/download "
                >
                  Multi Page
                </a>
                <a
                  className="w-full lg:w-auto my-2 lg:my-0 inline-block rounded border border-gray-600 px-10 py-3 text-base font-medium text-[#333333] hover:bg-red-600 hover:border-red-600  hover:text-white focus:outline-none focus:ring active:bg-red-500 mx-1 uppercase"
                  href="/download"
                >
                  E-Commerce
                </a> */}
              </div>
            </div>
          </div>
          <div className="dec_cirlce">
            <span></span>
          </div>
        </div>
      </section>

      <section className="ourtestimonials bg-[#efefef]">
        <div className="container m-auto">
          <div className="w-full lg:flex">
            <div className="lg:w-1/2">
              <div className="lg:py-24 px-10 lg:my-10">
                <div className="text-[#da0006] lg:text-4xl text-2xl font-extrabold py-3 uppercase">
                  Client
                  <span className="text-[#343434] italic font-normal border-b-2 border-b-red-600 mx-2 lowercase">
                    testimonials
                  </span>
                </div>
                <h2 className="lg:text-4xl text-xl font-bold text-[#343434] py-2">
                  What they are talking about us
                </h2>
                <p className="text-[#4b4b4b]">
                  {/* Awesome services, these guys has made my website running
                  beyond my expectations. I would like to recommend
                  Ajivainfotech to everyone as I found more professional in
                  their work */}
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 relative lg:py-10 lg:px-10 px-4 py-4 flex items-center Testimonials-main">
              <div className="js_our-services_content slidercrm ">
                <div className="owl-item active slide-track">
                  <div className="testimonial-block slideimg_in">
                    <div className="inner-box">
                      <div className="image-box">
                        <figure className="image">
                          <img
                            decoding="async"
                            loading="lazy"
                            width="100"
                            height="100"
                            alt="image-box"
                            src={man}
                          />
                        </figure>
                        <div className="flex lg:my-0 mt-12">
                          <div className="info-box">
                            <p className="name font-semibold text-xl">
                              Gun free America
                            </p>
                            <span className="designation text-base">
                              Gun free America
                            </span>
                          </div>
                          <div className="rating py-2">
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                          </div>
                        </div>
                      </div>
                      <div className="text-[14px]  Testimonials text-[#5c5c5c] ">
                        Awesome services, these guys has made my website running
                        beyond my expectations. I would like to recommend
                        Ajivainfotech to everyone as I found more professional
                        in their work
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-block slideimg_in">
                    <div className="inner-box">
                      <div className="image-box">
                        <figure className="image">
                          <img
                            decoding="async"
                            loading="lazy"
                            width="100"
                            height="100"
                            alt="image-box"
                            src={jcclient}
                          />
                        </figure>
                        <div className="flex lg:my-0 mt-12">
                          <div className="info-box">
                            <p className="name font-semibold text-xl">
                              JC WebWork
                            </p>
                            <span className="designation text-base">
                              JC WebWork
                            </span>
                          </div>
                          <div className="rating py-2">
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                          </div>
                        </div>
                      </div>
                      <div className="Testimonials text-[#5c5c5c] ">
                        This year, I decided i wanted to start a small business.
                        Yeah-pretty scary enough, but I had no idea where to
                        start. Upon much thought and consideration of our
                        current technology, I decided to start with a website. I
                        have created and made many websites on my own, this time
                        I decided I wanted to invest in a professional service.
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-block slideimg_in">
                    <div className="inner-box">
                      <div className="image-box">
                        <figure className="image">
                          <img
                            decoding="async"
                            loading="lazy"
                            width="100"
                            height="100"
                            alt="image-box"
                            src={s}
                          />
                        </figure>
                        <div className="flex lg:my-0 mt-12">
                          <div className="info-box">
                            <p className="name font-semibold text-xl">
                              San Juan
                            </p>
                            <span className="designation text-base">
                              San Juan
                            </span>
                          </div>
                          <div className="rating py-2">
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                          </div>
                        </div>
                      </div>
                      <div className="Testimonials text-[#5c5c5c] ">
                        Offshore developers usually have one of the following:
                        great communication and customer service OR strong
                        development skills OR affordable rates. Rarely do they
                        have more than one. Ajivainfotech has all 3! I'm super
                        tough to impress and hate wasting time on ineffective
                        developers.
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-block slideimg_in">
                    <div className="inner-box">
                      <div className="image-box">
                        <figure className="image">
                          <img
                            decoding="async"
                            loading="lazy"
                            width="100"
                            height="100"
                            alt="image-box"
                            src={Jim}
                          />
                        </figure>
                        <div className="flex lg:my-0 mt-12">
                          <div className="info-box">
                            <p className="name font-semibold text-xl">
                              Jim Sweeney
                            </p>
                            <span className="designation text-base">
                              Jim Sweeney
                            </span>
                          </div>
                          <div className="rating py-2">
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                          </div>
                        </div>
                      </div>
                      <div className="Testimonials text-[#5c5c5c] ">
                        Great work, I've been a client for 3+ years and Ajiva is
                        crucial to my business due to their ability to create
                        real tech solutions fast!
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-block slideimg_in">
                    <div className="inner-box">
                      <div className="image-box">
                        <figure className="image">
                          <img
                            decoding="async"
                            loading="lazy"
                            width="100"
                            height="100"
                            alt="image-box"
                            src={galina}
                          />
                        </figure>
                        <div className="flex lg:my-0 mt-12">
                          <div className="info-box">
                            <p className="name font-semibold text-xl">
                              Galina Mikova
                            </p>
                            <span className="designation text-base">Usa</span>
                          </div>
                          <div className="rating py-2">
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                          </div>
                        </div>
                      </div>
                      <div className="Testimonials text-[#5c5c5c] ">
                        Very Happy. Fast, responsive and did exactly as
                        instructed. Will definitely work with Ajiva infotech.
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-block slideimg_in">
                    <div className="inner-box">
                      <div className="image-box">
                        <figure className="image">
                          <img
                            decoding="async"
                            loading="lazy"
                            width="100"
                            height="100"
                            alt="image-box"
                            src={jesica}
                          />
                        </figure>
                        <div className="flex lg:my-0 mt-12">
                          <div className="info-box">
                            <p className="name font-semibold text-xl">
                              Jessica Barna
                            </p>
                            <span className="designation text-base">
                              Australia
                            </span>
                          </div>
                          <div className="rating py-2">
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                            <i className="fa fa-star lg:text-2xl"></i>
                          </div>
                        </div>
                      </div>
                      <div className="Testimonials text-[#5c5c5c] ">
                        When it comes to addressing some challenging
                        requirements we had for website integrations, Ajiva
                        Infotech excels. We will use Ajiva Infotech again since
                        they were responsive, quick to answer, and available on
                        video call when necessary.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" relative ">
        <div className="container m-auto relative">
          <div className="w-full lg:flex">
            <div className="lg:w-1/2	lg:flex lg:items-center	 bg-zinc-900 fade-left reveal">
              <div className=" p-6">
                <div className="lg:flex">
                  <p className="h-1 my-4 mx-2  bar"></p>
                </div>
                <h1 className="lg:text-6xl text-2xl  font-extrabold uppercase text-white pt-4 mr-8">
                  Information on 30+ Directories
                </h1>
                <p className="lg:mr-10 mr-0 lg:text-base text-sm font-semibold text-gray-300 pb-6 pt-7 lg:w-96">
                  Showing Your Business Off Where It Matters Are your online
                  listings showing up on these leading sites?
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 fade-right reveal">
              <div className="data_management">
                <img
                  decoding="async"
                  loading="lazy"
                  src={data}
                  width="640"
                  height="413"
                  className=""
                  alt="data images website ajivainfotech"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="aj_data_circle">
          <h2 className="uppercase">
            Data <br></br>management
          </h2>
        </div>
      </section>

      <section className="py-10 mb-8 mt-0 aj_about_ajiva fade-bottom reveal">
        <div className="container m-auto">
          <div className="w-full text-center">
            <div className="our-services_content p-4">
              <h2 className="text-red-600 lg:text-2xl text-xl font-semibold uppercase flex justify-center">
                crm & Erp integration
              </h2>
              <div className="flex justify-center">
                <p className="h-1  my-4 mx-2  bar"></p>
              </div>
              <h1 className="font-extrabold	 lg:text-4xl text-2xl text-[#161616] my-2 uppercase">
                Let us help you setup your
              </h1>
              <h1 className="font-bold	 lg:text-3xl text-xl text-[#161616] mt-2 mb-3 uppercase">
                CRM & automation
              </h1>
              <button
                className="bg-red-600 py-2 px-4 text-white text-xl my-1 font-semibold"
                onClick={(e) => setOpen(true)}
              >
                Let's Connect
              </button>
            </div>
            <div className="border-b border-[#b4b4b4] my-8"></div>
            <div className="js_our-services_content slidercrm ">
              <div className=" grid lg:grid-cols-6 grid-cols-3 lg:gap-4 gap-2 my-2 slide-track">
                <div className="slideimg">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={one}
                    width="143"
                    height="125"
                    alt="one images services card"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={two}
                    width="143"
                    height="125"
                    alt="two images services card"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={three}
                    width="143"
                    height="125"
                    alt="three images services card"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={four}
                    width="143"
                    height="125"
                    alt="four images services card"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={five}
                    width="143"
                    height="125"
                    alt="five images services card"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={six}
                    width="143"
                    height="125"
                    alt="six images services card"
                    className="lg:m-5"
                  />
                </div>

                <div className="slideimg">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={twillo}
                    width="143"
                    height="125"
                    alt="six services images services card"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={pard}
                    width="143"
                    height="125"
                    alt="six services images services card"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={ontrapate}
                    width="143"
                    height="125"
                    alt="six services images services card"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={big}
                    width="143"
                    height="125"
                    alt="six services images services card"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={bit}
                    width="143"
                    height="125"
                    alt="six services images services card"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={Agille}
                    width="143"
                    height="125"
                    alt="six services images services card"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={Quick}
                    width="143"
                    height="125"
                    alt="six services images services card"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={Aircell}
                    width="143"
                    height="125"
                    alt="six services images services card"
                    className="lg:m-5"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="About_page_hub lg:my-10 lg:py-8">
        <div className="container m-auto">
          <div className="w-full lg:flex">
            <div className="lg:w-1/2 md:w-1/2 sm:m-auto sm:w-full mx-4 fade-right reveal">
              <div className="aj_about-page-hub mx-8 pt-6">
                <div className="relative">
                  <img
                    src={round}
                    width="524"
                    height="518"
                    alt="round_img ajivainfotech web images"
                    className="relative bg-image rotateme"
                  />
                </div>
                <img
                  decoding="async"
                  loading="lazy"
                  src={dub_img}
                  width="544"
                  height="497"
                  alt="GET FOUND,GET NOTICED
                  GET AJIVA INFOTECH"
                  className="absolute top-2 right-8  p-4 left-0 "
                />
              </div>
            </div>
            <div className="lg:w-1/2 md:w-1/2 sm:w-full sm:m-auto mx-4 fade-left reveal mt-5 lg:flex lg:m-auto">
              <div className="our_services_card  py-4">
                <div className="lg:flex"></div>
                <h1 className=" lg:text-4xl text-2xl font-bold text-[#161616] pb-1">
                  GET FOUND,GET NOTICED
                </h1>
                <h1 className=" lg:text-4xl text-2xl font-bold text-[#161616] pb-3">
                  GET AJIVA INFOTECH
                </h1>
                <p className="text-base lg:text-[14px]  text-gray-800 pb-4">
                  We are your all-in-digital marketing solution for Google My
                  Business management and coustom mobile, optimized websites.
                </p>
                <div className="lg:flex py-2 ">
                  <div className="icon py-2 relative lg:w-50">
                    <div className="m-auto lg:w-[100px]">
                      <CircularProgressbar value={percentage1} />
                    </div>
                    <div className="flex">
                      <img
                        decoding="async"
                        loading="lazy"
                        src={dub_img_icon1}
                        width="38"
                        height="35"
                        alt="dub images icons website ajivainfotech "
                      />
                    </div>
                  </div>
                  <div className="score_content lg:text-5xl text-3xl text-red-600 p-4 text-center">
                    <h1 className="font-bold ">{`${percentage1}%`}</h1>
                  </div>
                  <div className="text_content lg:p-4 text-center lg:text-left">
                    <p className="text-[14px] font-semibold text-[#333333]">
                      of consumers who did a local search visited a store within
                      five miles
                    </p>
                  </div>
                </div>
                <div className="lg:flex py-2 ">
                  <div className="icon py-2 relative w-50">
                    <div className="m-auto lg:w-[100px]">
                      <CircularProgressbar value={percentage2} />
                    </div>
                    <img
                      decoding="async"
                      loading="lazy"
                      src={dub_img_icon2}
                      width="38"
                      height="35"
                      alt="dub imag icons two website"
                    />
                  </div>
                  <div className="score_content lg:text-5xl text-3xl text-red-600 p-4 text-center">
                    <h1 className=" font-bold ">{`${percentage2}%`}</h1>
                  </div>
                  <div className="text_content lg:p-4 text-center lg:text-left">
                    <p className="text-[14px] font-semibold text-[#333333]">
                      Of searchers haven’t made up their minds about a brand
                      before starting a search
                    </p>
                  </div>
                </div>
                <div className="lg:flex py-2 relative">
                  <div className="icon py-2 relative w-50">
                    <div className="m-auto lg:w-[100px]">
                      <CircularProgressbar value={percentage} />
                    </div>
                    <img
                      decoding="async"
                      loading="lazy"
                      src={dub_img_icon3}
                      width="38"
                      height="35"
                      alt="dub images icons three website"
                    />
                  </div>

                  <div className="score_content lg:text-5xl text-3xl text-red-600 p-4 text-center">
                    <h2 className="font-bold">{`${percentage}%`}</h2>
                  </div>
                  <div className="text_content lg:p-4 text-center lg:text-left">
                    <p className="text-[14px] font-semibold text-[#333333]">
                      Of total organic traffic comes exclusively from Google
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="py-10 staff_member relative bg-[#efefef]">
        <div className="container m-auto relative">
          <div className="w-full text-center">
            <h2 className="lg:text-5xl text-3xl font-bold">
              MEET OUR
              <span className="text-red-600"> TEAM</span>
            </h2>
          
            <div className="slideremployee">
            
              <div className="grid lg:grid-cols-4 grid-cols-2 lg:gap-4 gap-2 lg:p-5 my-2 slide-track2">
               
                <div className="slideimg2">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={sumit}
                    width="250"
                    height="319"
                    alt="sumit ajiva infotech team member"
                    className="lg:m-5"
                  />
                </div>
               
                <div className="slideimg2">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={ajay}
                    width="250"
                    height="319"
                    alt="ajay ajiva infotech team member"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg2">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={dinesh}
                    width="250"
                    height="319"
                    alt="dinesh ajiva infotech team member"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg2">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={arti}
                    width="250"
                    height="319"
                    alt="arti ajiva infotech team member"
                    className="lg:m-5"
                  />
                </div>
               
                <div className="slideimg2">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={diksha}
                    width="250"
                    height="319"
                    alt="diksha ajiva infotech team member"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg2">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={sunil}
                    width="250"
                    height="319"
                    alt="sunil ajiva infotech team member"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg2">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={abhishak}
                    width="250"
                    height="319"
                    alt="abhishak ajiva infotech team member"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg2">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={surinder}
                    width="250"
                    height="319"
                    alt="surinder ajiva infotech team member"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg2">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={Gautam}
                    width="250"
                    height="319"
                    alt="sunil ajiva infotech team member"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg2">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={sony}
                    width="250"
                    height="319"
                    alt="sunil ajiva infotech team member"
                    className="lg:m-5"
                  />
                </div>
                <div className="slideimg2">
                  <img
                    decoding="async"
                    loading="lazy"
                    src={Kamaljeet}
                    width="250"
                    height="319"
                    alt="sunil ajiva infotech team member"
                    className="lg:m-5"
                  />
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </section> */}
      {open == true ? (
        <div className="modal-details-fade ContactVoice">
          <div className="modal-details">
            <i
              className="fa-regular fa-circle-xmark text-red-600"
              style={{ float: "right", fontSize: "22px", cursor: "pointer" }}
              onClick={(e) => setOpen(false)}
            ></i>
            <div className="">
              <section className="">
                <div className="container m-auto">
                  <div className="w-full">
                    <div className="js_contact_content text-center">
                      <h2 className="text-2xl font-bold text-center ">
                        HAVE AN ENQUIRY
                      </h2>
                      <h1 className=" m-auto py-2 text-base text-gray-500 mx-2	">
                        Ajiva Infotech is available for you 24*7.
                      </h1>
                    </div>
                  </div>

                  <div className="w-full flex  p-2 ">
                    <div className="w-1/3 h-50 ">
                      <div className="mx-1 border text-2xl px-2 py-2 uppercase font-bold flex my-3 cursor-pointer">
                        <div className="py-4 border-r-2 border-indigo-500">
                          <img
                            src={help}
                            alt="help ajivainfotech images"
                            className="pr-5"
                          ></img>
                        </div>
                        <div className=" mx-4 ">
                          <h2 className="text-base text-red-600  font-bold ">
                            NEED HELP
                          </h2>
                          <h4 className="text-base text-gray-700 ">
                            +91 8699981982
                          </h4>
                          <h4 className="text-base text-gray-700 ">
                            0172-4084189
                          </h4>
                        </div>
                      </div>
                      <div className="mx-1 border text-2xl px-2 py-2 uppercase font-bold flex my-3 cursor-pointer">
                        <div className="py-4 border-r-2 border-indigo-500">
                          <NavLink
                            to="/static/media/Aj_pdf.85d121be6e88c9117dd7.pdf"
                            download
                          >
                            <img
                              src={pdf}
                              alt="pdf ajivainfotech pdf link download"
                              className=" pr-4"
                            ></img>
                          </NavLink>
                        </div>
                        <div className=" mx-4  ">
                          <h2 className="text-base text-red-600  font-bold ">
                            DOWNLOAD
                          </h2>
                          <h4 className="text-base text-gray-700 ">
                            COMPANY PROFILE
                          </h4>
                        </div>
                      </div>
                      <div className="mx-1 border text-2xl px-2 py-2 uppercase font-bold flex my-3 cursor-pointer">
                        <div className="py-4 border-r-2 border-indigo-500">
                          <img
                            src={lets}
                            alt="lets ajvainfotech images"
                            className=" pr-4"
                          ></img>
                        </div>
                        <div className=" mx-4 ">
                          <h2 className="text-base text-red-600  font-bold ">
                            LET'S TALK YOUR
                          </h2>
                          <h4 className="text-base text-gray-700 ">BUSINESS</h4>

                          <NavLink
                            to="text-white  text-base  font-semibold"
                            //href="mailto:info@ajivainfotech.com"
                            style={{
                              color: "red",
                              fontSize: "13px",
                              textTransform: "none",
                            }}
                          >
                            info@ajivainfotech.com
                          </NavLink>
                        </div>
                      </div>
                    </div>

                    <div className="w-2/3  px-10  ">
                      <h1 className="text-black underline-offset-0 pt-2 text-gray-500">
                        Fill out this form and tell us little bit about
                        yourself. We will get back to you with best solution.
                      </h1>
                      <form
                        action=""
                        name="contactform"
                        id="contactid"
                        className=""
                      >
                        <input
                          type="text"
                          name="fullname"
                          className="w-full rounded-lg border-gray-200 p-2 pe-12 border my-2 "
                          placeholder="Name:"
                        />
                        <input
                          type="email"
                          name="email"
                          className="w-full rounded-lg border-gray-200 p-2 pe-12 border my-2"
                          placeholder="Email:"
                        />
                        <input
                          type="tel"
                          name="phone"
                          className="w-full rounded-lg border-gray-200 p-2 pe-12 border my-2"
                          placeholder="Phone No:"
                        />
                        <input
                          type="text"
                          name="address"
                          className="w-full rounded-lg border-gray-200 p-2 pe-12 border my-2"
                          placeholder="City:"
                        />
                        <textarea
                          className="w-full  rounded-lg border-gray-200 p-2 pe-12 border my-2 h-[80px]"
                          id=""
                          name="message"
                          placeholder="Message:"
                        ></textarea>
                        <button
                          className="inline-block rounded border border-red-600 bg-red-600 py-2 my-1 text-sm font-medium text-white hover:bg-transparent hover:text-red-600 focus:outline-none focus:ring active:text-black-500 w-40
 "
                          type="button"
                          onClick={(e) => handleformsubmit(e)}
                        >
                          SUBMIT
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default Home;
