import React, { useState } from "react";
import beautify from "js-beautify";
import csstree from "css-tree";

const CssValidator = () => {
  const [cssCode, setCssCode] = useState("");
  const [formattedCss, setFormattedCss] = useState("");
  const [error, setError] = useState("");

  const beautifyCss = () => {
    try {
      // Check for syntax errors
      csstree.parse(cssCode);

      // Format CSS code
      const beautifiedCss = beautify.css(cssCode);
      setFormattedCss(beautifiedCss);
      setError("");
    } catch (err) {
      console.error(err); // Log the error to console for debugging
      setError(err.message);
    }
  };

  const handleChange = (e) => {
    setCssCode(e.target.value);
  };

  return (
    <div className="max-w-lg mx-auto my-8 p-4 bg-gray-100 rounded-lg shadow-lg">
      <h2 className="text-xl font-bold mb-4">CSS Validator</h2>
      <textarea
        value={cssCode}
        onChange={handleChange}
        rows={10}
        className="w-full p-2 mb-4 border rounded"
        placeholder="Enter your CSS code here..."
      />
      <button
        onClick={beautifyCss}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        Beautify CSS
      </button>
      {error && <div className="text-red-500 mt-4">{error}</div>}
      <h3 className="text-lg font-semibold mt-4">Formatted CSS:</h3>
      <pre className="overflow-auto bg-gray-200 p-4 rounded">
        {formattedCss}
      </pre>
    </div>
  );
};

export default CssValidator;
