import React, { useState, useCallback, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  get_api_request,
  post_api_request,
  put_api_request,
  api_url,
} from "../../../../helpers/Api";
import { headers } from "../../../../helpers/variables";
const SignIn = () => {
  function submit_form(form_id) {
    let e = document.getElementById(form_id),
      l = new FormData(e),
      t = Array.from(l.entries());
    var n = {};
    for (let o = 0; o < t.length; o++) {
      var s = t[o],
        i = s[0],
        y = s[1];
      y.length > 0 && (void 0 != n[i] ? (n[i] = n[i] + "," + y) : (n[i] = y));
    }
    return n;
  }
  const handleSubmit = () => {
    var data = submit_form("contactid");
    var postData = {
      phoneoremail: data.phoneoremail,
      password: data.password,
    };
    console.log(postData);
    async function Createcontact() {
      const url = "/user/login/";
      const response = await post_api_request(url, postData, headers);
      if (response.status == 201) {
        console.log(response);
        //document.getElementById("contactid").reset();
        //setshowsuccessmsg(true);
        //setshowform(false);
        // notification.success({
        //   message: response?.data?.message,
        // });
      }
    }
    
    Createcontact();
    
    //console.log(postData);
    // let entity = value;
    // if (entity != null) {
    //   console.log(entity);
    // }
    // dispatch(login(postData));
    //dispatch(login(postData,entity));
    // history.push("/dashboard") : history.push("/"); //NOT REQ
  };

  return (
    <div>
      <div className="w-2/3  p-10  m-auto">
        <form action="" name="contactform" id="contactid" class="">
          <input
            type="text"
            name="phoneoremail"
            class="w-full rounded-lg border-gray-200 p-2 pe-12 border my-2 "
            placeholder="Username:"
          />
          <input
            type="password"
            name="password"
            class="w-full rounded-lg border-gray-200 p-2 pe-12 border my-2"
            placeholder="Password:"
          />
          <button
            className="inline-block rounded border border-red-600 bg-red-600 py-2 my-1 text-sm font-medium text-white hover:bg-transparent hover:text-red-600 focus:outline-none focus:ring active:text-black-500 w-40
 "
            type="button"
            onClick={(e) => handleSubmit(e)}
          >
            SUBMIT
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
