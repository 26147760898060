import React, { useState } from "react";
import Helmet from "react-helmet";
import logo from "../img/logo192.png";
import JsonValidator from "../tools_image/Json.png";
import PHP from "../tools_image/PHP.png";
import Html_2 from "../tools_image/Html_2.png";
import CSS from "../tools_image/Css.png";
import Javascript from "../tools_image/js.png";
import HTML_validator from "../tools_image/Html.png";
import Css_minifiy from "../tools_image/Minify.png";
import Convert_Hex_to_RGB from "../tools_image/HEX.png";
import Convert_RGB_to_Hex from "../tools_image/RGB.png";
import Convert_Xml_to_json from "../tools_image/JSON2.png";
import dummy from "../../src/tools_image/Dummy.png";
const tools = [
  { name: "HTML and Preview", img: HTML_validator, link: "/tools/html" },
  { name: "Minyfiy to UnMinyfiy", img: Css_minifiy, link: "/tools/Minyfiy" },
  { name: "JsonValidator", img: JsonValidator, link: "/tools/jsonvalidator" },
  { name: "Validate CSS", img: CSS, link: "/tools/css-validate" },
  { name: "Validate HTML", img: Html_2, link: "/tools/html" },
  { name: "String Replace", img: Javascript, link: "/tools/strreplace" },
  { name: "Run PHP", img: PHP, link: "/tools/php" },
  { name: "Webhook Test", img: JsonValidator, link: "/tools/webhook" },
  { name: "Sitemaps", img: JsonValidator, link: "/tools/sitemaps" },
  {
    name: "Convert Hex to RGB",
    img: Convert_Hex_to_RGB,
    link: "/tools/hex-to-rgb",
  },
  {
    name: "Convert RGB to Hex",
    img: Convert_RGB_to_Hex,
    link: "/tools/rgb-to-hex",
  },
  {
    name: "Convert XML to JSON",
    img: Convert_Xml_to_json,
    link: "/tools/xml-to-json",
  },
  {
    name: "Base64Encode",
    img: Convert_Hex_to_RGB,
    link: "/tools/base64-encode",
  },
  {
    name: "Base64Decode",
    img: Convert_RGB_to_Hex,
    link: "/tools/base64-decode",
  },
  { name: "URLEncode", img: Convert_Xml_to_json, link: "/tools/url-encode" },
  { name: "URLDecode", img: Convert_Hex_to_RGB, link: "/tools/url-decode" },
  {
    name: "Convert PNG to JPG",
    img: Convert_RGB_to_Hex,
    link: "/tools/png-to-jpg",
  },
  {
    name: "Convert JSON to XML",
    img: Convert_Xml_to_json,
    link: "/tools/xml-minify",
  },
  {
    name: "Convert UNIX Time to UTC",
    img: dummy,
    link: "/tools/utc-to-unix",
  },
  {
    name: "Convert H:M:S to Seconds",
    img: dummy,
    link: "/tools/hms-to-seconds",
  },
  {
    name: "Create Bcrypt Password",
    img: dummy,
    link: "/tools/bcrypt",
  },
  {
    name: "Convert JPG to PNG  ",
    img: dummy,
    link: "/tools/jpg-to-png",
  },
  {
    name: "Convert GIF to PNG",
    img: dummy,
    link: "/tools/gif-to-png",
  },
];

const ToolButtons = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredTools = tools.filter((tool) =>
    tool.name.toLowerCase().includes(searchQuery)
  );

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="" />
        <meta
          name="title"
          content="Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" coding validation tools, programming tools, code checker, code error finding tool, code beautify tool, code scanning tool"
        />
        <meta
          name="description"
          content=" Discover top coding validation tools to ensure error-free, high-quality code. Learn how tools like HTML Preview, Minyfiy to UnMinyfiy, Json Validator, Validate CSS, String Replace, Webhook Test can streamline your development workflow and enforce coding standards. "
        />
        <link rel="apple-touch-icon" href={logo} />
        <title>
          Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech
        </title>
      </Helmet>
      <div className="flex space-x-4 justify-center">
        <div className="my-5">
          <div className="TOOLS">TOOLS</div>
          <div className="Coding_Validation p-10">
            <h1 className="text-xl font-bold mb-4 text-[#333333] text-center">
              What is Coding Validation Tools?
            </h1>
            <p className="text-base text-[#515151] font-medium text-center">
              Coding validation tools are software applications that analyze and
              check code for errors, adherence to coding standards, and
              potential bugs, ensuring high-quality, maintainable, and
              error-free programming.
            </p>
          </div>
          <div className="flex justify-center mx-auto mt-10 lg:w-[52%]">
            <div className="relative lg:w-[100%]">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                className="w-64 px-4 lg:w-[100%] py-2 pr-10 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search..."
              />
              <svg
                className="absolute top-1/2 right-3 transform -translate-y-1/2 h-5 w-5 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-4.35-4.35M9.5 17a7.5 7.5 0 100-15 7.5 7.5 0 000 15z"
                ></path>
              </svg>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 coding_standards-outer grid-section mt-5 lg:w-[55%] mx-auto">
            {filteredTools.map((tool, index) => (
              <div key={index} className="coding_standards">
                <a href={tool.link}>
                  <div className="button-card flex items-center p-4 border border-gray-300 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-300">
                    <div className="w-1/3 button-card-image">
                      <img src={tool.img} alt={tool.name} className="w-full" />
                    </div>
                    <div className="w-2/3 pl-4 text-[#333333] text-lg font-medium">
                      {tool.name}
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
          <div className="Coding_Validation p-6">
            <div className="py-5">
              <h1 className="text-xl font-bold mb-4 text-[#333333] text-center">
                Top Coding Validation Beautify Tools for Efficient and
                Error-Free Programming
              </h1>
              <p className="text-base text-[#515151] font-medium">
                In the fast-paced world of software development, ensuring that
                your code is error-free and adheres to best practices is
                crucial. Coding validation tools or Beautify Tools are
                indispensable for developers looking to streamline their
                workflow, catch bugs early, and maintain high-quality code.
              </p>
              <p className="text-base text-[#515151] font-medium py-5">
                Choose the tools that best Code validation tool fit your
                programming language and project requirements to enhance your
                coding efficiency and reliability.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToolButtons;
