import React, { useState } from "react";
import { parseGIF, decompressFrames } from "gifuct-js";
import Helmet from "react-helmet";
import logo from "../../img/logo192.png";

const GifToPngConverter = () => {
  const [gifFile, setGifFile] = useState(null);
  const [pngUrl, setPngUrl] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "image/gif") {
      setGifFile(file);
    } else {
      alert("Please select a GIF file");
    }
  };

  const convertGifToPng = async () => {
    if (!gifFile) {
      return;
    }

    const arrayBuffer = await gifFile.arrayBuffer();
    const gif = parseGIF(arrayBuffer);
    const frames = decompressFrames(gif, true);
    const firstFrame = frames[0];

    const canvas = document.createElement("canvas");
    canvas.width = firstFrame.dims.width;
    canvas.height = firstFrame.dims.height;
    const ctx = canvas.getContext("2d");

    const imageData = ctx.createImageData(
      firstFrame.dims.width,
      firstFrame.dims.height
    );
    imageData.data.set(firstFrame.patch);
    ctx.putImageData(imageData, 0, 0);

    canvas.toBlob((blob) => {
      const pngUrl = URL.createObjectURL(blob);
      setPngUrl(pngUrl);
      setDownloadUrl(pngUrl);
    }, "image/png");
  };

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = "converted_image.png";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <Helmet>{/* Helmet content */}</Helmet>
      <div className="flex flex-col items-center p-4">
        <h1 className="text-2xl font-bold mb-4">GIF to PNG Converter</h1>
        <input
          type="file"
          accept="image/gif"
          onChange={handleFileChange}
          className="mb-4 p-2 border rounded"
        />
        <button
          onClick={convertGifToPng}
          disabled={!gifFile}
          className={`px-4 py-2 mb-4 rounded text-white ${
            gifFile ? "bg-blue-500 hover:bg-blue-700" : "bg-gray-400"
          }`}
        >
          Convert to PNG
        </button>
        {pngUrl && (
          <div className="mt-4">
            <h2 className="text-xl font-semibold mb-2">PNG Image:</h2>
            <img src={pngUrl} alt="Converted PNG" className="border rounded" />
            <button
              onClick={handleDownload}
              className="mt-2 px-4 py-2 rounded bg-green-500 hover:bg-green-700 text-white"
            >
              Download PNG
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default GifToPngConverter;
