import React, { useState } from "react";
import axios from "axios";
import beautify from "js-beautify";

const PhpEditor = () => {
  const [code, setCode] = useState("");
  const [beautifiedCode, setBeautifiedCode] = useState("");
  const [output, setOutput] = useState("");
  const [error, setError] = useState("");

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const beautifyCode = () => {
    try {
      const beautified = beautify.html(code, { indent_size: 2 });
      setBeautifiedCode(beautified);
    } catch (err) {
      setError("Error beautifying code: " + err.message);
    }
  };

  const clearCode = () => {
    setBeautifiedCode(""); // Only clear the beautified code
  };

  const runCode = async () => {
    setError("");
    setOutput("");
    try {
      const response = await axios.post(
        "http://localhost:5000/api/v1/run-php",
        {
          code: beautifiedCode,
        }
      );
      setOutput(response.data.output);
    } catch (err) {
      setError("Error running code: " + err.message);
    }
  };

  return (
    <div className="p-10">
      <h1 class="text-3xl text-center font-bold text-gray-600 bg-white  p-6 rounded-lg">
        PHP Run
      </h1>
      <div>
        <div className="p-4">
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 mb-4 pr-2">
              <textarea
                value={code}
                onChange={handleCodeChange}
                placeholder="Enter PHP code here"
                rows="10"
                className="w-full border border-gray-300 rounded-md p-2"
              />
            </div>
            <div className="w-full md:w-1/2 mb-4 pl-2 border rounded border-gray-300">
              {beautifiedCode && (
                <div>
                  <h3 className="mt-4">Beautified Code:</h3>
                  <pre>{beautifiedCode}</pre>
                </div>
              )}

              {output && (
                <div className="mt-4">
                  <h3>Output:</h3>
                  <pre>{output}</pre>
                </div>
              )}
              {error && (
                <div className="mt-4">
                  <h3>Error:</h3>
                  <pre>{error}</pre>
                </div>
              )}
            </div>
          </div>
          <button
            onClick={beautifyCode}
            className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2"
          >
            Beautify Code
          </button>
          <button
            onClick={runCode}
            className="bg-green-500 text-white px-4 py-2 rounded-md mr-2"
          >
            Run Code
          </button>
          <button
            onClick={clearCode}
            className="bg-gray-500 text-white px-4 py-2 rounded-md"
          >
            Clear Code
          </button>
        </div>
      </div>
    </div>
  );
};

export default PhpEditor;
