import React from "react";
import logo from "../img/company_logo/download.png";
// import Shipping_Policy from "../components/Shipping_Policy";
const Terms_Conditions = () => {
  // const [Cancellation_and_Refund, setCancellation_and_Refund] = useState(false);
  // const [Refund_Policy, setRefund_Policy] = useState(false);
  // const [Privacy_Policy, setPrivacy_Policy] = useState(false);
  // const [Shipping_Delivery, setShipping_Delivery] = useState(false);
  return (
    <>
      <div className="py-10">
        <div className="container m-auto">
          <div className="w-[75%] m-auto">
            <div className=" p-10 committed_to_ensuring">
              <div className="text-[#32325e]  ">
                <h1 className="font-bold text-[25px] p-3 ">
                  Terms & Conditions
                </h1>
                <h1 className="border-2	w-[30px]  border-[#213554] ml-3"></h1>
                <p className="font-bold p-3">Last updated on Oct 20th 2023</p>
                <div className="w-full">
                  <div>
                    <p className="p-3 text-[#757a91] text-[14px]">
                    Welcome to our website. By continuing to browse and use this website, you agree to comply with and be bound by the following terms and conditions of use, which, together with our privacy policy, govern AJIVA INFOTECH PRIVATE LIMITED's relationship with you in relation to this website.
                    </p>
                    <p className="p-3 text-[#757a91] text-[14px]">
                    The Website Owner, including subsidiaries and affiliates ("Website" or "Website Owner" or "we" or "us" or "our"), provides the information contained on the website or any of the pages comprising the website ("website") to visitors ("visitors") (collectively referred to as "you" or "your" hereinafter) subject to the terms and conditions set out in these website terms and conditions, the privacy policy, and any other relevant terms and conditions, policies, and notices which may be applicable to a specific section or module of the website.
                      <span className="text-[15px]">
                        {/* AJIVAINFOTECH PRIVATE LIMITED'' */}
                      </span>
                      {/* s relationship with you in relation to this website. */}
                    </p>
                    <p className="p-3 text-[14px] font-normal text-[#757a91]">
                      The term
                      <span className="text-[15px]">
                        AJIVAINFOTECH PRIVATE LIMITED
                      </span>
                      "us", or "we" refers to the owner of the website whose registered/operational office is located at Plot 309, Industrial Area Mohali, Sector 75, Phase 8A, Mohali, Punjab, 160055. The term "you" refers to the user or viewer of our website.
                    </p>
                  </div>
                  <div>
                    <h1 className="font-semibold p-3">
                      The use of this website is subject to the following terms
                      of use:
                    </h1>
                    <ul className="pl-5 text-[#757a91]">
                      <ol className="p-3">
                        The content of the pages of this website is for your
                        general information and use only. It is subject to
                        change without notice.
                      </ol>
                      <li className="p-3">
                        Neither we nor any third parties provide any warranty or
                        guarantee as to the accuracy, timeliness, performance,
                        completeness or suitability of the information and
                        materials found or offered on this website for any
                        particular purpose. You acknowledge that such
                        information and materials may contain inaccuracies or
                        errors and we expressly exclude liability for any such
                        inaccuracies or errors to the fullest extent permitted
                        by law.
                      </li>
                      <li className="p-3">
                        Your use of any information or materials on this website
                        is entirely at your own risk, for which we shall not be
                        liable. It shall be your own responsibility to ensure
                        that any products, services or information available
                        through this website meet your specific requirements.
                      </li>
                      <li className="p-3">
                        This website contains material which is owned by or
                        licensed to us. This material includes, but is not
                        limited to, the design, layout, look, appearance and
                        graphics. Reproduction is prohibited other than in
                        accordance with the copyright notice, which forms part
                        of these terms and conditions
                      </li>
                      <li className="p-3">
                        All trademarks reproduced in this website which are not
                        the property of, or licensed to, the operator are
                        acknowledged on the website.
                      </li>
                      <li className="p-3">
                        Unauthorized use of this website may give rise to a
                        claim for damages and/or be a criminal offense.
                      </li>
                      <li className="p-3">
                        From time to time this website may also include links to
                        other websites. These links are provided for your
                        convenience to provide further information.
                      </li>
                      <li className="p-3">
                        You may not create a link to this website from another
                        website or document without AJIVAINFOTECH PRIVATE
                        LIMITED’s prior written consent.
                      </li>
                      <li className="p-3">
                        Your use of this website and any dispute arising out of
                        such use of the website is subject to the laws of India
                        or other regulatory authority.
                      </li>
                    </ul>
                  </div>
                  <p className="py-5 text-[#757a91]  p-3">
                    We as a merchant shall be under no liability whatsoever in
                    respect of any loss or damage arising directly or indirectly
                    out of the decline of authorization for any Transaction, on
                    Account of the Cardholder having exceeded the preset limit
                    mutually agreed by us with our acquiring bank from time to
                    time
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Terms_Conditions;
