import React, { useEffect, useState } from "react";
import contact_bg from "../img/background_img/Contact-Us.webp";
import help from "../img/Need-help.webp";
import { NavLink } from "react-router-dom";
import Aservices from "./Aservices";
import res from "../img/bn3256.png";
import Helmet from "react-helmet";
import logo from "../img/logo192.png";
import Expertisecommerce from "../../src/aservecse_img/001.png";
import commerce_Development from "../../src/aservecse_img/002.png";
import commerce_Website_Design from "../../src/aservecse_img/003.png";
import Platform_Integration from "../../src/aservecse_img/004.png";
import Ecommerce_image_ecc from "../../src/aservecse_img/About_Ajiva_Eccomerce.png";
import Ecommerce_image from "../../src/aservecse_img/ecom_pg_lfimg_1.png";
import Ecommerce_image_2 from "../../src/aservecse_img/ecom_pg_lfimg_2.png";
import Ecommerce_image_3 from "../../src/aservecse_img/Eccomerce_3.png";
import Ecommerce_image_4 from "../../src/aservecse_img/Eccomerce_4.png";
import Eccomerce_SCO from "../../src/aservecse_img/Eccomerce_SCO.png";
import new_design from "../../src/aservecse_img/New_design.png";

const EcommerceService = () => {
  <script>
    let hrs = document.querySelector("#hrs"); let sec =
    document.querySelector("#sec"); let min = document.querySelector("#min");
  </script>;

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="title"
          content="Affordable Custom E-Commerce Website Development Services | Professional E-Commerce Developers"
        />
        <title>
          Expert E-commerce Development Solutions for Your Online Success |
          Ajiva Infotech
        </title>

        <meta name="robots" content="index, follow"></meta>
        <meta content="Discover AjivaInfotech, a leading technology partner offering end-to-end software development and testing services. Transform  digital presence today."></meta>
        <meta
          name="description"
          content=" Boost your online sales with our affordable and custom E-Commerce website development services. Professional E-Commerce developers specialize in cost-effective, high-quality E-Commerce websites using the latest platforms and technologies "
        />
        <meta
          name="keywords"
          content="E-Commerce website design and development, Custom E-Commerce website development, Professional E-Commerce developers, Affordable E-Commerce website development, Cost-effective E-Commerce website development"
        />
        <link rel="apple-touch-icon" href={logo} />
      </Helmet>
      <div className="cocontainer m-auton">
        <section>
          <div className="flex justify-center">
            <img src={contact_bg} alt="contact_bg" />
          </div>
          <div className="container m-auto ">
            <div className="">
              <div className="opp">
                <div className="bang">
                  <h1 className="">
                    <h3 className="text-red-700	 ">E-commerce Development</h3>
                  </h1>
                </div>
                <div className="Api_int">
                  <NavLink to="/" className="text-base text-red-600	pr-2">
                    Home
                  </NavLink>
                  <span className="text-sm">/</span>
                  <h1 className="pl-2 text-[18px] lg:text-2xl font-bold text-[#333333]">
                    E-commerce Development{" "}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* =================new card================================================= */}

        {/* ===================section ksjdgksvjdvd====================================================== */}

        <div className="ecomerece_border_frist_main">
          <div className="container m-auto">
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image ">
                  <img src={Ecommerce_image} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Transform Your Online Business with Our E-Commerce Website
                  Development Services
                </h1>
                <p className="">
                  Are you looking to take your online business to the next
                  level? At <span>AJIVA INFOTECH</span>, Our expert team
                  specializes in E-Commerce website design and development,
                  providing custom solutions that cater to your unique business
                  needs. With a focus on affordability and quality, we offer
                  professional E-Commerce development services to help you
                  succeed in the digital marketplace.
                </p>
              </div>
            </div>
            <div className="Integration-Needs">
              <h1>Our E-Commerce Website Development Services</h1>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer sectio-positon-change bg-[]">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Custom E-Commerce Website Development
                </h1>
                <p className="">
                  Best solutions to meet your specific business requirements.
                  Our custom E-Commerce websites are designed to provide
                  seamless user experiences and drive sales.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_2} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Professional E-Commerce Developers
                </h1>
                <p className="">
                  Our team comprises skilled and experienced developers who are
                  proficient in the latest E-Commerce platforms and
                  technologies. We ensure your website is robust, scalable, and
                  secure.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]   sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Affordable E-Commerce Website Development
                </h1>
                <p className="">
                  High-quality E-Commerce solutions that fit your budget. We
                  believe that great online stores shouldn’t come with a hefty
                  price tag.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_4} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]  ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Cost-effective E-Commerce Website Development
                </h1>
                <p className="">
                  Maximize your ROI with our cost-effective development
                  services. We deliver exceptional value without compromising on
                  quality.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  E-Commerce Platforms and Technologies We Use
                </h1>
                <p className="">
                  We work with a variety of E-Commerce platforms and
                  technologies to ensure we can offer the best solution for your
                  business. Our expertise includes but is not limited to:
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2   ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Eccomerce_SCO} />
                </div>
              </div>
              <div className="lg:w-1/2   Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Shopify
                </h1>
                <p className="">
                  Known for its ease of use and robust features, Shopify is a
                  popular choice for businesses of all sizes.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  WooCommerce
                </h1>
                <p className="">
                  A flexible, open-source E-Commerce plugin for WordPress,
                  perfect for businesses looking for customization.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Magento
                </h1>
                <p className="">
                  A powerful platform for businesses looking to scale, offering
                  extensive features and customization options.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  WooCommerce
                </h1>
                <p className="">
                  A flexible, open-source E-Commerce plugin for WordPress,
                  perfect for businesses looking for customization.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  BigCommerce
                </h1>
                <p className="">
                  Ideal for businesses that need advanced SEO and multi-channel
                  selling capabilities.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  PrestaShop
                </h1>
                <p className="">
                  An open-source platform that offers flexibility and a wide
                  range of features to support your growing business.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>

            <div className="Integration-Needs">
              <h1>Our Development Process</h1>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Discovery and Planning
                </h1>
                <p className="">
                  We begin with a thorough understanding of your business needs
                  and goals. This phase involves market research, competitor
                  analysis, and defining project requirements.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Design and Prototyping
                </h1>
                <p className="">
                  Our designers create intuitive and visually appealing
                  mock-up’s that reflect your brand identity. We focus on user
                  experience to ensure your customers have a smooth shopping
                  journey.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Development
                </h1>
                <p className="">
                  Using the latest technologies and best practices, our
                  developers bring your website to life. We build responsive,
                  high-performance E-Commerce websites that are optimized for
                  all devices.
                </p>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Testing and Quality Assurance
                </h1>
                <p className="">
                  Rigorous testing is conducted to ensure your website is
                  bug-free and performs flawlessly. We test for functionality,
                  compatibility, security, and performance.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Launch and Deployment
                </h1>
                <p className="">
                  After successful testing, we launch your website. Our team
                  handles all aspects of deployment to ensure a smooth
                  transition.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Maintenance and Support
                </h1>
                <p className="">
                  Post-launch, we provide ongoing support and maintenance to
                  keep your website up-to-date and running smoothly. We offer
                  regular updates, security checks, and performance
                  optimization.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="Integration-Needs">
              <h1>Latest Trends in E-Commerce Development</h1>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Mobile Commerce
                </h1>
                <p className="">
                  With a significant increase in mobile shopping, we prioritize
                  mobile-first design to enhance the user experience on all
                  devices.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  AI and Personalization
                </h1>
                <p className="">
                  Implementing AI-driven features for personalized shopping
                  experiences, such as product recommendations and customer
                  support chatbots.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Voice Commerce
                </h1>
                <p className="">
                  Integrating voice search capabilities to cater to the growing
                  number of voice-activated searches.
                </p>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Social Commerce
                </h1>
                <p className="">
                  Leveraging social media platforms for direct selling and
                  improving customer engagement.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Augmented Reality (AR)
                </h1>
                <p className="">
                  Enhancing product visualization with AR, allowing customers to
                  experience products virtually before purchasing.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Get Started Today
                </h1>
                <p className="">
                  Ready to elevate your online business with a custom E-Commerce
                  website? Contact us today to discuss your project
                  requirements. Our team of professional E-Commerce developers
                  is here to provide you with cost-effective, high-quality
                  solutions that drive results.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Aservices />
      </div>
    </>
  );
};
export default EcommerceService;
