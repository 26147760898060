import React, { useState } from "react";
import Helmet from "react-helmet";
import logo from "../../img/logo192.png";
const RGBToHexConverter = () => {
  const [r, setR] = useState("");
  const [g, setG] = useState("");
  const [b, setB] = useState("");
  const [hex, setHex] = useState("");

  const rgbToHex = (r, g, b) => {
    const toHex = (c) => {
      const hex = c.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  const handleConvert = () => {
    const red = parseInt(r, 10);
    const green = parseInt(g, 10);
    const blue = parseInt(b, 10);

    if (
      isNaN(red) ||
      red < 0 ||
      red > 255 ||
      isNaN(green) ||
      green < 0 ||
      green > 255 ||
      isNaN(blue) ||
      blue < 0 ||
      blue > 255
    ) {
      alert("Please enter valid RGB values (0-255).");
      return;
    }

    const hexColor = rgbToHex(red, green, blue);
    setHex(hexColor);
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="" />
        <meta
          name="title"
          content="Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" coding validation tools, programming tools, code checker, code error finding tool, code beautify tool, code scanning tool"
        />
        <meta
          name="description"
          content=" Discover top coding validation tools to ensure error-free, high-quality code. Learn how tools like HTML Preview, Minyfiy to UnMinyfiy, Json Validator, Validate CSS, String Replace, Webhook Test can streamline your development workflow and enforce coding standards. "
        />
        {/* <link rel="apple-touch-icon" href={logo} /> */}
        <title>
          Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech
        </title>
      </Helmet>
      <div className="flex flex-col items-center p-8 bg-gray-100 min-h-screen">
        <h2 className="text-2xl font-semibold mb-4">Convert RGB to Hex</h2>
        <div className="flex space-x-4 mb-4">
          <div className="flex flex-col items-center">
            <label className="mb-2">R:</label>
            <input
              type="text"
              value={r}
              onChange={(e) => setR(e.target.value)}
              className="p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="flex flex-col items-center">
            <label className="mb-2">G:</label>
            <input
              type="text"
              value={g}
              onChange={(e) => setG(e.target.value)}
              className="p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="flex flex-col items-center">
            <label className="mb-2">B:</label>
            <input
              type="text"
              value={b}
              onChange={(e) => setB(e.target.value)}
              className="p-2 border border-gray-300 rounded"
            />
          </div>
        </div>
        <button
          onClick={handleConvert}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Convert
        </button>
        {hex && (
          <div className="mt-8 flex flex-col items-center">
            <strong className="text-lg">Hex Color:</strong>
            <span className="text-lg">{hex}</span>
            <div
              className="w-24 h-24 mt-4 border border-gray-500"
              style={{ backgroundColor: hex }}
            ></div>
          </div>
        )}
      </div>
    </>
  );
};

export default RGBToHexConverter;
