import React, { useEffect, useState } from "react";
import contact_bg from "../img/background_img/Contact-Us.webp";
import { NavLink } from "react-router-dom";
import Aservices from "./Aservices";
import help from "../img/Need-help.webp";

const View_More_Services = () => {
  const [percentage, setPercentage] = useState(0);
  const [percentage1, setPercentage1] = useState(0);
  const [percentage2, setPercentage2] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      if (percentage < 92) {
        setPercentage(percentage + 1);
      }
      if (percentage1 < 286) {
        setPercentage1(percentage1 + 1);
      }
      if (percentage2 < 672) {
        setPercentage2(percentage2 + 1);
      }
    }, 50);
  }, [percentage, percentage1, percentage2]);

  return (
    <div className="cocontainer m-auton">
      <section>
        <div className="">
          <img src={contact_bg} />
        </div>
        <div className="container m-auto ">
          <div className="">
            <div className="opp">
              <div className="bang">
                <h1 className="">
                  view_
                  <span className="text-red-700	 ">our services</span>
                </h1>
              </div>
              <div className=" text-center text-3xl  font-semibold	">
                <NavLink to="/" className="text-blue-600	text-xl pr-2">
                  Home
                </NavLink>
                <span className="text-sm">/</span>
                <span className="pl-2">services</span>
              </div>
              <div className="w-full">
                <div className="store mx10">
                  <p className=" online ">
                    Are you looking for an e-commerce development company in
                    Bangalore? You’ve come to the right place. Setting up an
                    online store for your business is possible with an
                    e-commerce platform.
                  </p>
                </div>
              </div>
              <div className="px-20">
                <p className=" goal pl-8 ml-19">
                  The goal of developing an e-commerce application is to get
                  customers excited and remove unnecessary friction. The company
                  aims to provide high-quality virtual customer service, a
                  seamless online experience, and a fantastic digital product.
                  Whether you are building your business online or optimizing
                  your existing e-commerce site, we will develop a website that
                  converts on time.
                </p>
              </div>
              <div className=" imgs  px-20 ">
                <div className="pt-8 mr-15 hhh">
                  <p>
                    Increasing internet usage has dramatically changed
                    traditional marketing and shopping around the world.
                    E-commerce has expanded to a wider range of products. Over
                    90% of people own smartphones, which makes it easy to buy
                    anything. E-commerce web designand e-commerce website
                    development are the two most important factors for any
                    website to succeed in the internet market today.<br></br>
                    <br></br>
                  </p>
                  <p>
                    The development of an e-commerce website brings in potential
                    customers for whom certain features need to be remembeblack,
                    which saves time, money,and user-centricity.
                  </p>
                  <br></br>
                  <p>
                    Get the best quote from us if you are looking for a Top
                    Ecommerce Website Development Company in Bangalore!
                  </p>
                </div>
                <div className="mt-10 ">
                  <img src="http://localhost:3000/static/media/A_Img.dae818bbfa5ea4f81b6e.webp" />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="frist ">
            <div className="card ">
              <div className="mx-15 cd shedow">
                <div className="p-3 cop">
                  <h2 className="  py-4 web ">Web Design</h2>
                  <p className="text-base text-gray-800 pb-3">
                    We create unique hand-crafted website design solutions that
                    build your online presence to its full potential. We like to
                    be as creative and innovative as our clients allow us to be,
                    designing.
                  </p>
                </div>
              </div>
              <div className="lg:w-1/3 md:w-1/3 mx-15 pt-10 FIND_OUT ">
                <div className="p-3  ">
                  <h2 className="text-xl font-bold  ">FIND OUT MORE</h2>
                  <p className="text-base text-gray-800 pb-3">
                    To learn more about our web design services and how we can
                    help your business, get in touch.
                  </p>
                </div>
              </div>
              <div className="Hello">
                <button
                  className="click"
                  type="button"
                  onclick="alert('Hello world!')"
                >
                  Get to Touch
                </button>
              </div>
            </div>
            <div className="w ">
              <div className="lg:w-1/3 md:w-1/3 mx-15 cd ">
                <div className="p-3">
                  <h2 className="text-xl font-bold py-5">
                    Ecommerce Development
                  </h2>
                  <p className="text-base text-gray-800 pb-3">
                    To learn more about our web design services and how we can
                    help your business, get in touch.
                  </p>
                  <div className="flex">
                    <a
                      href="/services/ecommerce-development"
                      className="p-1 text-red-700"
                    >
                      Read more
                    </a>
                    <div>
                      <a className="text-black-600 text-1xl font-semibold " />

                      <i class="fa-solid fa-arrow-right-long text-2xl  atag" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/3 md:w-1/3 mx-15 cd ">
                <div className="p-3">
                  <h2 className="text-xl font-bold py-5">Web Development</h2>
                  <p className="text-base text-gray-800 pb-3">
                    To learn more about our web design services and how we can
                    help your business, get in touch.
                  </p>
                  <div className="flex">
                    <a
                      href="/services/web_development"
                      className="p-1 text-red-700"
                    >
                      Read more
                    </a>
                    <div>
                      <a
                        href="/services/web_development"
                        className="text-black-600 text-1xl font-semibold "
                      />

                      <i class="fa-solid fa-arrow-right-long text-2xl  atag" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/3 md:w-1/3 mx-15 cd ">
                <div className="p-3 ">
                  <h2 className="text-xl font-bold py-5">
                    Web Design &Graphic Design
                  </h2>
                  <p className="text-base text-gray-800 pb-3">
                    To learn more about our web design services and how we can
                    help your business, get in touch.
                  </p>
                  <div className="flex">
                    <a
                      href="/services/web_&_graphic design"
                      className="p-1 text-red-700	"
                    >
                      Read more
                    </a>
                    <div>
                      <a className="text-Red-600 text-1xl font-semibold " />

                      <i class="fa-solid fa-arrow-right-long text-2xl atag" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w ">
              <div className="lg:w-1/3 md:w-1/3 mx-15 cd ">
                <div className="p-3">
                  <h2 className="text-xl font-bold py-5">API Interation </h2>
                  <p className="text-base text-gray-800 pb-3">
                    To learn more about our web design services and how we can
                    help your business, get in touch.
                  </p>
                  <div className="flex">
                    <a
                      href="/services/api-integration"
                      className="p-1 text-red-700 "
                    >
                      Read more
                    </a>
                    <div>
                      <a
                        href="/"
                        className="text-black-600 text-1xl font-semibold "
                      >
                        <i class="fa-solid fa-arrow-right-long text-2xl  atag" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/3 md:w-1/3 mx-15 cd ">
                <div className="p-3">
                  <h2 className="text-xl font-bold py-5">
                    Mobile App Development
                  </h2>
                  <p className="text-base text-gray-800 pb-3">
                    To learn more about our web design services and how we can
                    help your business, get in touch.
                  </p>
                  <div className="flex">
                    <a
                      href="/services/mobile_app_development"
                      className="p-1 text-red-600"
                    >
                      Read more
                    </a>
                    <div>
                      <a className="text-black-600 text-1xl font-semibold " />

                      <i class="fa-solid fa-arrow-right-long text-2xl  atag" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/3 md:w-1/3 mx-15 cd ">
                <div className="p-3 ">
                  <h2 className="text-xl font-bold py-5">CRM Development</h2>
                  <p className="text-base text-gray-800 pb-3">
                    To learn more about our web design services and how we can
                    help your business, get in touch.
                  </p>
                  <div className="flex">
                    <a className="p-1">Read more</a>
                    <div>
                      <a className="text-black-600 text-1xl font-semibold " />

                      <i class="fa-solid fa-arrow-right-long text-2xl  atag" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <Aservices />
      {/* <section>
        <div className="containor m-auto">
          <div className="px-20 mx-20">
            <div>
              <div className="Phases">
                <h2>Phases Of The Website Design and Development Process</h2>
              </div>
              <div className="pt-5">
                <p className="this">
                  We produce visually stunning and engaging websites that
                  communicate the values of our clients. Online sales<br></br>{" "}
                  and lead generation are two of the ways these websites assist
                  their businesses in growing.
                </p>
              </div>
            </div>
            <div className="fee">
              <div className=" flex pl-10 fees">
                <div className="  pl-10 iconf">
                  <div className="cricle">
                    <i
                      class="fa fa-camera-retro text-3xl"
                      fa-solid
                      fa-object-group="true"
                    ></i>
                  </div>
                  <div className="d2">
                    <span>Requirement Gathering</span>
                  </div>
                </div>
                <div className="  pl-10 iconf">
                  <div className="cricle">
                    <i
                      class="fa fa-camera-retro text-3xl"
                      fa-solid
                      fa-object-group="true"
                    ></i>
                  </div>
                  <div className="d2">
                    <span> planing process</span>
                  </div>
                </div>
                <div className="  pl-10 iconf">
                  <div className="cricle">
                    <i
                      class="fa fa-camera-retro text-3xl"
                      fa-solid
                      fa-object-group="true"
                    ></i>
                  </div>
                  <div className="d2">
                    <span>Design process </span>
                  </div>
                </div>
                <div className="  pl-10 iconf">
                  <div className=" cricles">
                    <i
                      class="fa fa-camera-retro text-3xl"
                      fa-solid
                      fa-object-group="true"
                    ></i>
                  </div>
                  <div className="d2">
                    <span>Development update </span>
                  </div>
                </div>
                <div className="  pl-10 iconf">
                  <div className="criclePHP">
                    <i
                      class="fa fa-camera-retro text-3xl"
                      fa-solid
                      fa-object-group="true"
                    ></i>
                  </div>
                  <div className="d2">
                    <span className="pr-10">PHP_&_Laravel </span>
                  </div>
                </div>
                <div className="  pl-10 iconf">
                  <div className="cricle8">
                    <i
                      class="fa fa-camera-retro text-3xl"
                      fa-solid
                      fa-object-group="true"
                    ></i>
                  </div>
                  <div className="d2">
                    <span>Launch </span>
                  </div>
                </div>
                <div className="  pl-10 iconf">
                  <div className="cricleMaintenance">
                    <i
                      class="fa fa-camera-retro text-3xl"
                      fa-solid
                      fa-object-group="true"
                    ></i>
                  </div>{" "}
                  <div className="d2">
                    <span>Maintenance </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-red-600	">
              <div>
                <div className=" process  px-10 mx-10 mt-10 tt">
                  <div className="text-2xl font-semibold pl-5 ">
                    <h1 className="my-7 REQ">Requirement Gathering</h1>
                  </div>
                  <div className="pl-5 ">
                    <p className="element">
                      The process of gathering requirements goes beyond asking a
                      few questions and moving on. All projects should follow
                      some steps to gather requirements. Working through the
                      process takes time, whether it is a small or substantial
                      project. Make sure you ask the right questions and listen
                      carefully to the answers. The particular requirement can
                      be collected using a variety of techniques. Interviews,
                      facilitated sessions, prototypes, and questionnaires are
                      all part of the process.
                      <br></br>
                      <br></br>
                      Our next step is to validate the information collected and
                      confirm that it reflects the client’s ideology. Creating
                      models that visualize the process involves consolidating
                      requirements. The development life cycle is tracked
                      through documents and reports. Provide all the required
                      information to the client for approval after gathering all
                      the required information.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};
export default View_More_Services;
