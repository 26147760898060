import React, { useEffect, useState } from "react";

import study_case_bg from "../img/study-case.jpg";
import blog_img from "../img/services_img.webp";
import hagg_one from "../img/hagg_one.jpg";
import hagg_two from "../img/hagg_two.jpg";
import hagg_three from "../img/hagg_three.jpg";
import hagg_four from "../img/hagg_four.jpg";
import woman from "../img/jasica.png";
import arre from "../img/five_arre.png";
import netwok from "../img/networking.png";
import netwok_one from "../img/classice.jpg";
import usercase_one from "../img/usercase_one.png";
import usercase_two from "../img/usercase_two.png";
import usercase_three from "../img/usercase_three.png";
import usercase_four from "../img/usercase_four.png";
import usercase_five from "../img/usercase_five.png";
import usercase_six from "../img/usercase_six.png";
import userarrow_top from "../img/userarrow_top.png";
import userarrow_bottam from "../img/userarrow_bottam.png";
import shape_one from "../img/shape_one.png";
import ssss from "../img/skk_one.png";
const CaseStudies = () => {
  useEffect(() => {
    setkk(true);
  }, []);
  const [kk, setkk] = useState(false);
  const [kk1, setkk1] = useState(false);
  const [kk2, setkk2] = useState(false);
  const [kk3, setkk3] = useState(false);
  const [kk4, setkk4] = useState(false);
  const [kk5, setkk5] = useState(false);
  const [kk6, setkk6] = useState(false);
  const [Testing, setTesting] = useState(false);
  useEffect(() => {
    setkk(true);
  }, []);

  return (
    <>
      <section className="">
        <div className="">
          <img src={study_case_bg} alt="About_bg" />
        </div>
      </section>
      <section className="flex justify-end"><div className="text-center text-[40px] font-[700] text-[#3a3a3b] font_setting my-10 lg:w-[81%] ">HAGGLERPLANET</div></section>
      <section>
        <div className="container m-auto">
          <div className="w-full lg:flex">
            <div className="lg:w-[18%]	">
              <div className="my-4  h-max overflow-auto">
                {/* ------------------------------------------old------------------------------------------------------------------ */}
                <ul className="py-4">
                  <li
                    className={
                      kk
                        ? "active  px-4 font-semibold border-r-2 border-red-600 py-4   text-white bg-red-600"
                        : "text-[] mx-4 py-4 border-b border-dotted text-lg cursor-pointer "
                    }
                    onClick={(e) => {
                      setkk(true);
                      setkk1(false);
                      setkk2(false);
                      setkk3(false);
                      setkk4(false);
                      setkk5(false);
                      setkk6(false);
                       setTesting(false)
                    }}
                  >
                    {/* <i class="fa-solid fa-angle-right mx-2 "></i> */}
                    <span className="pl-5">About</span>
                  </li>
                  <li
                    className={
                      kk1
                        ? "active  px-4 font-semibold border-r-2 border-red-600 py-4   text-white bg-red-600"
                        : "text-gray-600 mx-4 py-4 border-b border-dotted text-lg cursor-pointer "
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(true);
                      setkk2(false);
                      setkk3(false);
                      setkk4(false);
                      setkk5(false);
                      setkk6(false);
                       setTesting(false)
                    }}
                  >
                    {/* <i class="fa-solid fa-angle-right mx-2 "></i> */}
                    <span className="pl-5">Feedback </span>
                  </li>
                  <li
                    className={
                      kk2
                        ? "active  px-4 font-semibold border-r-2 border-red-600 py-4   text-white bg-red-600"
                        : "text-gray-600 mx-4 py-4 border-b border-dotted text-lg cursor-pointer "
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(false);
                      setkk2(true);
                      setkk3(false);
                      setkk4(false);
                      setkk5(false);
                      setkk6(false);
                       setTesting(false)
                    }}
                  >
                    {/* <i class="fa-solid fa-angle-right mx-2 "></i> */}
                    <span className="pl-5">Server</span>
                  </li>
                  <li
                    className={
                      kk3
                        ? "active  px-4 font-semibold border-r-2 border-red-600 py-4   text-white bg-red-600"
                        : "text-gray-600 mx-4 py-4 border-b border-dotted text-lg cursor-pointer "
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(false);
                      setkk2(false);
                      setkk3(true);
                      setkk4(false);
                      setkk5(false);
                      setkk6(false);
                       setTesting(false)
                    }}
                  >
                    {/* <i class="fa-solid fa-angle-right mx-2 "></i> */}
                    <span className="pl-5">Methodology</span>
                  </li>
                  <li
                    className={
                      kk4
                        ? "active  px-4 font-semibold border-r-2 border-red-600 py-4   text-white bg-red-600"
                        : "text-gray-600 mx-4 py-4 border-b border-dotted text-lg cursor-pointer "
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(false);
                      setkk2(false);
                      setkk3(false);
                      setkk4(true);
                      setkk5(false);
                      setkk6(false);
                       setTesting(false)
                    }}
                  >
                    {/* <i class="fa-solid fa-angle-right mx-2"></i> */}
                    <span className="pl-5">Challenges </span>
                  </li>
                  <li
                    className={
                      kk5
                        ? "active  px-4 font-semibold border-r-2 border-red-600 py-4   text-white bg-red-600"
                        : "text-gray-600 mx-4 py-4 border-b border-dotted text-lg cursor-pointer "
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(false);
                      setkk2(false);
                      setkk3(false);
                      setkk4(false);
                      setkk5(true);
                      setkk6(false);
                       setTesting(false)
                    }}
                  >
                    {/* <i class="fa-solid fa-angle-right mx-2"></i> */}
                    <span className="pl-5">Risk_management </span>
                  </li>
                  <li
                    className={
                      kk6
                        ? "active  px-4 font-semibold border-r-2 border-red-600 py-4   text-white bg-red-600"
                        : "text-gray-600 mx-4 py-4 border-b border-dotted text-lg cursor-pointer "
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(false);
                      setkk2(false);
                      setkk3(false);
                      setkk4(false);
                      setkk5(false);
                      setkk6(true);
                      setTesting(false);    
                    }}
                  >
                    {/* <i class="fa-solid fa-angle-right mx-2"></i> */}
                    <span className="pl-5">User_Case</span>
                  </li>
                  <li
                    className={
                      setTesting
                        
                        ? "active  px-4 font-semibold border-r-2 border-red-600 py-4   text-white bg-red-600"
                        : "text-gray-600 mx-4 py-4 border-b border-dotted text-lg cursor-pointer "
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(false);
                      setkk2(false);
                      setkk3(false);
                      setkk4(false);
                      setkk5(false);
                      setkk6(false);
                      setTesting(true)
                    }}
                  >
                    {/* <i class="fa-solid fa-angle-right mx-2"></i> */}
                    <span className="pl-5">Testing</span>
                  </li>
                  <option></option>
                </ul>
              </div>
            </div>
            <div className="lg:w-[81%] ">

              <div className="">
                <div className="">
                  {kk == true ? (
                    <>
                      <div className=" border-t-2 border-[#cccccc] border-dotted w-full lg:flex 	lg:pt-[50px] lg:pb-[20px]">
                        <div className="lg:w-1/2">
                          <div className="border-b-2 p-8 border-dotted border-gray-500 	mb-4 mx-4">
                            <h2 className="text-[20px] font-[700] text-[#737373]">Logo :</h2>
                            <h2 className="text-[20px] font-[700] text-[#737373]">
                              Url :
                              <span className="text-red-600 text-[18px] font-[500] ml-2">
                                https://hagglerplanet.com/
                              </span>
                            </h2>
                          </div>
                          <div className="mb-4 mx-4 ">
                            <h2 className="text-[25px] text-[#787878] font-[700] mt-14 lg:pt-[15px]">About</h2>
                            <p className="text-justify text-[18px] mt-5 text-[#737373]">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised.
                            </p>
                            <p className="text-justify text-[18px] text-[#737373]	">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book.
                            </p>
                          </div>
                        </div>
                        <div className="lg:w-1/2">
                          <div className="lg:flex">
                            <img className="w-[44%]" src={hagg_one} />
                            <img className="w-[56%] " src={hagg_two} />
                          </div>
                          <div className="">
                            <img className="w-full" src={hagg_three} />
                            <img className="w-full my-4" src={hagg_four} />
                          </div>
                        </div>
                      </div></>
                  ) : (
                    ""
                  )}
                </div>
                <div className="">
                  {kk1 == true ? (
                    <>
                      <div className=" border-t-2 border-[#cccccc] border-dotted my-10">
                        <h2 className="text-[25px] text-[#787878] font-[700] my-5">Feedback</h2>
                        <div className="bg-red-600 lg:p-10 p-4">
                          <div className="bg-white lg:flex items-center rounded-lg border-2 border-red-600 border-dotted">
                            <img className="p-4" src={woman} />
                            <p className="px-4 text-[18px]  text-[#737373]">
                              Contrary to popular belief, Lorem Ipsum is not
                              simply random text. It has roots in a piece of
                              classical Latin literature from 45 BC, making it
                              over 2000 years old.
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="">
                  {kk2 == true ? (
                    <>
                      <div className="my-6 border-t-2 border-[#cccccc] border-dotted my-20">
                        <h2 className="text-[25px] text-[#787878] font-[700] my-5">Technology</h2>
                        <div className=" border p-10 drop-shadow-lg">
                          <div className="items-center rounded-lg ">
                            <p className="px-4 mb-6 text-[18px]  text-[#737373]">
                              Contrary to popular belief, Lorem Ipsum is not
                              simply random text. It has roots in a piece of
                              classical Latin literature from 45 BC, making it
                              over 2000 years old.
                            </p>
                            <span className="px-4  text-red-600">
                              CS5, XHTML, CSS, Javascript, Flash
                            </span>
                            <img className="mt-6" src={arre} />
                          </div>
                        </div>
                      </div></>
                  ) : (
                    ""
                  )}
                </div>

                <div className="">
                  {kk3 == true ? (
                    <>
                      <div className="my-6 border-t-2 border-[#cccccc] border-dotted my-20">
                        <h2 className="text-[25px] text-[#787878] font-[700] my-5 ">Feedback</h2>
                        <div className="bg-[#757575] p-10">
                          <div className="bg-white lg:flex items-center rounded-lg border-2 border-[#696969] border-dotted">
                            <p className="px-4 text-[18px]  text-[#737373]">
                              Awesome Services, these guys has made my website
                              running beyond my expectation. I would like to
                              recommend Ajivainfotech to everyone as i found
                              more professional in their work.
                            </p>
                            <img className="p-4" src={netwok} />
                          </div>
                        </div>
                      </div></>
                  ) : (
                    ""
                  )}
                </div>
                <div className="">
                  {kk4 == true ? (
                    <>
                      <div className="my-6 border-t-2 border-[#cccccc] border-dotted my-20">
                        <h2 className="text-[25px] text-[#787878] font-[700] my-5">Methodology</h2>
                        <div className=" border drop-shadow-lg">
                          <div className="items-center rounded-lg w-full lg:flex">
                            <div className="lg:w-2/5 py-4 px-4">
                              <p className="text-[18px]  text-[#737373]">
                                Contrary to popular belief, Lorem Ipsum is not
                                simply random text. It has roots in a piece of
                                classical Latin literature from 45 BC, making it
                                over 2000 years old.
                              </p>
                              <ul>
                                <li className="flex items-center my-4">
                                  <span className="flex justify-center items-center text-xl bg-[#ff0000] text-white px-4  py-2 rounded-full">
                                    1
                                  </span>
                                  <div className="mx-2">
                                    <h2 className="text-xl p-0">Design</h2>
                                    <p className="text-base p-0">
                                      Design Docoment & Protype
                                    </p>
                                  </div>
                                </li>
                                <li className="flex items-center my-4">
                                  <span className="flex justify-center items-center text-xl bg-[#ff0000] text-white px-4  py-2 rounded-full">
                                    2
                                  </span>
                                  <div className="mx-2">
                                    <h2 className="text-xl p-0">Development</h2>
                                    <p className="text-base p-0">
                                      Iterations, Demo & Feedback
                                    </p>
                                  </div>
                                </li>
                                <li className="flex items-center my-4">
                                  <span className="flex justify-center items-center text-xl bg-[#ff0000] text-white px-4  py-2 rounded-full">
                                    3
                                  </span>
                                  <div className="mx-2">
                                    <h2 className="text-xl p-0">
                                      Quality Assurance
                                    </h2>
                                    <p className="text-base p-0">
                                      Identify defects & Resolve bugs
                                    </p>
                                  </div>
                                </li>
                                <li className="flex items-center my-4">
                                  <span className="flex justify-center items-center text-xl bg-[#ff0000] text-white px-4  py-2 rounded-full">
                                    4
                                  </span>
                                  <div className="mx-2">
                                    <h2 className="text-xl p-0">Deployment</h2>
                                    <p className="text-base p-0">
                                      Production & Technical Support
                                    </p>
                                  </div>
                                </li>
                                <li className="flex items-center my-4">
                                  <span className="text-xl bg-[#b5b5b5] text-white px-5  py-5  rounded-full"></span>
                                  <div className="mx-2">
                                    <h2 className="text-xl p-0">
                                      Release To Marketing
                                    </h2>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="lg:w-3/5	p-0">
                              <img className="" src={netwok_one} />
                            </div>
                          </div>
                        </div>
                      </div></>
                  ) : (
                    ""
                  )}
                </div>
                <div className="">
                  {kk5 == true ? (
                    <>

                      <div className="my-6 border-t-2 border-[#cccccc] border-dotted my-20">
                        <h2 className="text-[30px] text-[#787878] font-[700] my-5">
                          Challenges and solutions
                        </h2>
                        <div className="w-full lg:flex my-2">
                          <div className=" border p-4 drop-shadow-lg lg:w-1/3 relative overflow-hidden mx-2">
                            <div className="items-center rounded-lg relative mt-14">
                              <h2 className="text-2xl font-bold text-[#656565]">
                                Setting Goals
                              </h2>
                              <p className="pt-6 text-[#737373]">
                                Setting clear and realistic goals is the
                                foundation of any successful web development
                                project. These goals should outline the
                                project's objectives, target audience, and
                                desired outcomes, providing a roadmap for the
                                development process.
                              </p>
                            </div>
                            <div className="absolute bg-[#ff0000] top-0 right-0 p-6 rounded-l">
                              <img src={shape_one} />
                            </div>
                          </div>
                          <div className=" border p-4 drop-shadow-lg lg:w-1/3 relative overflow-hidden mx-2">
                            <div className="items-center rounded-lg relative mt-14">
                              <h2 className="text-2xl font-bold text-[#656565]">
                                Setting Goals
                              </h2>
                              <p className="pt-6 text-[#737373]">
                                Setting clear and realistic goals is the
                                foundation of any successful web development
                                project. These goals should outline the
                                project's objectives, target audience, and
                                desired outcomes, providing a roadmap for the
                                development process.
                              </p>
                            </div>
                            <div className="absolute bg-[#ff0000] top-0 right-0 p-6 rounded-l">
                              <img src={shape_one} />
                            </div>
                          </div>
                          <div className=" border p-4 drop-shadow-lg lg:w-1/3 relative overflow-hidden mx-2">
                            <div className="items-center rounded-lg relative mt-14">
                              <h2 className="text-2xl font-bold text-[#656565]">
                                Setting Goals
                              </h2>
                              <p className="pt-6 text-[#737373]">
                                Setting clear and realistic goals is the
                                foundation of any successful web development
                                project. These goals should outline the
                                project's objectives, target audience, and
                                desired outcomes, providing a roadmap for the
                                development process.
                              </p>
                            </div>
                            <div className="absolute bg-[#ff0000] top-0 right-0 p-6 rounded-l">
                              <img src={shape_one} />
                            </div>
                          </div>
                        </div>
                        <div className="w-full lg:flex my-2">
                          <div className=" border p-4 drop-shadow-lg lg:w-1/3 relative overflow-hidden mx-2">
                            <div className="items-center rounded-lg relative mt-14">
                              <h2 className="text-2xl font-bold text-[#656565]">
                                Setting Goals
                              </h2>
                              <p className="pt-6 text-[#737373]">
                                Setting clear and realistic goals is the
                                foundation of any successful web development
                                project. These goals should outline the
                                project's objectives, target audience, and
                                desired outcomes, providing a roadmap for the
                                development process.
                              </p>
                            </div>
                            <div className="absolute bg-[#ff0000] top-0 right-0 p-6 rounded-l">
                              <img src={shape_one} />
                            </div>
                          </div>
                          <div className=" border p-4 drop-shadow-lg lg:w-1/3 relative overflow-hidden mx-2">
                            <div className="items-center rounded-lg relative mt-14">
                              <h2 className="text-2xl font-bold text-[#656565]">
                                Setting Goals
                              </h2>
                              <p className="pt-6 text-[#737373]">
                                Setting clear and realistic goals is the
                                foundation of any successful web development
                                project. These goals should outline the
                                project's objectives, target audience, and
                                desired outcomes, providing a roadmap for the
                                development process.
                              </p>
                            </div>
                            <div className="absolute bg-[#ff0000] top-0 right-0 p-6 rounded-l">
                              <img src={shape_one} />
                            </div>
                          </div>
                          <div className=" border p-4 drop-shadow-lg lg:w-1/3 relative overflow-hidden mx-2">
                            <div className="items-center rounded-lg relative mt-14">
                              <h2 className="text-2xl font-bold text-[#656565]">
                                Setting Goals
                              </h2>
                              <p className="pt-6 text-[#737373]">
                                Setting clear and realistic goals is the
                                foundation of any successful web development
                                project. These goals should outline the
                                project's objectives, target audience, and
                                desired outcomes, providing a roadmap for the
                                development process.
                              </p>
                            </div>
                            <div className="absolute bg-[#ff0000] top-0 right-0 p-6 rounded-l">
                              <img src={shape_one} />
                            </div>
                          </div>
                        </div>
                      </div></>
                  ) : (
                    ""
                  )}
                </div>
                <div className="">
                  {kk6 == true ? (
                    <>
                      <div className="my-6 border-t-2 border-[#cccccc] border-dotted my-20">
                        <h2 className="text-[30px] text-[#787878] font-[700] my-5 ">User Case</h2>
                        <div className=" lg:flex relative justify-center">
                          <div className="bg-white text-center relative top-[35px] lg:w-[16%] ">
                            <div className="relative">
                              <h2 className="text-3xl font-bold text-[#142396]">
                                1
                              </h2>
                              <p className="w-52 relative right-[15px] text-[#737373] responcive  lg:mx-0 mx-auto">
                                identify all system users and create a profile for
                                each one
                              </p>
                              <p className="userarrowline"></p>
                            </div>
                            <div className=" relative bg-[#142396] inline-block p-6 rounded-full border-4 border-[#cbd1ff]">
                              <img src={usercase_one} />
                            </div>
                            <div className="flex justify-center relative bottom-[65px] ">
                              <img src={userarrow_bottam} />
                            </div>
                          </div>
                          <div className="bg-white text-center relative  lg:w-[16%] lg:top-[125px]">
                            <div className="flex justify-center relative top-[30px] top-[50px] ">
                              <img src={userarrow_top} />
                            </div>
                            <div className="relative bg-[#142396] inline-table p-6 rounded-full border-4 border-[#cbd1ff]  ">
                              <img src={usercase_one} />
                            </div>
                            <div className="relative ">
                              <p className="userarrowline_2 relative "></p>
                              <h2 className="text-3xl font-bold text-[#142396]">
                                2
                              </h2>
                              <p className="w-52 relative  lg:right-[15px] text-[#737373] responcive  lg:mx-0 mx-auto">
                                Select one user and define thir goal
                              </p>
                            </div>

                          </div>
                          <div className="bg-white text-center relative top-[35px] lg:w-[16%]">
                            <div className="relative right-[]">
                              <h2 className="text-3xl font-bold text-[#142396]">
                                3
                              </h2>
                              <p className="w-52 relative  lg:right-[15px] text-[#737373] responcive  lg:mx-0 mx-auto">
                                Describe the course tacken throught the system to reach that goal                              </p>
                              <p className="userarrowline"></p>
                            </div>
                            <div className=" relative bg-[#142396] inline-block p-6 rounded-full border-4 border-[#cbd1ff] right-[]">
                              <img src={usercase_one} />
                            </div>
                            <div className="flex justify-center relative bottom-[65px] right-[] ">
                              <img src={userarrow_bottam} />
                            </div>
                          </div>
                          <div className="bg-white text-center relative  lg:w-[16%] lg:top-[125px]">
                            <div className="flex justify-center relative top-[30px]  top-[50px] ">
                              <img src={userarrow_top} />
                            </div>
                            <div className="relative bg-[#142396] inline-table p-6 rounded-full border-4 border-[#cbd1ff]  ">
                              <img src={usercase_one} />
                            </div>
                            <div className="relative ">
                              <p className="userarrowline_2 relative "></p>
                              <h2 className="text-3xl font-bold text-[#142396]">
                                4
                              </h2>
                              <p className="w-52 relative  lg:right-[15px] text-[#737373] responcive  lg:mx-0 mx-auto">
                                consider every alternate course of events and extend use case                              </p>
                            </div>

                          </div>
                          <div className="bg-white text-center relative top-[35px] lg:w-[16%]">
                            <div className="relative right-[]">
                              <h2 className="text-3xl font-bold text-[#142396]">
                                5
                              </h2>
                              <p className="w-52 relative  lg:right-[15px] text-[#737373] responcive  lg:mx-0 mx-auto">
                                Identify all commonalities in user journeys to create a common course use case
                              </p>
                              <p className="userarrowline"></p>
                            </div>
                            <div className=" relative bg-[#142396] inline-block p-6 rounded-full border-4 border-[#cbd1ff] right-[]">
                              <img src={usercase_one} />
                            </div>
                            <div className="flex justify-center relative bottom-[65px] right-[] ">
                              <img src={userarrow_bottam} />
                            </div>
                          </div>
                          <div className="bg-white text-center relative  lg:w-[16%] lg:top-[125px] z-[-1]">
                            <div className="flex justify-center relative top-[30px]  top-[50px] ">
                              <img src={userarrow_top} />
                            </div>
                            <div className="relative bg-[#142396] inline-table p-6 rounded-full border-4 border-[#cbd1ff]  ">
                              <img src={usercase_one} />
                            </div>
                            <div className="relative ">
                              <p className="userarrowline_2 relative "></p>
                              <h2 className="text-3xl font-bold text-[#142396]">
                                6
                              </h2>
                              <p className="w-52 relative lg:right-[15px] text-[#737373] responcive  lg:mx-0 mx-auto">
                                Repeat steps 2-5 for all other system user                              </p>
                            </div>

                          </div>

                        </div>
                      </div>
                      </>
                  ) : (
                    ""
                  )}
                  {Testing == true ? (
                  <>
                    
                    <div className="my-6 border-t-2 border-[#cccccc] border-dotted my-20">
                      <h2 className="text-[30px] text-[#787878] font-[700] my-5">Testing</h2>
                      <div className="bg-[#eaeaea] p-0 shadow_box">
                        <div className="">
                          <img className="p-6" src={ssss} />
                        </div>
                      </div>
                    </div>
                  </>
                  ):("")}
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default CaseStudies;
