import React, { useEffect, useState } from "react";
import contact_bg from "../img/background_img/Contact-Us.webp";
import { NavLink } from "react-router-dom";
import Aservices from "./Aservices";
import Helmet from "react-helmet";
import logo from "../img/logo192.png";
import res from "../img/bn3256.png";
import help from "../img/Need-help.webp";
import { logRoles } from "@testing-library/react";
import commerce_Development from "../../src/aservecse_img/002.png";
import commerce_Website_Design from "../../src/aservecse_img/003.png";
import Platform_Integration from "../../src/aservecse_img/004.png";
import Ecommerce_image_ecc from "../../src/aservecse_img/About_Ajiva_Eccomerce.png";
import Ecommerce_image from "../../src/aservecse_img/htmlcssimage.jpg";
import Ecommerce_image_2 from "../../src/aservecse_img/ecom_pg_lfimg_2.png";
import Ecommerce_image_3 from "../../src/aservecse_img/Eccomerce_3.png";
import Ecommerce_image_4 from "../../src/aservecse_img/Eccomerce_4.png";
import Eccomerce_SCO from "../../src/aservecse_img/Eccomerce_SCO.png";
import new_design from "../../src/aservecse_img/New_design.png";
// var Web = Web_Development();

function frontend() {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="title"
          content="Empower Your Digital Presence with Expert Website & App Development Services - Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" HTML, CSS, JavaScript, Responsive Design, UI/UX Design, Frameworks (e.g., React, Angular, Vue.js), Front-end Libraries (e.g., Bootstrap, Foundation), Cross-browser Compatibility, Performance Optimization, Accessibility Compliance"
        />
        <title>
        Empower Your Digital Presence with Expert Website & App Development Services - Ajiva Infotech
        </title>
        <meta
          name="description"
          content="Frontend-development- Ajiva Infotech"
        />
        <link rel="apple-touch-icon" href={logo} />
      </Helmet>
      {/* <div className="cocontainer m-auton">
        <section>
          <div className="flex justify-center">
            <img src={contact_bg} alt="Website Development" />
          </div>
          <div className="container m-auto ">
            <div className="">
              <div className="opp">
                <div className="bang">
                  <h1 className="">
                    <span className="text-red-700	 ">Frontend development</span>
                  </h1>
                </div>
                <div className="Api_int">
                  <NavLink to="/" className="text-base text-red-600	pr-2">
                    Home
                  </NavLink>
                  <span className="text-sm">/</span>
                  <span className="pl-2 text-2xl font-bold  text-[#333333]">
                    Frontend development
                  </span>
                </div>
                <div className="w-full">
                  <div className="store mx10">
                    <p className=" online text-[#515151]  text-base font-medium">
                      Welcome to Ajiva Infotech, where we specialize in
                      delivering cutting-edge front-end development solutions
                      tailored to meet your unique needs. Our team of skilled
                      developers is proficient in a wide array of technologies
                      and frameworks to ensure that your website or application
                      stands out in today's competitive digital landscape.
                    </p>
                    <br></br>
                    
                  </div>
                </div>
                <div className="">
                
                </div>
                <div className=" imgs w-full px-20 ">
                  <div className="pt-8 graph mr-15 hhh">
                    <h1 className="goal Research text-xl font-bold  text-[#333333]">
                      HTML, CSS, and JavaScript Mastery
                    </h1>
                    <p className=" goal Research text-[#515151] text-base font-medium">
                      At Ajiva Infotech, we believe that the foundation of any
                      exceptional web experience starts with clean, semantic
                      HTML, elegant CSS styling, and powerful JavaScript
                      functionality. Our developers are experts in crafting
                      responsive and visually stunning user interfaces that
                      engage and captivate your audience.
                    </p>
                    <h1 className=" goal Research text-xl font-bold  text-[#333333]">
                      UI/UX Design Excellence
                    </h1>
                    <p className=" goal Research text-[#515151] text-base font-medium">
                      We understand the importance of user experience in driving
                      success online. That's why our team places a strong
                      emphasis on UI/UX design principles to create intuitive
                      and user-friendly interfaces. From wireframing to
                      prototyping, we work tirelessly to ensure that every
                      interaction is seamless and enjoyable for your users.
                    </p>
                    <h1 className=" goal Research text-xl font-bold  text-[#333333]">
                      Frameworks and Libraries
                    </h1>
                    <p className=" goal Research text-[#515151] text-base font-medium">
                      Whether you're looking to leverage the power of React,
                      Angular, Vue.js, or other cutting-edge frameworks, we've
                      got you covered. Our developers are well-versed in the
                      latest trends and technologies, allowing us to choose the
                      right tools for the job and deliver exceptional results.
                    </p>
                    <h1 className=" goal Research text-xl font-bold  text-[#333333]">
                      Cross-Browser Compatibility and Performance Optimization
                    </h1>
                    <p className=" goal Research text-[#515151] text-base font-medium">
                      We understand the importance of reaching your audience
                      wherever they are. That's why we ensure that your website
                      or application performs flawlessly across all major
                      browsers and devices. Our developers also prioritize
                      performance optimization, so your site loads quickly and
                      efficiently, providing users with a seamless experience
                      every time.
                    </p>

                    <br></br>
                  </div>
                  <div className="mt-10   ">
                    <img src={res} alt="Website Development" />
                  </div>
                </div>
                <div>
                  <h1 className=" goal Research text-xl font-bold  text-[#333333]">
                    Accessibility Compliance
                  </h1>
                  <p className=" goal Research text-[#515151] text-base font-medium">
                    Ensuring that your digital presence is accessible to all
                    users is not just a best practice—it's the right thing to
                    do. Our developers are well-versed in accessibility
                    standards and guidelines, ensuring that your website or
                    application is usable by individuals of all abilities.
                  </p>
                  <p className=" goal Research text-[#515151] text-base font-medium">
                    At Ajiva Infotech, we're passionate about front-end
                    development, and we're dedicated to helping you achieve your
                    goals online. Contact us today to learn more about how we
                    can elevate your digital presence with our expert front-end
                    development services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div> */}

      <div className="cocontainer m-auton">
        <section>
          <div className="flex justify-center">
            <img src={contact_bg} alt="contact_bg" />
          </div>
          <div className="container m-auto ">
            <div className="">
              <div className="opp">
                <div className="bang">
                  <h1 className="text-red-700	 ">Frontend development</h1>
                </div>
                <div className="Api_int">
                  <NavLink to="/" className="text-base text-red-600	pr-2">
                    Home
                  </NavLink>
                  <span className="text-sm">/</span>
                  <h1 className="pl-2  lg:text-2xl font-bold text-[#333333]">
                    Frontend development
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* =================new card================================================= */}

        {/* ===================section ksjdgksvjdvd====================================================== */}

        <div className="ecomerece_border_frist_main">
          <div className="container m-auto">
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image ecomerece-image-sect ">
                  <img src={Ecommerce_image_3} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Frontend development
                </h1>
                <p className="">
                  Welcome to Ajiva Infotech, where we specialize in delivering
                  cutting-edge front-end development solutions tailored to meet
                  your unique needs. Our team of skilled developers is
                  proficient in a wide array of technologies and frameworks to
                  ensure that your website or application stands out in today's
                  competitive digital landscape.
                </p>
                {/* <div>
                  <ul className="web_des_ser_offer">
                    <li>Optimize customer experience</li>
                    <li>Increase operational efficiency</li>
                    <li>Gain a competitive advantage</li>
                    <li>Unlock new revenue opportunities</li>
                    <li>Adapt to changing market demands</li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  HTML, CSS, and JavaScript Mastery
                </h1>
                <p className="">
                  At Ajiva Infotech, we believe that the foundation of any
                  exceptional web experience starts with clean, semantic HTML,
                  elegant CSS styling, and powerful JavaScript functionality.
                  Our developers are experts in crafting responsive and visually
                  stunning user interfaces that engage and captivate your
                  audience.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_2} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  UI/UX Design Excellence
                </h1>
                <p className="">
                  We understand the importance of user experience in driving
                  success online. That's why our team places a strong emphasis
                  on UI/UX design principles to create intuitive and
                  user-friendly interfaces. From wireframing to prototyping, we
                  work tirelessly to ensure that every interaction is seamless
                  and enjoyable for your users.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change  ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Frameworks and Libraries
                </h1>
                <p className="">
                  Whether you're looking to leverage the power of React,
                  Angular, Vue.js, or other cutting-edge frameworks, we've got
                  you covered. Our developers are well-versed in the latest
                  trends and technologies, allowing us to choose the right tools
                  for the job and deliver exceptional results.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_4} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_2} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Cross-Browser Compatibility and Performance Optimization
                </h1>
                <p className="">
                  We understand the importance of reaching your audience
                  wherever they are. That's why we ensure that your website or
                  application performs flawlessly across all major browsers and
                  devices. Our developers also prioritize performance
                  optimization, so your site loads quickly and efficiently,
                  providing users with a seamless experience every time.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Accessibility Compliance
                </h1>
                <p className="">
                  Ensuring that your digital presence is accessible to all users
                  is not just a best practice—it's the right thing to do. Our
                  developers are well-versed in accessibility standards and
                  guidelines, ensuring that your website or application is
                  usable by individuals of all abilities. At Ajiva Infotech,
                  we're passionate about front-end development, and we're
                  dedicated to helping you achieve your goals online. Contact us
                  today to learn more about how we can elevate your digital
                  presence with our expert front-end development services.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            {/* <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2  fade-left reveal active ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Eccomerce_SCO} />
                </div>
              </div>
              <div className="lg:w-1/2  fade-right reveal active Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  E-commerce SEO Optimization
                </h1>
                <p className="">
                  Drive organic traffic to your e-commerce store with our SEO
                  optimization services. From keyword research to on-page
                  optimization, we'll implement proven strategies to improve
                  your site's visibility in search engine results and attract
                  qualified leads ready to make a purchase.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[]">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Why Choose Ajiva Infotech for E-commerce Development?
                </h1>
                <p className="">
                  Industry expertise: With years of experience in e-commerce
                  development, we have the knowledge and skills to deliver
                  exceptional results that exceed your expectations. Customized
                  solutions: We understand that every business is unique, which
                  is why we tailor our services to meet your specific needs and
                  objectives. Customer satisfaction: Your success is our
                  priority, and we're committed to providing you with
                  personalized support and guidance every step of the way.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Get Started Today
                </h1>
                <p className="">
                  Ready to take your e-commerce business to new heights? Contact
                  us today to discuss your project requirements and discover how
                  our expert e- commerce development solutions can help you
                  achieve your goals. With Ajiva Infotech, success is just a
                  click away!
                </p>
              </div>
            </div> */}
          </div>
        </div>
        <Aservices />
      </div>
    </>
  );
}
export default frontend;
