import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import logo from "../img/logo192.png";
import icon_1 from "../img/portfolio_icon_1.webp";
import icon_2 from "../img/portfolio_icon_2.webp";
import icon_3 from "../img/portfolio_icon_3.webp";
import portfolio_bg from "../img/background_img/Portfolio.webp";
// import portfolio_bg_1 from "../img/banner/portfolio_mob_view_003.jpg";
import pharmagel_logo from "../img/company_logo/pharmagel_logo.webp";
import ezmoov_logo from "../img/company_logo/ezmoov_logo_short.webp";
import hagglerplanet_logo from "../img/company_logo/hagglerplanet_logo.webp";
import ldp_logo from "../img/company_logo/ldp_logo.webp";
import logo_1 from "../img/company_logo/logo-1.webp";
import netxgen_logo from "../img/company_logo/netxgen_logo.webp";
import card_one from "../img/portfoilo_img_1.webp";
import card_two from "../img/one_porfolio_.webp";
import card_three from "../img/one_porfolio_2.webp";
import card_four from "../img/one_porfolio_3.webp";
import card_five from "../img/one_porfolio_4.webp";
import card_six from "../img/one_porfolio_5.webp";
import sentseen_card from "../img/company_logo/sentseen_image.png";
import sentseen_logo from "../img/company_logo/sentseen_logo.png";

const Portfolio = () => {
  useEffect(() => {
    setkk(true);
  }, []);
  const [kk, setkk] = useState(false);
  const [kk1, setkk1] = useState(false);
  const [kk2, setkk2] = useState(false);
  const [kk3, setkk3] = useState(false);
  const [kk4, setkk4] = useState(false);
  const [kk5, setkk5] = useState(false);
  const [kk6, setkk6] = useState(false);
  const [kk7, setkk7] = useState(false);
  useEffect(() => {
    setkk(true);
  }, []);
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="title"
          content="Client Success Stories in Business Management |AjivaInfotech"
        />
        <title>
          Client Success Stories in Business Management |AjivaInfotech
        </title>
        <meta name="robots" content="index, follow"></meta>
        <meta
          content="Learn from our client success stories in the field of business management. See how AjivaInfotech has empowered businesses to achieve their goals"
          name="description"
        ></meta>
        <meta
          name="description"
          content="Learn from our client success stories in the field of business management. See how AjivaInfotech has empowered businesses to achieve their goals"
        />
        <link rel="apple-touch-icon" href={logo} />
      </Helmet>

      <section>
        <section className="container-fluid m-auto">
          <div className="w-full">
            <img
              className="w-full"
              src={portfolio_bg}
              alt="portfolio_bg"
              // className="lg:block md:block hidden"
            />
            {/* <img
              src={portfolio_bg_1}
              alt="portfolio_bg"
              className="lg:hidden md:hidden block"
            /> */}
          </div>
        </section>
        <section className="aj_portfolio_2">
          <div className="container  m-auto">
            <div className="w-full">
              <div className="text-center my-8">
                <h2 className="text-5xl text-[#343434] font-semibold">
                  Our Portfolio
                </h2>
                <p className="text-xl font-medium py-3 text-[#4b4b4b]">
                  Unlocking Success: 2000+ Projects Flourish in 50+ Nations
                </p>
              </div>
            </div>
            <div class="border-b-2 border-gray-600 py-0 my-5 mx-4 border-dotted"></div>
          </div>
        </section>
        <section className="aj_portfolio_3">
          <div className="container m-auto">
            <div className="w-full">
              <ul className="flex lg:justify-center py-4 overflow-auto mx-4">
                <li>
                  <h2
                    className={
                      kk
                        ? "lg:text-3xl text-2xl font-semibold text-red-600 mx-4 cursor-pointer border-b border-b-2 border-red-600 pb-2"
                        : "lg:text-3xl text-2xl font-semibold text-gray-700 mx-4 cursor-pointer pb-2"
                    }
                    onClick={(e) => {
                      setkk(true);
                      setkk1(false);
                      setkk2(false);
                      setkk3(false);
                      setkk4(false);
                      setkk5(false);
                      setkk6(false);
                      setkk7(false);
                    }}
                  >
                    All
                  </h2>
                </li>
                <li>
                  <h2
                    className={
                      kk1
                        ? "lg:text-3xl text-2xl font-semibold text-red-600 mx-4 cursor-pointer border-b border-b-2 border-red-600 pb-2"
                        : "lg:text-3xl text-2xl font-semibold text-gray-700 mx-4 cursor-pointer pb-2"
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(true);
                      setkk2(false);
                      setkk3(false);
                      setkk4(false);
                      setkk5(false);
                      setkk6(false);
                      setkk7(false);
                    }}
                  >
                    Static
                  </h2>
                </li>
                <li>
                  <h2
                    className={
                      kk2
                        ? "lg:text-3xl text-2xl font-semibold text-red-600 mx-4 cursor-pointer border-b border-b-2 border-red-600 pb-2"
                        : "lg:text-3xl text-2xl font-semibold text-gray-700 mx-4 cursor-pointer pb-2"
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(false);
                      setkk2(true);
                      setkk3(false);
                      setkk4(false);
                      setkk5(false);
                      setkk6(false);
                      setkk7(false);
                    }}
                  >
                    Ecommerce
                  </h2>
                </li>
                <li>
                  <h2
                    className={
                      kk3
                        ? "lg:text-3xl text-2xl font-semibold text-red-600 mx-4 cursor-pointer border-b border-b-2 border-red-600 pb-2"
                        : "lg:text-3xl text-2xl font-semibold text-gray-700 mx-4 cursor-pointer pb-2"
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(false);
                      setkk2(false);
                      setkk3(true);
                      setkk4(false);
                      setkk5(false);
                      setkk6(false);
                      setkk7(false);
                    }}
                  >
                    Mobile App
                  </h2>
                </li>
                <li>
                  <h2
                    className={
                      kk4
                        ? "lg:text-3xl text-2xl font-semibold text-red-600 mx-4 cursor-pointer border-b border-b-2 border-red-600 pb-2"
                        : "lg:text-3xl text-2xl font-semibold text-gray-700 mx-4 cursor-pointer pb-2"
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(false);
                      setkk2(false);
                      setkk3(false);
                      setkk4(true);
                      setkk5(false);
                      setkk6(false);
                      setkk7(false);
                    }}
                  >
                    Logo
                  </h2>
                </li>
                <li>
                  <h2
                    className={
                      kk5
                        ? "lg:text-3xl text-2xl font-semibold text-red-600 mx-4 cursor-pointer border-b border-b-2 border-red-600 pb-2"
                        : "lg:text-3xl text-2xl font-semibold text-gray-700 mx-4 cursor-pointer pb-2"
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(false);
                      setkk2(false);
                      setkk3(false);
                      setkk4(false);
                      setkk5(true);
                      setkk6(false);
                      setkk7(false);
                    }}
                  >
                    CMS
                  </h2>
                </li>
                <li>
                  <h2
                    className={
                      kk6
                        ? "lg:text-3xl text-2xl font-semibold text-red-600 mx-4 cursor-pointer border-b border-b-2 border-red-600 pb-2"
                        : "lg:text-3xl text-2xl font-semibold text-gray-700 mx-4 cursor-pointer pb-2"
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(false);
                      setkk2(false);
                      setkk3(false);
                      setkk4(false);
                      setkk5(false);
                      setkk6(true);
                      setkk7(false);
                    }}
                  >
                    Landing Page
                  </h2>
                </li>
                <li>
                  <h2
                    className={
                      kk7
                        ? "lg:text-3xl text-2xl font-semibold text-red-600 mx-4 cursor-pointer border-b border-b-2 border-red-600"
                        : "lg:text-3xl text-2xl font-semibold text-gray-700 mx-4 cursor-pointer"
                    }
                    onClick={(e) => {
                      setkk(false);
                      setkk1(false);
                      setkk2(false);
                      setkk3(false);
                      setkk4(false);
                      setkk5(false);
                      setkk6(false);
                      setkk7(true);
                    }}
                  >
                    CRM
                  </h2>
                </li>
              </ul>
            </div>
            {kk == true ? (
              <div className="">
                <div className="w-full lg:flex my-10">
                  <div className="lg:w-1/3 relative my-10 lg:my-0 p-2 lg:p-0 mx-1">
                    <div className="relative">
                      <img
                        src={card_three}
                        alt="card_three"
                        className="border-2 p-1 lg:mx-4  border-gray-300"
                      />
                    </div>
                    <a href="https://ezmoov.com/">
                      <div className="porfolio_company_logo absolute border-2 border-gray-300 rounded-full px-4 py-4 bottom-[-34px] left-[38%] bg-white hover:border-red-400">
                        <img src={ezmoov_logo} alt="ezmoov_logo" />
                      </div>
                    </a>
                  </div>
                  <div className="lg:w-1/3 relative my-10 lg:my-0 p-2 lg:p-0 mx-1">
                    <div className="relative">
                      <img
                        src={card_two}
                        alt="card_two"
                        className="border-2 p-1 lg:mx-4  border-gray-300"
                      />
                    </div>
                    <a href="https://biotclabware.com/">
                      <div className="porfolio_company_logo absolute border-2 border-gray-300 rounded-full px-4 py-4 bottom-[-34px] left-[38%] bg-white hover:border-red-400">
                        <img src={ldp_logo} alt="ldp_logo" />
                      </div>
                    </a>
                  </div>
                  <div className="lg:w-1/3 relative my-10 lg:my-0 p-2 lg:p-0 mx-1">
                    <div className="relative">
                      <img
                        src={sentseen_card}
                        alt="card_six"
                        className="border-2 p-1 lg:mx-4  border-gray-300"
                      />
                    </div>
                    <a href="https://sentseen.com/">
                      <div className="porfolio_company_logo absolute border-2 border-gray-300 hover:border-red-400 rounded-full px-4 py-4 bottom-[-34px] left-[38%] bg-white">
                        <img src={sentseen_logo} alt="hagglerplanet_logo" />
                      </div>
                    </a>
                  </div>
                  {/* <div className="lg:w-1/3 relative my-10 lg:my-0 p-2 lg:p-0 mx-1">
                    <div className="relative">
                      <img
                        src={card_two}
                        alt="card_two"
                        className="border-2 p-1 lg:mx-4  border-gray-300"
                      />
                    </div>
                    <a href="https://biotclabware.com/">
                      <div className="porfolio_company_logo absolute border-2 border-gray-300 rounded-full px-4 py-4 bottom-[-34px] left-[38%] bg-white hover:border-red-400">
                        <img src={ldp_logo} alt="ldp_logo" />
                      </div>
                    </a>
                  </div> */}
                </div>
                <div className="w-full lg:flex my-10">
                  <div className="lg:w-1/3 relative my-10 lg:my-0 p-2 lg:p-0 mx-1">
                    <div className="relative">
                      <img
                        src={card_one}
                        alt="card_one"
                        className="border-2 p-1 lg:mx-4  border-gray-300"
                      />
                    </div>
                    <a href="https://pharmagel.net/">
                      <div className="porfolio_company_logo absolute border-2 border-gray-300 rounded-full px-4 py-4 bottom-[-34px] left-[38%] bg-white	hover:border-red-400">
                        <img src={pharmagel_logo} alt="pharmagel_logo" />
                      </div>
                    </a>
                  </div>
                  <div className="lg:w-1/3 relative my-10 lg:my-0 p-2 lg:p-0 mx-1">
                    <div className="relative">
                      <img
                        src={card_four}
                        alt="card_four"
                        className="border-2 p-1 lg:mx-4  border-gray-300"
                      />
                    </div>
                    <a href="https://nikola-brussels.be/">
                      <div className="porfolio_company_logo absolute border-2 border-gray-300 rounded-full px-4 py-4 bottom-[-34px] left-[38%] bg-white hover:border-red-400">
                        <img src={logo_1} alt="logo_1" />
                      </div>
                    </a>
                  </div>
                  <div className="lg:w-1/3 relative my-10 lg:my-0 p-2 lg:p-0 mx-1">
                    <div className="relative">
                      <img
                        src={card_six}
                        alt="card_six"
                        className="border-2 p-1 lg:mx-4  border-gray-300"
                      />
                    </div>
                    <a href="https://hagglerplanet.com/">
                      <div className="porfolio_company_logo absolute border-2 border-gray-300 hover:border-red-400 rounded-full px-4 py-4 bottom-[-34px] left-[38%] bg-white">
                        <img
                          src={hagglerplanet_logo}
                          alt="hagglerplanet_logo"
                        />
                      </div>
                    </a>
                  </div>
                  {/* <div className="lg:w-1/3 relative my-10 lg:my-0 p-2 lg:p-0 mx-1">
                    <div className="relative">
                      <img
                        src={card_four}
                        alt="card_four"
                        className="border-2 p-1 lg:mx-4  border-gray-300"
                      />
                    </div>
                    <a href="https://nikola-brussels.be/">
                      <div className="porfolio_company_logo absolute border-2 border-gray-300 rounded-full px-4 py-4 bottom-[-34px] left-[38%] bg-white hover:border-red-400">
                        <img src={logo_1} alt="logo_1" />
                      </div>
                    </a>
                  </div> */}
                </div>
                {/* <div className="w-full lg:flex my-10">
                  <div className="lg:w-1/3 relative my-10 lg:my-0 p-2 lg:p-0 mx-1">
                    <div className="relative">
                      <img
                        src={card_five}
                        alt="card_five"
                        className="border-2 p-1 lg:mx-4  border-gray-300"
                      />
                    </div>
                    <a href="https://getnexgen.com/">
                      <div className="porfolio_company_logo absolute border-2 border-gray-300 rounded-full px-4 py-4 bottom-[-34px] left-[38%] bg-white hover:border-red-400">
                        <img src={netxgen_logo} alt="netxgen_logo" />
                      </div>
                    </a>
                  </div>
                  <div className="lg:w-1/3 relative my-10 lg:my-0 p-2 lg:p-0 mx-1">
                    <div className="relative">
                      <img
                        src={card_six}
                        alt="card_six"
                        className="border-2 p-1 lg:mx-4  border-gray-300"
                      />
                    </div>
                    <a href="https://hagglerplanet.com/">
                      <div className="porfolio_company_logo absolute border-2 border-gray-300 hover:border-red-400 rounded-full px-4 py-4 bottom-[-34px] left-[38%] bg-white">
                        <img
                          src={hagglerplanet_logo}
                          alt="hagglerplanet_logo"
                        />
                      </div>
                    </a>
                  </div>
                  <div className="lg:w-1/3 relative my-10 lg:my-0 p-2 lg:p-0 mx-1">
                    <div className="relative">
                      <img
                        src={card_six}
                        alt="card_six"
                        className="border-2 p-1 lg:mx-4  border-gray-300"
                      />
                    </div>
                    <a href="https://hagglerplanet.com/">
                      <div className="porfolio_company_logo absolute border-2 border-gray-300 hover:border-red-400 rounded-full px-4 py-4 bottom-[-34px] left-[38%] bg-white">
                        <img
                          src={hagglerplanet_logo}
                          alt="hagglerplanet_logo"
                        />
                      </div>
                    </a>
                  </div>
                </div> */}
              </div>
            ) : (
              ""
            )}
            <div className="w-full lg:flex my-10">
              {kk1 == true ? (
                <div className="lg:w-1/2 relative my-10 lg:my-0 p-2 lg:p-0">
                  <div className="relative">
                    <img
                      src={card_three}
                      alt="card_three"
                      className="border-2 p-1 lg:mx-4  border-gray-300"
                    />
                  </div>
                  <a href="https://ezmoov.com/">
                    <div className="porfolio_company_logo absolute border-2 border-gray-300 rounded-full px-4 py-4 bottom-[-34px] left-[38%] bg-white hover:border-red-400">
                      <img src={ezmoov_logo} alt="ezmoov_logo" />
                    </div>
                  </a>
                </div>
              ) : (
                ""
              )}
              {kk2 == true ? (
                <div className="lg:w-1/2 relative my-10 lg:my-0 p-2 lg:p-0">
                  <div className="relative">
                    <img
                      src={card_two}
                      alt="card_two"
                      className="border-2 p-1 lg:mx-4  border-gray-300"
                    />
                  </div>
                  <a href="https://biotclabware.com/">
                    <div className="porfolio_company_logo absolute border-2 border-gray-300 rounded-full px-4 py-4 bottom-[-34px] left-[38%] bg-white hover:border-red-400">
                      <img src={ldp_logo} alt="ldp_logo" />
                    </div>
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
            {kk3 == true ? (
              <div className="w-full lg:flex my-10">
                <div className="lg:w-1/2 relative my-10 lg:my-0 p-2 lg:p-0">
                  <div className="relative">
                    <img
                      src={card_one}
                      alt="card_one"
                      className="border-2 p-1 lg:mx-4  border-gray-300"
                    />
                  </div>
                  <a href="https://pharmagel.net/">
                    <div className="porfolio_company_logo absolute border-2 border-gray-300 rounded-full px-4 py-4 bottom-[-34px] left-[38%] bg-white	hover:border-red-400">
                      <img src={pharmagel_logo} alt="pharmagel_logo" />
                    </div>
                  </a>
                </div>
                <div className="lg:w-1/2 relative my-10 lg:my-0 p-2 lg:p-0">
                  <div className="relative">
                    <img
                      src={card_four}
                      alt="card_four"
                      className="border-2 p-1 lg:mx-4  border-gray-300"
                    />
                  </div>
                  <a href="https://nikola-brussels.be/">
                    <div className="porfolio_company_logo absolute border-2 border-gray-300 rounded-full px-4 py-4 bottom-[-34px] left-[38%] bg-white hover:border-red-400">
                      <img src={logo_1} alt="logo_1" />
                    </div>
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}
            {kk4 == true ? (
              <div className="w-full lg:flex my-10">
                <div className="lg:w-1/2 relative my-10 lg:my-0 p-2 lg:p-0">
                  <div className="relative">
                    <img
                      src={card_five}
                      alt="card_five"
                      className="border-2 p-1 lg:mx-4  border-gray-300"
                    />
                  </div>
                  <a href="https://getnexgen.com/">
                    <div className="porfolio_company_logo absolute border-2 border-gray-300 rounded-full px-4 py-4 bottom-[-34px] left-[38%] bg-white hover:border-red-400">
                      <img src={netxgen_logo} alt="netxgen_logo" />
                    </div>
                  </a>
                </div>
                <div className="lg:w-1/2 relative my-10 lg:my-0 p-2 lg:p-0">
                  <div className="relative">
                    <img
                      src={card_six}
                      alt="card_six"
                      className="border-2 p-1 lg:mx-4  border-gray-300"
                    />
                  </div>
                  <a href="https://hagglerplanet.com/">
                    <div className="porfolio_company_logo absolute border-2 border-gray-300 hover:border-red-400 rounded-full px-4 py-4 bottom-[-34px] left-[38%] bg-white">
                      <img src={hagglerplanet_logo} alt="hagglerplanet_logo" />
                    </div>
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </section>
      </section>
    </>
  );
};
export default Portfolio;
