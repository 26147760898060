import React, { useState } from "react";

const UnixToUTC = () => {
  const [unixTime, setUnixTime] = useState("");
  const [utcTime, setUtcTime] = useState("");

  const handleConvert = () => {
    if (!isNaN(unixTime) && unixTime.trim() !== "") {
      const date = new Date(parseInt(unixTime, 10) * 1000);
      setUtcTime(date.toUTCString());
    } else {
      setUtcTime("Invalid UNIX timestamp");
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(utcTime)
      .then(() => {
        alert("UTC Time copied to clipboard!");
      })
      .catch((error) => {
        console.error("Unable to copy to clipboard: ", error);
      });
  };

  return (
    <>
      <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
        <div className="bg-white rounded-lg shadow-md p-8 w-[50%]">
          <div>
            <h1 className="text-2xl font-bold mb-4 text-center">
              Convert UNIX Time to UTC
            </h1>
          </div>
          <div>
            <input
              type="text"
              className="p-2 border rounded-md shadow-sm mb-4 w-full min-h-[60px]"
              placeholder="Enter UNIX timestamp"
              value={unixTime}
              onChange={(e) => setUnixTime(e.target.value)}
            />
          </div>
          <div className="text-center">
            <button
              onClick={handleConvert}
              className="bg-blue-500 text-white p-2 rounded-md shadow-md w-60 hover:bg-blue-600"
            >
              Convert
            </button>
            {utcTime && (
              <button
                onClick={handleCopyToClipboard}
                className="bg-green-500 text-white p-2 rounded-md shadow-md ml-4 hover:bg-green-600"
              >
                Copy to Clipboard
              </button>
            )}
          </div>
          <div>
            {utcTime && (
              <p className="mt-4 p-2 bg-white border rounded-md shadow-sm text-center min-h-[40px]">
                UTC Time: {utcTime}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UnixToUTC;
