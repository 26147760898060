import React, { useState } from 'react';

const RandomTimeGenerator = () => {
  const [randomTime, setRandomTime] = useState('');
  
  const generateRandomTime = () => {
    const hours = Math.floor(Math.random() * 24);
    const minutes = Math.floor(Math.random() * 60);
    const formattedTime = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
    setRandomTime(formattedTime);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(randomTime);
    alert('Copied to clipboard!');
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-center items-center">
        <button 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={generateRandomTime}
        >
          Generate Random Time
        </button>
        <button
          className="ml-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={copyToClipboard}
        >
          Copy to Clipboard
        </button>
      </div>
      <div className="text-center mt-8">
        <p className="text-4xl font-bold">{randomTime}</p>
      </div>
    </div>
  );
};

export default RandomTimeGenerator;
