import React, { useState } from "react";
import axios from "axios";

function App() {
  const [smtpDetails, setSmtpDetails] = useState({
    host: "",
    port: "",
    secure: false,
    user: "",
    pass: "",
    to: "",
    cc: "",
    bcc: "",
    subject: "",
    text: "",
  });
  const [result, setResult] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSmtpDetails((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:5001/send-email",
        smtpDetails
      );
      if (response.data.success) {
        setResult("SMTP details are correct. Email sent successfully.");
      } else {
        setResult("Failed to send email. Please check the SMTP details.");
      }
    } catch (error) {
      setResult(`Error: ${error.response.data.error}`);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center w-full sm:py-12">
      <div className="relative py-3  sm:m-auto w-[70%]">
        <div className="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
          <div className=" ">
            <div className="flex items-center space-x-5">
              <div className="h-14 w-14 bg-indigo-200 rounded-full flex flex-shrink-0 justify-center items-center text-indigo-500 text-2xl font-mono">
                SMTP
              </div>
              <div className="block pl-2 font-semibold text-xl self-start text-gray-700 m-auto">
                <h2 className="leading-relaxed">SMTP Tester</h2>
              </div>
            </div>
            <form onSubmit={handleSubmit} className="mt-8">
              <input
                type="text"
                name="host"
                placeholder="SMTP Host"
                value={smtpDetails.host}
                onChange={handleChange}
                className="block border border-grey-light w-full p-3 rounded mb-4"
                required
              />
              <input
                type="number"
                name="port"
                placeholder="SMTP Port"
                value={smtpDetails.port}
                onChange={handleChange}
                className="block border border-grey-light w-full p-3 rounded mb-4"
                required
              />
              <label className="flex items-center space-x-2 mb-4">
                <span>Secure:</span>
                <input
                  type="checkbox"
                  name="secure"
                  checked={smtpDetails.secure}
                  onChange={handleChange}
                  className="form-checkbox h-5 w-5 text-indigo-600"
                />
              </label>
              <input
                type="text"
                name="user"
                placeholder="Username"
                value={smtpDetails.user}
                onChange={handleChange}
                className="block border border-grey-light w-full p-3 rounded mb-4"
                required
              />
              <input
                type="password"
                name="pass"
                placeholder="Password"
                value={smtpDetails.pass}
                onChange={handleChange}
                className="block border border-grey-light w-full p-3 rounded mb-4"
                required
              />
              <input
                type="email"
                name="to"
                placeholder="Recipient Email"
                value={smtpDetails.to}
                onChange={handleChange}
                className="block border border-grey-light w-full p-3 rounded mb-4"
                required
              />
              <input
                type="email"
                name="cc"
                placeholder="CC"
                value={smtpDetails.cc}
                onChange={handleChange}
                className="block border border-grey-light w-full p-3 rounded mb-4"
              />
              <input
                type="email"
                name="bcc"
                placeholder="BCC"
                value={smtpDetails.bcc}
                onChange={handleChange}
                className="block border border-grey-light w-full p-3 rounded mb-4"
              />
              <input
                type="text"
                name="subject"
                placeholder="Subject"
                value={smtpDetails.subject}
                onChange={handleChange}
                className="block border border-grey-light w-full p-3 rounded mb-4"
                required
              />
              <textarea
                name="text"
                placeholder="Message"
                value={smtpDetails.text}
                onChange={handleChange}
                className="block border border-grey-light w-full p-3 rounded mb-4 h-32"
                required
              />
              <button
                type="submit"
                className="w-full text-center py-3 rounded bg-indigo-600 text-white hover:bg-indigo-500 focus:outline-none my-1"
              >
                Send
              </button>
            </form>
            {result && <p className="text-sm text-gray-600 mt-4">{result}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
