import React, { useState } from "react";
import xmlbuilder from "xmlbuilder";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Helmet from "react-helmet";
import logo from "../../img/logo192.png";
const Sitemap = () => {
  const [urlList, setUrlList] = useState("");
  const [xmlString, setXmlString] = useState("");

  const handleUrlInputChange = (event) => {
    setUrlList(event.target.value);
  };

  const generateSitemap = () => {
    const urls = urlList
      .split("\n")
      .map((url) => url.trim())
      .filter((url) => url !== "");
    const root = xmlbuilder
      .create("urlset", { version: "1.0", encoding: "UTF-8" })
      .att("xmlns", "http://www.sitemaps.org/schemas/sitemap/0.9");

    urls.forEach((url) => {
      root
        .ele("url")
        .ele("loc", {}, url)
        .up()
        .ele("lastmod", {}, new Date().toISOString().split("T")[0])
        .up()
        .ele("changefreq", {}, "weekly")
        .up()
        .ele("priority", {}, "0.5")
        .up();
    });

    const generatedXmlString = root.end({ pretty: true });
    setXmlString(generatedXmlString);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    generateSitemap();
  };

  const handleDownloadXml = () => {
    const url = `data:text/xml;charset=utf-8,${encodeURIComponent(xmlString)}`;
    const link = document.createElement("a");
    link.href = url;
    link.download = "sitemap.xml";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const copyXmlToClipboard = () => {
    navigator.clipboard
      .writeText(xmlString)
      .then(() => {
        toast.success("Sitemap XML copied to clipboard!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        toast.error("Failed to copy XML to clipboard", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="" />
        <meta
          name="title"
          content="Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" coding validation tools, programming tools, code checker, code error finding tool, code beautify tool, code scanning tool"
        />
        <meta
          name="description"
          content=" Discover top coding validation tools to ensure error-free, high-quality code. Learn how tools like HTML Preview, Minyfiy to UnMinyfiy, Json Validator, Validate CSS, String Replace, Webhook Test can streamline your development workflow and enforce coding standards. "
        />
        {/* <link rel="apple-touch-icon" href={logo} /> */}
        <title>
          Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech
        </title>
      </Helmet>
      <div className="p-4">
        <div className="ajivalogo-outer-main my-24">
          <div className="sitemap-generator">
            <div className="flex justify-center">
              <h2 className="text-2xl font-bold">Sitemap Generator</h2>
            </div>
            <form onSubmit={handleFormSubmit} className="mt-4">
              <div className="form-group">
                <label
                  htmlFor="urlList"
                  className="block text-xl font-medium text-gray-700 "
                >
                  Enter URLs (one per line):
                </label>
                <textarea
                  id="urlList"
                  className="form-control text-[#fff] mt-1 block w-full border-gray-300 bg-[#000] p-5 min-h-96 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={urlList}
                  onChange={handleUrlInputChange}
                  rows={10}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                Generate Sitemap
              </button>
            </form>
            {xmlString && (
              <div className="generated-xml mt-8">
                <h3 className="text-xl font-medium text-gray-700 ">
                  Generated Sitemap XML:
                </h3>
                <pre className="mt-2  min-h-96 border-[2px] p-4 bg-gray-100 rounded">
                  {xmlString}
                </pre>
                <div className="flex justify-between mt-4">
                  <button
                    className="btn btn-success px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                    onClick={handleDownloadXml}
                  >
                    Download Sitemap
                  </button>
                  <button
                    className="btn btn-success px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                    onClick={copyXmlToClipboard}
                  >
                    Copy to Clipboard
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sitemap;
