import React, { useState } from "react";
import Helmet from "react-helmet";
import logo from "../../img/logo192.png";
function TimeConverter() {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [copied, setCopied] = useState(false);

  const handleConvert = () => {
    const total = hours * 3600 + minutes * 60 + seconds;
    setTotalSeconds(total);
  };

  const copyToClipboard = () => {
    if (totalSeconds === 0) {
      alert("Please enter a valid time to copy.");
      return;
    }
    navigator.clipboard.writeText(totalSeconds.toString());
    setCopied(true);
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="" />
        <meta
          name="title"
          content="Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" coding validation tools, programming tools, code checker, code error finding tool, code beautify tool, code scanning tool"
        />
        <meta
          name="description"
          content=" Discover top coding validation tools to ensure error-free, high-quality code. Learn how tools like HTML Preview, Minyfiy to UnMinyfiy, Json Validator, Validate CSS, String Replace, Webhook Test can streamline your development workflow and enforce coding standards. "
        />
        <link rel="apple-touch-icon" href={logo} />
        <title>
          Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech
        </title>
      </Helmet>
      <div className="max-w-sm mx-auto mt-10">
        <div className="bg-gray-100 border border-gray-200 rounded-lg shadow-md overflow-hidden">
          <div className="p-6">
            <h2 className="text-xl font-semibold mb-4">Time Converter</h2>
            <div className="mb-4">
              <label className="block text-gray-700">Hours:</label>
              <input
                type="number"
                className="form-input mt-1 p-2 block w-full border border-black border-opacity-50 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                value={hours}
                onChange={(e) => setHours(parseInt(e.target.value))}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Minutes:</label>
              <input
                type="number"
                className="form-input mt-1 p-2 block w-full border border-black border-opacity-50 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                value={minutes}
                onChange={(e) => setMinutes(parseInt(e.target.value))}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Seconds:</label>
              <input
                type="number"
                className="form-input mt-1 block w-full p-2 border border-black border-opacity-50 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                value={seconds}
                onChange={(e) => setSeconds(parseInt(e.target.value))}
              />
            </div>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
              onClick={handleConvert}
            >
              Convert
            </button>
            <button
              className={`bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ${copied && "bg-gray-500 cursor-not-allowed"}`}
              onClick={copyToClipboard}
              disabled={copied}
            >
              {copied ? "Copied" : "Copy to Clipboard"}
            </button>
            <div className="mt-4">
              <label className="block text-gray-700 font-semibold">
                Total Seconds:
              </label>
              <div className="font-bold" id="totalSecond">
                {totalSeconds}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TimeConverter;
