import React, { useState } from "react";
import Helmet from "react-helmet";
import help from "../img/Need-help_white.webp";
import lets from "../img/Lats-talk_white.webp";
import contact_bg from "../img/background_img/Contact-Us.webp";
// import contact_bg_1 from "../img/banner/mob_view_contact_004.jpg";
import pdf from "../img/Pdf_white.webp";
import pdf2 from "../img/download_gif.gif";
import Pdffile from "../img/about-section logos/AjivaInfotech.pdf";
import { headers } from "../helpers/variables";
import Cookies from "js-cookie";
// import ajivainfo from "../img/AjivaInfotech.pdf"

import logo from "../img/logo192.png";
import {
  get_api_request,
  post_api_request,
  put_api_request,
  delete_api_request,
  api_url,
} from "../helpers/Api.js";
const Contact = () => {
  const [showform, setshowform] = useState("true");
  const [showsuccessmsg, setshowsuccessmsg] = useState("false");
  function submit_form(form_id) {
    let e = document.getElementById(form_id),
      l = new FormData(e),
      t = Array.from(l.entries());
    var n = {};
    for (let o = 0; o < t.length; o++) {
      var s = t[o],
        i = s[0],
        y = s[1];
      y.length > 0 && (void 0 != n[i] ? (n[i] = n[i] + "," + y) : (n[i] = y));
    }
    return n;
  }
  const handleformsubmit = () => {
    var feildsvalue = submit_form("contactid");
    console.log(feildsvalue);
    var payload = {};
    payload["fullname"] = feildsvalue?.fullname;
    payload["email"] = feildsvalue?.email;
    payload["phone"] = feildsvalue?.phone;
    // payload["city"] = feildsvalue?.address;
    payload["message"] = feildsvalue?.message;
    async function Createcontact(payload) {
      const url = "/api/v1/contactus/";
      // console.log(url,"gggggggggggggg")
      const response = await post_api_request(url, payload, headers);
      if (response.status == 201) {
        document.getElementById("contactid").reset();
        setshowsuccessmsg(true);
        setshowform(false);
      }
    }
    Createcontact(payload);
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="title"
          content="Expert Tech Solutions at Your Fingertips: Contact AjivaInfotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          content="Need top-notch tech solutions for your business? Contact AppSierra now and let our expert team help you achieve your goals. Get in touch today"
          name="description"
        ></meta>
        <meta
          name="description"
          content="Need top-notch tech solutions for your business? Contact AppSierra now and let our expert team help you achieve your goals. Get in touch today."
        />
        <link rel="apple-touch-icon" href={logo} />
        <title>
          Expert Tech Solutions at Your Fingertips: Contact AjivaInfotech
        </title>
      </Helmet>
      <section className="container-fluid m-auto">
        <div className="w-full">
          <img
            className="w-full"
            src={contact_bg}
            alt="contact_bg"
            // className="lg:block md:block hidden"
          />
          {/* <img
            src={contact_bg_1}
            alt="contact_bg"
            className="lg:hidden md:hidden block"
          /> */}
        </div>
      </section>
      <section className="">
        {showform != false ? (
          <div className="container m-auto">
            <div className="w-full">
              <div className="js_contact_content text-center">
                {/* <h2 className="text-4xl font-bold text-center pt-16 text-[#343434]">
                  Contact us page
                </h2> */}
                <h1 className=" m-auto lg:mt-12 py-4 px-2 text-2xl text-gray-500 mx-2	">
                  Get in touch with us, and let's turn your ideas into digital
                  brilliance!
                </h1>
              </div>
            </div>

            <div className=" w-10/12	 mx-auto mb-20  lg:flex mt-8 lg:py-8 relative">
              <div class="dec_cirlce2 ">
                <span></span>
              </div>

              <div className="lg:flex contactcard z-12 ">
                <div className="lg:w-1/3 h-50 lg:ml-10 relative">
                  <div className=" border text-2xl  uppercase font-bold flex my-8 bg-[#f4f4f4] boredr-r border-r-[3px] border-r-red-600">
                    <div className="border-r-2 border-r-[#000C6B] bg-red-600 py-10 px-4">
                      <img src={help} class="pr-5" alt="help"></img>
                    </div>
                    <div className=" px-4  py-4 ">
                      <h2 className="text-2xl text-[#DA0006] font-bold ">
                        NEED HELP
                      </h2>
                      <h4 className="text-xl text-[#000C6B]">+91 8699981982</h4>
                      <h4 className="text-xl text-[#000C6B]">0172-4084189</h4>
                    </div>
                  </div>
                  <div className=" border text-2xl  uppercase font-bold flex my-8 bg-[#f4f4f4] boredr-r border-r-[3px] border-r-red-600">
                    <div className="border-r-2 border-r-[#000C6B] bg-red-600 py-10 px-4 pdf_align">
                      <a href={Pdffile} download="filename.pdf">
                        <img src={pdf} class=" pr-4" alt="pdf"></img>
                      </a>
                    </div>
                    <a href={Pdffile} download="filename.pdf">
                      <div className=" px-1 py-8 mx-3 cursor-pointer ">
                        <div className="py-2 px-3 rounded">
                          <div className="">
                            <h2 className="text-2xl text-[#DA0006] font-bold ">
                              DOWNLOAD
                              <a
                                href={Pdffile}
                                download="AjivaInfotech.pdf"
                              ></a>
                            </h2>
                            <h4 className="text-base text-[#000C6B]">
                              COMPANY PROFILE
                              <a
                                href={Pdffile}
                                download="AjivaInfotech.pdf"
                              ></a>
                            </h4>
                          </div>
                        </div>
                        <div
                          className="flex justify-center "
                          // onClick={handleDownload}
                        >
                          <a href={Pdffile} download="AjivaInfotech.pdf">
                            <img src={pdf2} class="w-32 pr-4" alt="pdf" />
                          </a>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className=" border text-2xl  uppercase font-bold flex my-8 bg-[#f4f4f4] boredr-r border-r-[3px] border-r-red-600">
                    <div className="border-r-2 border-r-[#000C6B] bg-red-600 py-10 px-4">
                      <img src={lets} alt="lets" class=" pr-4"></img>
                    </div>
                    <div className=" px-4  py-8 ">
                      <h2 className="text-2xl text-[#DA0006] font-bold ">
                        LET'S TALK YOUR
                      </h2>
                      <h4 className="text-xl text-[#000C6B] ">BUSINESS</h4>
                    </div>
                  </div>
                  {/* <div className="lg:ml-16 border text-2xl px-6 py-8 uppercase font-bold flex my-6">
                  <div className="py-4 border-r-2 border-indigo-500">
                    <img src={pdf} class=" pr-4"></img>
                  </div>
                  <div className=" mx-4 pl-4">
                    <h2 className="text-xl text-red-600  font-bold cursor-pointer">
                      DOWNLOAD
                    </h2>
                    <h4 className="text-xl text-gray-700 ">COMPANY PROFILE</h4>
                  </div>
                </div>
                <div className="lg:ml-16 border text-2xl px-6 py-8 uppercase font-bold flex my-6">
                  <div className="py-4 border-r-2 border-indigo-500">
                    <img src={lets} class=" pr-4"></img>
                  </div>
                  <div className=" mx-4 pl-4">
                    <h2 className="text-xl text-red-600  font-bold ">
                      LET'S TALK YOUR
                    </h2>
                    <h4 className="text-xl text-gray-700 ">BUSINESS</h4>
                    <a
                      className="text-white  text-base  font-semibold"
                      href="mailto:info@ajivainfotech.com"
                      style={{
                        color: "red",
                        fontSize: "13px",
                        textTransform: "none",
                      }}
                    >
                      info@ajivainfotech.com
                    </a>
                  </div>
                </div> */}
                </div>

                <div className="lg:w-2/3  lg:px-10   mx-4  z-10">
                  {/* <h1 class="text-black underline-offset-0 text-lg	py-2 pt-6 text-gray-800">
                    Fill Out this form and tell us a little about you We will
                    get back to you with best solution.
                  </h1> */}
                  <form
                    action=""
                    name="contactform"
                    id="contactid"
                    class="mt-6"
                  >
                    <input
                      type="text"
                      name="fullname"
                      class="w-full border-gray-200 p-4 pe-12 border my-2 bg-[#f2f2f2]"
                      placeholder="Name:"
                    />
                    <input
                      type="email"
                      name="email"
                      class="w-full border-gray-200 p-4 pe-12 border my-2  bg-[#f2f2f2]"
                      placeholder="Email:"
                    />
                    <input
                      type="tel"
                      name="phone"
                      class="w-full  border-gray-200 p-4 pe-12 border my-2  bg-[#f2f2f2]"
                      placeholder="Phone No:"
                    />

                    <textarea
                      className="w-full border-gray-200 p-4 pe-12 border my-2 h-[180px]  bg-[#f2f2f2]"
                      id=""
                      name="message"
                      placeholder="Write your query here"
                    ></textarea>
                    <button
                      className="inline-block rounded border border-red-600 bg-red-600 lg:py-2 py-2 px-4 mb-4 lg:my-4 text-xl
                  font-semibold text-white hover:bg-transparent hover:text-red-600 
                  focus:outline-none focus:ring active:text-black-500 w-full lg:w-56
 "
                      type="button"
                      onClick={(e) => handleformsubmit(e)}
                    >
                      SUBMIT
                    </button>
                  </form>
                </div>
              </div>
              <div class="dec_cirlce3">
                <span></span>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {showsuccessmsg == true ? (
          <div className="successmsg">
            <div className="successmsginner">
              <i class="fa-regular fa-circle-check text-green-700"></i>
              <p>Thanks for Contacting Us!</p>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    </>
  );
};
export default Contact;
