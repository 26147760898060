import React, { useState } from 'react';
import Helmet from "react-helmet";
import logo from "../../img/logo192.png";
const JsonValidator = () => {
  const [jsonInput, setJsonInput] = useState('');
  const [validationResult, setValidationResult] = useState('');

  const validateJSON = () => {
    try {
      const json = JSON.parse(jsonInput);
      setValidationResult(JSON.stringify(json, null, 4));
    } catch (error) {
      setValidationResult(`Invalid JSON: ${error.message}`);
    }
  };

  return (
   <>
   <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="" />
        <meta
          name="title"
          content="Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" coding validation tools, programming tools, code checker, code error finding tool, code beautify tool, code scanning tool"
        />
        <meta
          name="description"
          content=" Discover top coding validation tools to ensure error-free, high-quality code. Learn how tools like HTML Preview, Minyfiy to UnMinyfiy, Json Validator, Validate CSS, String Replace, Webhook Test can streamline your development workflow and enforce coding standards. "
        />
        {/* <link rel="apple-touch-icon" href={logo} /> */}
        <title>
          Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech
        </title>
      </Helmet>
    <div className='my-20'>
       <div className="mx-auto w-[80%] border-[2px] p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-lg font-bold text-center mb-4">JSON Validator</h2>

      <textarea
        className="w-full h-96 px-3 bg-[#000] text-[#fff] py-2 mb-4 border rounded-md focus:outline-none  "
        value={jsonInput}
        onChange={(e) => setJsonInput(e.target.value)}
        placeholder="Enter your JSON here..."
      />

      <button
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        onClick={validateJSON}
      >
        Validate JSON
      </button>

      <div className="mt-4 border rounded-md p-4">
        <pre className=" min-h-40 whitespace-pre-wrap">{validationResult}</pre>
      </div>
    </div>
   </div></>
  );
};

export default JsonValidator;
