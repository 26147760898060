import React, { useEffect, useState } from "react";
import contact_bg from "../img/background_img/Contact-Us.webp";
import Aservices from "./Aservices";
import res from "../img/bn3256.png";
import Helmet from "react-helmet";
import logo from "../img/logo192.png";
import Ecommerce_image_ecc from "../../src/aservecse_img/About_Ajiva_Eccomerce.png";
import Ecommerce_image from "../../src/aservecse_img/ecom_pg_lfimg_1.png";
import Ecommerce_image_2 from "../../src/aservecse_img/ecom_pg_lfimg_2.png";
import Ecommerce_image_3 from "../../src/aservecse_img/Eccomerce_3.png";
import Ecommerce_image_4 from "../../src/aservecse_img/Eccomerce_4.png";
import Eccomerce_SCO from "../../src/aservecse_img/Eccomerce_SCO.png";
import new_design from "../../src/aservecse_img/New_design.png";
import { NavLink } from "react-router-dom";
// const [Intigration, setIntigration] = useState(0);
function ApiIntigrationA() {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="title"
          content=" Custom CRM Software Development Services | Leading CRM Developers
          "
        />
        <title>
          Custom CRM Software Development Services | Leading CRM Developers
        </title>
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content="  CRM software development services, custom CRM development, CRM application development, leading CRM developers, top CRM development firms, best CRM development agencies"
        />
        <meta
          name="description"
          content="  Discover top-notch custom CRM software development services tailored to your business needs. Our leading CRM developers offer expert CRM application development, ensuring scalable solutions with advanced technologies and agile processes. Contact us today for a consultation!
          "
        />
        <link rel="apple-touch-icon" href={logo} />
      </Helmet>
      <div className="cocontainer m-auton">
        <section>
          <div className="flex justify-center">
            <img src={contact_bg} alt="contact_bg" />
          </div>
          <div className="container m-auto ">
            <div className="">
              <div className="opp">
                <div className="bang">
                  <h1 className="text-red-700	 ">CRM</h1>
                </div>
                <div className="Api_int">
                  <NavLink to="/" className="text-base text-red-600	pr-2">
                    Home
                  </NavLink>
                  <span className="text-sm">/</span>
                  <h1 className="pl-2  text-2xl font-bold text-[#333333]">
                    CRM Development
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="ecomerece_border_frist_main">
          <div className="container m-auto">
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image ">
                  <img src={Ecommerce_image} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Customer-Centric Approach
                </h1>
                <p className="">
                  Are you looking to streamline your customer relationship
                  management (CRM) processes with customize software solutions?
                  At AJIVA INFTECH, Our CRM Developers specialize in delivering
                  top-notch CRM software development services that cater to your
                  unique business needs. Whether you're a startup or an
                  enterprise, our custom CRM development services are designed
                  to optimize your workflows and enhance customer interactions.
                </p>
              </div>
            </div>

            {/* <div className="Our-Services-Graphic-Design">
              <h1>Why Choose Our CRM Software</h1>
            </div> */}
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Custom CRM Development Services
                </h1>
                <p className="">
                  Our team of expert developers excels in creating custom CRM
                  solutions from scratch, tailored specifically to meet your
                  business requirements. We understand that every organization
                  is unique, and so are its CRM needs. With our custom CRM
                  application development services, you can expect:
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_4} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold  text-[#333333]">
                  <ul class="">
                    <li class="  rounded-lg">
                      <h3 class="text-[17px] font-semibold text-[#333333]">
                        Customizable Features
                      </h3>
                      <p class="text-gray-600">
                        Build CRM systems with features that align perfectly
                        with your business processes.
                      </p>
                    </li>
                    <li class="  rounded-lg">
                      <h3 class="text-[17px] font-semibold text-[#333333]">
                        Scalability
                      </h3>
                      <p class="text-gray-600">
                        Solutions that grow with your business, accommodating
                        increasing data and user demands.
                      </p>
                    </li>
                    <li class="  rounded-lg">
                      <h3 class="text-[17px] font-semibold text-[#333333]">
                        Integration
                      </h3>
                      <p class="text-gray-600">
                        Seamless integration with existing systems like ERP, BI
                        tools, and more.
                      </p>
                    </li>
                    <li class="  rounded-lg">
                      <h3 class="text-[17px] font-semibold text-gray-700">
                        User-friendly Interfaces
                      </h3>
                      <p class="text-gray-600">
                        Intuitive interfaces that enhance user adoption and
                        productivity.
                      </p>
                    </li>
                  </ul>
                </h1>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Leading CRM Developers
                </h1>
                <p className="">
                  As one of the top CRM development firms, we bring years of
                  expertise in developing robust CRM solutions across various
                  industries. Our team comprises skilled developers, designers,
                  and project managers who work collaboratively to deliver:
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image} />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  <ul class="">
                    <li class="  rounded-lg">
                      <h3 class="text-[17px] font-semibold text-[#333333]">
                        Comprehensive Solutions
                      </h3>
                      <p class="text-gray-600">
                        End-to-end CRM development from initial consultation to
                        deployment and support.
                      </p>
                    </li>
                    <li class="  rounded-lg">
                      <h3 class="text-[17px] font-semibold text-[#333333]">
                        Agile Methodology
                      </h3>
                      <p class="text-gray-600">
                        Agile development practices ensuring flexibility and
                        responsiveness to changes.
                      </p>
                    </li>
                    <li class="  rounded-lg">
                      <h3 class="text-[17px] font-semibold text-[#333333]">
                        Quality Assurance
                      </h3>
                      <p class="text-gray-600">
                        Rigorous testing procedures to ensure reliable
                        performance and security.
                      </p>
                    </li>
                  </ul>
                </h1>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Top CRM Development Firms
                </h1>
                <p className="">
                  When you choose us as your CRM development partner, you
                  benefit from:
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  <ul class="">
                    <li class="  rounded-lg">
                      <h3 class="text-[17px] font-semibold text-[#333333]">
                        Proven Track Record
                      </h3>
                      <p class="text-gray-600">
                        Successfully delivered CRM projects for diverse clients
                        globally.
                      </p>
                    </li>
                    <li class="  rounded-lg">
                      <h3 class="text-[17px] font-semibold text-[#333333]">
                        Client-Centric Approach
                      </h3>
                      <p class="text-gray-600">
                        Understanding your business goals to deliver solutions
                        that drive growth.
                      </p>
                    </li>
                    <li class="  rounded-lg">
                      <h3 class="text-[17px] font-semibold text-[#333333]">
                        Support & Maintenance
                      </h3>
                      <p class="text-gray-600">
                        Ongoing support and maintenance to keep your CRM system
                        running smoothly.
                      </p>
                    </li>
                  </ul>
                </h1>
                {/* <p className="">
                  Let us handle the setup and configuration of your CRM system
                  to maximize its effectiveness.
                </p> */}
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Best CRM Development Agencies
                </h1>
                <p className="">
                  Our CRM development process leverages the latest technologies
                  and frameworks to build robust and scalable solutions:
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image} />
                </div>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]"></h1>
                <p className="">
                  <ul class="">
                    <li class="  rounded-lg">
                      <h3 class="text-[17px] font-semibold text-[#333333]">
                        Technologies
                      </h3>
                      <p class="text-gray-600">
                        Utilizing technologies such as Salesforce, Microsoft
                        Dynamics, HubSpot, and custom frameworks.
                      </p>
                    </li>
                    <li class="  rounded-lg">
                      <h3 class="text-[17px] font-semibold text-[#333333]">
                        Development Process
                      </h3>
                      <p class="text-gray-600">
                        {" "}
                        Iterative development cycles ensuring rapid prototyping
                        and timely feedback.
                      </p>
                    </li>
                    <li class="  rounded-lg">
                      <h3 class="text-[17px] font-semibold text-[#333333]">
                        Security & Compliance
                      </h3>
                      <p class="text-gray-600">
                        Adhering to industry standards to ensure data security
                        and regulatory compliance.
                      </p>
                    </li>
                  </ul>
                </p>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2   Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Get Started with AJIVA INFOTECH
                </h1>
                <p className="">
                  Transform your customer relationship management with our
                  tailored CRM software development services. Whether you need a
                  new CRM system or want to enhance an existing one, our experts
                  are here to help you achieve your business objectives.
                </p>
              </div>
              <div className="lg:w-1/2  reveal active ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Eccomerce_SCO} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Aservices />
      </div>
    </>
  );
}
export default ApiIntigrationA;
