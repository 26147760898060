import React, { useState } from "react";
import Helmet from "react-helmet";

import logo from "../../img/logo192.png";
function App() {
  const [base64Input, setBase64Input] = useState("");
  const [decodedOutput, setDecodedOutput] = useState("");

  const handleDecode = () => {
    try {
      const decodedString = atob(base64Input);
      setDecodedOutput(decodedString);
    } catch (e) {
      setDecodedOutput("Invalid Base64 string");
    }
  };

  const handleCopyToClipboard = () => {
    // Create a temporary textarea to copy the text to clipboard
    const textarea = document.createElement("textarea");
    textarea.value = decodedOutput;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="" />
        <meta
          name="title"
          content="Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" coding validation tools, programming tools, code checker, code error finding tool, code beautify tool, code scanning tool"
        />
        <meta
          name="description"
          content=" Discover top coding validation tools to ensure error-free, high-quality code. Learn how tools like HTML Preview, Minyfiy to UnMinyfiy, Json Validator, Validate CSS, String Replace, Webhook Test can streamline your development workflow and enforce coding standards. "
        />
        {/* <link rel="apple-touch-icon" href={logo} /> */}
        <title>
          Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech
        </title>
      </Helmet>
      <div className="flex flex-col items-center justify-center  text-white">
        <header className="text-center w-1/2">
          <h2 className="text-3xl font-bold mb-6">Base64 Decoder</h2>
          <div>
            <input
              type="text"
              value={base64Input}
              onChange={(e) => setBase64Input(e.target.value)}
              placeholder="Enter Base64 encoded string"
              className="w-full p-3 mb-4 text-black rounded-lg border border-gray-300"
            />
          </div>
          <div className="flex justify-between">
            <button
              onClick={handleDecode}
              className="px-6 py-3 mb-4 text-lg font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
            >
              Decode
            </button>
            <button
              onClick={handleCopyToClipboard}
              className="px-6 py-3 mb-4 text-lg font-semibold text-white bg-green-500 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
            >
              Copy to Clipboard
            </button>
          </div>
          <div>
            <textarea
              className="w-full h-40 p-3 text-lg bg-gray-800 text-white rounded-lg border border-gray-300 resize-none"
              value={decodedOutput}
              readOnly
            />
          </div>
        </header>
      </div>
    </>
  );
}

export default App;
