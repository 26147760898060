import React, { useState } from "react";
import vkbeautify from "vkbeautify";
import Helmet from "react-helmet";
import logo from "../../img/logo192.png";
const JSONtoXMLConverter = () => {
  const [jsonInput, setJsonInput] = useState("");
  const [xmlOutput, setXmlOutput] = useState("");

  const handleConvert = () => {
    try {
      const jsonObject = JSON.parse(jsonInput);
      const xmlString = vkbeautify.xml(vkbeautify.json.toXml(jsonObject));
      setXmlOutput(xmlString);
    } catch (error) {
      console.error("Error converting JSON to XML:", error);
      setXmlOutput("Invalid JSON input");
    }
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="" />
        <meta
          name="title"
          content="Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" coding validation tools, programming tools, code checker, code error finding tool, code beautify tool, code scanning tool"
        />
        <meta
          name="description"
          content=" Discover top coding validation tools to ensure error-free, high-quality code. Learn how tools like HTML Preview, Minyfiy to UnMinyfiy, Json Validator, Validate CSS, String Replace, Webhook Test can streamline your development workflow and enforce coding standards. "
        />
        {/* <link rel="apple-touch-icon" href={logo} /> */}
        <title>
          Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech
        </title>
      </Helmet>

      <div className="p-6 max-w-xl mx-auto bg-white rounded-md shadow-md">
        <h2 className="text-lg font-semibold mb-4">Convert JSON to XML</h2>
        <div>
          <textarea
            value={jsonInput}
            onChange={(e) => setJsonInput(e.target.value)}
            placeholder="Enter JSON here..."
            className="w-full min-h-[200px] border border-gray-300 rounded-md p-2"
          />
        </div>
        <div className="mt-4">
          <button
            onClick={handleConvert}
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md"
          >
            Convert
          </button>
        </div>
        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">XML Output</h3>
          <textarea
            value={xmlOutput}
            readOnly
            placeholder="XML output will appear here..."
            className="w-full min-h-[200px] border border-gray-300 rounded-md p-2"
          />
        </div>
      </div>
    </>
  );
};

export default JSONtoXMLConverter;
