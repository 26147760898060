import React, { useEffect, useState } from "react";
import contact_bg from "../img/background_img/Contact-Us.webp";
import { NavLink } from "react-router-dom";
import res from "../img/bn3256.png";
import help from "../img/Need-help.webp";
import Aservices from "./Aservices";
import Helmet from "react-helmet";
import logo from "../img/logo192.png";
import Ecommerce_image_ecc from "../../src/aservecse_img/About_Ajiva_Eccomerce.png";
import Ecommerce_image from "../../src/aservecse_img/ecom_pg_lfimg_1.png";
import Ecommerce_image_2 from "../../src/aservecse_img/ecom_pg_lfimg_2.png";
import Ecommerce_image_3 from "../../src/aservecse_img/Eccomerce_3.png";
import Ecommerce_image_4 from "../../src/aservecse_img/Eccomerce_4.png";
import Eccomerce_SCO from "../../src/aservecse_img/Eccomerce_SCO.png";
import new_design from "../../src/aservecse_img/New_design.png";
function Graphi_Design() {
  // const [Graphi_Design, setGraphi_Design] = useState(0);

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        {/* <link rel="icon" href={logo} /> */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="title"
          content="Professional Website & Graphic Design Services | Custom, Affordable, and Responsive Designs"
        />
        <title>
        Professional Website & Graphic Design Services | Custom, Affordable, and Responsive Designs
        </title>
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content="website design services, graphic design services, custom website design, affordable website design, responsive website design, small business website design, eCommerce website design, WordPress website design, mobile-friendly website design, logo design, brochure design, flyer design, business card design, banner design, social media graphic design, integrated design services, full-service design agency, UI/UX design, branding and identity design, illustration design, packaging design, motion graphics design, infographic design"
        />
        <meta
          name="description"
          content="Discover top-notch website and graphic design services suits to your business needs. Offering custom, affordable, and responsive designs for websites, logos, brochures, and more. Perfect for small businesses, eCommerce, and WordPress sites. Contact us today!
"
        />
        {/* <link rel="apple-touch-icon" href={logo} /> */}
      </Helmet>
      <div className="cocontainer m-auton">
        <section>
          <div className="flex justify-center">
            <img src={contact_bg} alt="contact_bg" />
          </div>
          <div className="container m-auto ">
            <div className="">
              <div className="opp">
                <div className="bang">
                  <h1 className="text-red-700	 ">Website_Design</h1>
                </div>
                <div className="Api_int">
                  <NavLink to="/" className="text-base text-red-600	pr-2">
                    Home
                  </NavLink>
                  <span className="text-sm">/</span>
                  <h1 className="pl-2  lg:text-2xl font-bold text-[#333333]">
                  Website_Design
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="ecomerece_border_frist_main">
          <div className="container m-auto">
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image ">
                  <img src={Ecommerce_image} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <p className="">
                  Welcome to our comprehensive website and graphic design
                  services, where creativity meets functionality. We specialize
                  in delivering professional, custom, and affordable design
                  solutions tailored to meet the unique needs of your business.
                  Whether you are looking for a new website, a logo, or a full
                  branding package, our experienced team is here to bring your
                  vision to life.
                </p>
              </div>
            </div>
            <div className="Our-Services-Graphic-Design">
              <h1>Website Design Services</h1>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Professional Website Design Services
                </h1>
                <p className="">
                  Our professional website design services ensure your online
                  presence is visually stunning and highly functional. We create
                  websites that not only look great but also perform seamlessly,
                  providing an excellent user experience.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]  ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_4} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Affordable Website Design Services
                </h1>
                <p className="">
                  We believe that a high-quality website shouldn’t break the
                  bank. Our affordable website design services offer exceptional
                  value without compromising on quality, making it accessible
                  for businesses of all sizes.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]  sectio-positon-change ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Custom Website Design Services
                </h1>
                <p className="">
                  Your business is unique, and your website should be too. Our
                  custom website design services provide personalized solutions
                  tailored to reflect your brand’s identity and meet your
                  specific needs.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image} />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Responsive Website Design Services
                </h1>
                <p className="">
                  In today’s mobile-centric world, having a responsive website
                  is crucial. Our responsive website design services ensure your
                  site looks and works perfectly on all devices, from desktops
                  to smartphones.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Small Business Website Design Services
                </h1>
                <p className="">
                  We specialize in small business website design services,
                  offering solutions that help you stand out in a crowded
                  market. We create websites that are not only attractive but
                  also optimized to drive growth and success.
                </p>
              </div>
              <div className="lg:w-1/2  ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Eccomerce_SCO} />
                </div>
              </div>
            </div>
            {/* <div className="Our_Services">
              <h1>Why Choose Us?</h1>
            </div> */}
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Ecommerce Website Design Services
                </h1>
                <p className="">
                  Transform your online store with our ecommerce website design
                  services. We build robust, secure, and user-friendly ecommerce
                  websites that enhance the shopping experience and boost your
                  sales.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  WordPress Website Design Services
                </h1>
                <p className="">
                  Leverage the power of WordPress with our specialized design
                  services. Our WordPress website design services provide you
                  with a flexible, scalable, and easy-to-manage website that
                  grows with your business.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Mobile-Friendly Website Design Services
                </h1>
                <p className="">
                  Our mobile-friendly website design services ensure your site
                  is optimized for mobile users. We create designs that offer a
                  seamless browsing experience, increasing engagement and
                  satisfaction on mobile devices.
                </p>
              </div>
            </div>
           </div>
        </div>
        <Aservices />
      </div>
    </>
  );
}
export default Graphi_Design;
