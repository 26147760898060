import React, { useState } from "react";
import Helmet from "react-helmet";

import logo from "../../img/logo192.png";
function URLEncoder() {
  const [inputString, setInputString] = useState("");
  const [encodedString, setEncodedString] = useState("");
  const [copySuccess, setCopySuccess] = useState(false);

  const handleInputChange = (event) => {
    setInputString(event.target.value);
  };

  const handleEncode = () => {
    const encoded = encodeURIComponent(inputString);
    setEncodedString(encoded);
    setCopySuccess(false); // Reset copy success state
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(encodedString);
    setCopySuccess(true);
  };

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="" />
        <meta
          name="title"
          content="Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" coding validation tools, programming tools, code checker, code error finding tool, code beautify tool, code scanning tool"
        />
        <meta
          name="description"
          content=" Discover top coding validation tools to ensure error-free, high-quality code. Learn how tools like HTML Preview, Minyfiy to UnMinyfiy, Json Validator, Validate CSS, String Replace, Webhook Test can streamline your development workflow and enforce coding standards. "
        />
        {/* <link rel="apple-touch-icon" href={logo} /> */}
        <title>
          Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech
        </title>
      </Helmet>
      <div className="lg:w-1/2 mx-auto ">
        <div className="p-10 border m-10 rounded">
          <h2 className="text-xl font-bold mb-4 text-center">URL Encoder</h2>
          <div className="mb-4  items-center">
            <label htmlFor="inputString" className="block mb-1 mr-2 font-bold	">
              Enter a string to encode:
            </label>
            <input
              type="text"
              id="inputString"
              value={inputString}
              onChange={handleInputChange}
              className="border border-gray-300 px-3 py-2 w-full rounded-md"
            />
            <button
              onClick={handleEncode}
              className=" bg-blue-500   mt-3 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
            >
              Encode
            </button>
          </div>
          <div className="mt-4">
            <strong>Encoded Result:</strong>
            <br />
            <code className="bg-gray-100 px-3 py-2 mt-2 border rounded-md block min-h-[45px]">
              {encodedString}
            </code>
            {encodedString && (
              <div className="mt-4">
                <button
                  onClick={handleCopyToClipboard}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded-md"
                >
                  {copySuccess ? "Copied!" : "Copy to Clipboard"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default URLEncoder;
