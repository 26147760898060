import React, { useState } from "react";
import Helmet from "react-helmet";
import logo from "../../img/logo192.png";
const CodeEditor = () => {
  const [inputCode, setInputCode] = useState("");
  const [outputCode, setOutputCode] = useState("");

  const handlePreview = () => {
    setOutputCode(inputCode); // For preview, simply display the input code as-is
  };

  const handleMinify = () => {
    const minifiedCode = inputCode.replace(/\s+/g, ""); // Remove all whitespace
    setOutputCode(minifiedCode);
  };

  const handleUnminify = () => {
    // Assuming unminify means to format the code for readability
    // You can use a library like prettier to achieve this more effectively
    // For simplicity, we'll just add line breaks after semicolons
    const unminifiedCode = inputCode.replace(/;/g, ";\n");
    setOutputCode(unminifiedCode);
  };

  return (
    <>
      {" "}
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="canonical" href="" />
        <meta
          name="title"
          content="Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech"
        />
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" coding validation tools, programming tools, code checker, code error finding tool, code beautify tool, code scanning tool"
        />
        <meta
          name="description"
          content=" Discover top coding validation tools to ensure error-free, high-quality code. Learn how tools like HTML Preview, Minyfiy to UnMinyfiy, Json Validator, Validate CSS, String Replace, Webhook Test can streamline your development workflow and enforce coding standards. "
        />
        {/* <link rel="apple-touch-icon" href={logo} /> */}
        <title>
          Top Coding Validation Tools | Code Beautify Tool -Ajiva Infotech
        </title>
      </Helmet>
      <div className="bg-gray-100 p-10">
        <h1 className="text-xl text-center font-semibold ">Minify</h1>
        <div className="code-editor-container p-4  rounded-lg shadow-md w-[80%] m-auto">
          <textarea
            value={inputCode}
            onChange={(e) => setInputCode(e.target.value)}
            placeholder="Enter your code here..."
            rows={10}
            className="w-full p-2 border border-gray-300 text-[#fff] bg-[#000] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div className="flex space-x-4 mt-4">
            {/* <button onClick={handlePreview} className="btn-preview">Preview</button> */}
            <button onClick={handleMinify} className="btn-minify">
              Minify
            </button>
            <button onClick={handleUnminify} className="btn-unminify">
              Unminify
            </button>
          </div>
          <h2 className="mt-4 text-lg font-semibold">Output:</h2>
          <textarea
            value={outputCode}
            readOnly
            rows={10}
            className="w-full p-2 border border-gray-300 rounded-md mt-2 bg-white"
          />
        </div>
      </div>
    </>
  );
};

export default CodeEditor;
