import React, { useEffect, useState } from "react";
import contact_bg from "../img/background_img/Contact-Us.webp";
import { NavLink } from "react-router-dom";
import Aservices from "./Aservices";
import help from "../img/Need-help.webp";
import res from "../img/bn3256.png";
import Helmet from "react-helmet";
import logo from "../img/logo192.png";
import Ecommerce_image_ecc from "../../src/aservecse_img/About_Ajiva_Eccomerce.png";
import Ecommerce_image from "../../src/aservecse_img/ecom_pg_lfimg_1.png";
import Ecommerce_image_2 from "../../src/aservecse_img/ecom_pg_lfimg_2.png";
import Ecommerce_image_3 from "../../src/aservecse_img/Eccomerce_3.png";
import Ecommerce_image_4 from "../../src/aservecse_img/Eccomerce_4.png";
import Eccomerce_SCO from "../../src/aservecse_img/Eccomerce_SCO.png";
import new_design from "../../src/aservecse_img/New_design.png";
// const [Mobile_App_Development, setMobile_App_Development] = useState(0);

function Mobile_App_Development() {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="icon" href={logo} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta
          name="title"
          content=" Best Mobile App Development Services | Custom & Affordable App Solutions  "
        />

        <title>
        Best Mobile App Development Services | Custom & Affordable App Solutions 
        </title>
        <meta name="robots" content="index, follow"></meta>
        <meta
          name="keywords"
          content=" Best mobile app development services, top mobile app development companies, custom mobile app development, affordable mobile app development, professional mobile app development"
        />
        <meta
          name="description"
          content="Discover the best mobile app development services offering custom, affordable, and professional solutions for iOS, Android, and cross-platform apps. Partner with one of the top mobile app development companies to turn your vision into reality. Contact us today for a seamless app development process.
  "
        />
        <link rel="apple-touch-icon" href={logo} />
      </Helmet>
      <div className="cocontainer m-auton">
        <section>
          <div className="flex justify-center">
            <img src={contact_bg} alt="contact_bg" />
          </div>
          <div className="container m-auto ">
            <div className="">
              <div className="opp">
                <div className="bang">
                  <h1 className="text-red-700	 ">Mobile App Development</h1>
                </div>
                <div className="Api_int">
                  <NavLink to="/" className="text-base text-red-600	pr-2">
                    Home
                  </NavLink>
                  <span className="text-sm">/</span>
                  <h1 className="pl-2 text-[18px] lg:text-2xl font-bold text-[#333333]">
                    Mobile App Development
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* =================new card================================================= */}

        {/* ===================section ksjdgksvjdvd====================================================== */}

        <div className="ecomerece_border_frist_main">
          <div className="container m-auto">
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image ">
                  <img src={Ecommerce_image} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Mobile App Development Services
                </h1>
                <p className="">
                  Welcome to our premier Mobile App Development Services, where
                  innovation meets functionality. We pride ourselves on being
                  among the{" "}
                  <span className="font-bold">
                    best mobile app development services
                  </span>
                  , delivering top-notch solutions that cater to your unique
                  business needs. As one of the
                  <span className="font-bold">
                    {" "}
                    top mobile app development companies
                  </span>
                  , we specialize in crafting custom, professional, and
                  affordable mobile applications for a variety of platforms and
                  technologies.
                </p>
                {/* <div>
                  <ul className="web_des_ser_offer">
                    <li>Optimize customer experience</li>
                    <li>Increase operational efficiency</li>
                    <li>Gain a competitive advantage</li>
                    <li>Unlock new revenue opportunities</li>
                    <li>Adapt to changing market demands</li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="Integration-Needs">
              <h1>Why Choose Our Mobile App Development Services?</h1>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Custom Mobile App Development
                </h1>
                <p className="">
                  We understand that every business is unique. Our custom mobile
                  app development services ensure that your app is tailored to
                  meet your specific requirements, enhancing user experience and
                  engagement.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_2} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Affordable Mobile App Development:
                </h1>
                <p className="">
                  Quality doesn't have to come at a high price. Our affordable
                  mobile app development solutions offer competitive pricing
                  without compromising on excellence.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change  ">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Professional Mobile App Development
                </h1>
                <p className="">
                  With a team of seasoned developers, designers, and
                  strategists, we deliver professional mobile app development
                  services that align with your business goals and industry
                  standards.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_4} alt="Ecommerce_image" />
                </div>
              </div>
            </div>

            <div className="Integration-Needs">
              <h1>Platforms and Technologies</h1>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] reveal ">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image} />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]"></h1>
                <p className="">
                  We At <span>Ajiva Infotech</span>, develop mobile applications
                  for various platforms and technologies, ensuring broad
                  compatibility and performance:
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  iOS App Development:
                </h1>
                <p className="">
                  Our experts create seamless, high-performance iOS apps using
                  Swift and Objective-C, tailored for iPhone and iPad users.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_3} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer bg-[]">
              <div className="lg:w-1/2  ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Eccomerce_SCO} />
                </div>
              </div>
              <div className="lg:w-1/2   Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Android App Development
                </h1>
                <p className="">
                  Leveraging Java and Kotlin, we develop robust and scalable
                  Android applications that cater to a diverse audience.
                </p>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Cross-Platform App Development
                </h1>
                <p className="">
                  Using frameworks like React Native, Flutter, and Xamarin, we
                  build apps that work flawlessly across both iOS and Android,
                  ensuring a wider reach and reduced development time.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Web App Development
                </h1>
                <p className="">
                  Our team excels in developing progressive web apps (PWAs) that
                  deliver a native app-like experience through web technologies
                  such as HTML5, CSS3, and JavaScript.
                </p>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Our App Development Process
                </h1>
                <p className="">
                  Our mobile app development process is designed to deliver
                  efficient, high-quality results:
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Discovery & Planning
                </h1>
                <p className="">
                  We start with understanding your vision, goals, and
                  requirements. Our team conducts thorough research and planning
                  to lay a strong foundation for the project.
                </p>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  UI/UX Design
                </h1>
                <p className="">
                  Our designers craft intuitive and visually appealing
                  interfaces, ensuring a user-friendly experience. Wireframes
                  and prototypes are created for client approval before
                  development begins.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Development
                </h1>
                <p className="">
                  Our developers bring your app to life using the latest
                  technologies and best practices. We follow agile methodologies
                  to ensure iterative progress and transparency.
                </p>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Testing
                </h1>
                <p className="">
                  Rigorous testing is conducted to identify and resolve any
                  issues. We perform functional, performance, and security
                  testing to ensure the app is reliable and secure.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
            </div>
            <div className="lg:flex my-2 Platform_Integration_outer  bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Deployment
                </h1>
                <p className="">
                  Once the app meets our quality standards, we assist with the
                  deployment process on the respective app stores. Our team
                  ensures a smooth launch and provides post-deployment support.
                </p>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer bg-[] sectio-positon-change">
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Maintenance & Support
                </h1>
                <p className="">
                  We offer ongoing maintenance and support services to keep your
                  app updated and running smoothly. Our team is always ready to
                  implement updates and new features as needed.
                </p>
              </div>
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={new_design} />
                </div>
              </div>
            </div>

            <div className="lg:flex my-2 Platform_Integration_outer  bg-[]">
              <div className="lg:w-1/2 ecomerece_border">
                <div className="ecomerece_border-image">
                  <img src={Ecommerce_image_ecc} alt="Ecommerce_image" />
                </div>
              </div>
              <div className="lg:w-1/2 Platform_Integration">
                <h1 className="lg:text-xl font-bold py-5 text-[#333333]">
                  Partner with Us
                </h1>
                <p className="">
                  As one of the{" "}
                  <span className="font-bold">
                    top mobile app development companies
                  </span>
                  , we are committed to turning your ideas into successful
                  mobile applications. Whether you need{" "}
                  <b className="font-bold">custom mobile app development</b> or{" "}
                  <b className="font-bold">
                    affordable mobile app development{" "}
                  </b>
                  , our professional team is here to deliver exceptional
                  results. Contact us today to discuss your project and discover
                  how we can help you achieve your mobile app goals.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Aservices />
      </div>
    </>
  );
}

export default Mobile_App_Development;
